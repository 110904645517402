import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import api, { GetToken } from "../api/axios";

import Logo from '../assets/images/urban.png'
import User from './User';


export default function Header() {
    const [company, setCompany] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    useEffect(() => {
        document.title = 'LoadOut';
        api
            .get("tenant/getdetails", { headers: { "x-access-token": GetToken() } })
            .then((data) => {
                const value = data.data[0];
                setCompany(value.company);
                setState(value.state);
                setCity(value.city);
            })
            .catch((err) => {
                console.log(err.response);
            });
    })

    const noMenuPages = ['', 'login', 'forgot_password', 'define']

    var pathName = useLocation().pathname;

    if (noMenuPages.includes(pathName.split('/')[1])) {
        return null;
    }



    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand pt-0 pb-0" href="/dashboard">
                    <img src={Logo} width='50' alt="" className="d-inline-block" />
                </a>
                <span>
                    {company}<br />
                    {city} - {state}
                </span>
                <button id='navbar-toggler' className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" >
                    <User />
                </div>
            </div>
        </nav>
    );
}
