import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
import { faSave, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactInputMask from "react-input-mask";

const eye = <FontAwesomeIcon icon={faEye} />;

export default function CompanySettings() {

    const [phone, setPhone] = useState('');
    const [phoneMask, setPhoneMask] = useState('');

    const history = useHistory();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    function onSubmit(data) {
        const phoneReplace = phone
            .replace("+", "")
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "");

        if (isNaN(phoneReplace)) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid phone",
            });
            return;
        }
        api
            .put(
                "tenant/update",
                {
                    company: data.company,
                    phone: phoneReplace,
                    zip: data.zip,
                    state: data.state,
                    email: data.email,
                    city: data.city,
                    address1: data.address1,
                    address2: data.address2,
                },
                { headers: { "x-access-token": GetToken() } }
            )
            .then((data) => {
                //Swal.fire({
                //  icon: "success",
                // title: "Success",
                //}).then(() => {
                history.push("/dashboard");
                //});
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.response.data.message,
                });
            });
    }


    useEffect(() => {
        api
            .get("tenant/getdetails", { headers: { "x-access-token": GetToken() } })
            .then((data) => {
                const value = data.data[0];
                setValue("company", value.company);
                //setValue("phone", value.phone);
                setValue("zip", value.zip);
                setValue("state", value.state);
                setValue("email", value.email);
                setValue("city", value.city);
                setValue("address1", value.address1);
                setValue("address2", value.address2);

                setPhone(value.phone);


            })
            .catch((err) => {
                console.log(err.response);
            });

        api.get('param-country/getall', {
            headers: { "x-access-token": GetToken() },
        }).then(data => {
            const currentLang = localStorage.getItem('language');
            const lang = data.data.find(x => x.code === currentLang)?.phonemask
            setPhoneMask(lang);
        });

    }, [setValue]);


    return (
        <div className="container mt-3">
            <div className='row mb-3'>
                <div className='col-6 col-md-10'>
                    <p className="title"><FormattedMessage id="company_settings" /></p>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="company_name" />
                                    <input
                                        className="form-control"
                                        type="company"
                                        {...register("company", { required: true })}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="phone" />
                                    <ReactInputMask
                                        mask={phoneMask}
                                        {...register("phone", { required: false })}
                                        className="form-control"
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="billing_email" />
                                    <input
                                        className="form-control"
                                        type="email"
                                        {...register("email")}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="address1" />
                                    <input
                                        className="form-control"
                                        type="address1"
                                        {...register("address1")}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="address2" />
                                    <input
                                        className="form-control"
                                        type="address2"
                                        {...register("address2")}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="city" />
                                    <input
                                        className="form-control"
                                        type="city"
                                        {...register("city")}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="state" />
                                    <input
                                        className="form-control"
                                        type="state"
                                        {...register("state")}
                                    />
                                </div>
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="zip" />
                                    <input
                                        className="form-control"
                                        type="zip"
                                        {...register("zip")}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row-reverse bd-highlight">
                                <button type="submit"
                                    className="btn btn-orange btn-confirm float-end"
                                >
                                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                                    <FormattedMessage id="save" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
