import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export default function AddEdit({ mode = "scorecard", setModal = null, startData = {} }) {

  const history = useHistory();
  let { id } = useParams();
  const isEdit = id != null;

  const [stateParam, setStateParam] = useState([]);
  const [routes, setRoutes] = useState("");
  const [packages, setPackages] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();



  function onSubmit(data) {

    if (id) {
      //edit
      api
        .put(
          "scorecard/update/" + id,
          {
            year: data.year,
            week: data.week,
            overall: data.overall === "" ? null : data.overall,
            //reliability: data.reliability == "" ? null : data.reliability,
            safety: data.safety === "" ? null : data.safety,
            team: data.team === "" ? null : data.team,
            quality: data.quality === "" ? null : data.quality,
            routes: data.routes === "" ? null : data.routes, //.toString().replace(",", "."),
            packages: data.packages === "" ? null : data.packages, //.toString().replace(",", ".")
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          Swal.fire({
            icon: "success",
            title: "Success",
          }).then(() => {
            if (mode === "scorecard") {
              history.push("/scorecard");
            }
            else {
              console.log(setModal)
              setModal(false);
            }
          });
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response?.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "scorecard/create",
          {
            year: data.year,
            week: data.week,
            overall: data.overall === "" ? null : data.overall,
            // reliability: data.reliability == "" ? null : data.reliability,
            safety: data.safety === "" ? null : data.safety,
            team: data.team === "" ? null : data.team,
            quality: data.quality === "" ? null : data.quality,
            packages: data.packages === "" ? null : data.packages,
            routes: data.routes === "" ? null : data.routes
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);

          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          if (mode === "scorecard") {
            history.push("/scorecard");
          }
          else {
            console.log(setModal)
            setModal(false);
          }
          //});
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  }

  useEffect(() => {

    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const currentLang = localStorage.getItem("language");
        const lang = data.data.find((x) => x.code === currentLang)?.phonemask;

        if (id) {
          api
            .get("scorecard/getbyid/" + id, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              const value = data.data[0];
              setValue("year", value.year);
              setValue("week", value.week);
              setValue("overall", value.overall);
              //setValue("reliability", value.reliability);
              setValue("safety", value.safety);
              setValue("team", value.team);
              setValue("quality", value.quality);
              setValue("routes", value.routes);
              setRoutes(value.routes);
              setValue("packages", value.packages);
              setPackages(value.packages);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response?.data.message,
              });
            });
        } else {

        }

      });
  }, [id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-content">
      <div className="row">
        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.year" />
          <input
            type="number"
            min="1900" max="2099" step="1"
            {...register("year", { required: true, min: 4 })}
            className="form-control"
          />
        </div>
        <div className="col-md-3 mt-3">
          <FormattedMessage id="week" />
          <input
            type="number"
            min="1" max="53" step="1"
            {...register("week", { required: true, min: 1 })}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mt-3">
          <FormattedMessage id="quality" />
          <select {...register("quality")} className="form-select">
            <option value="">Selecione</option>
            <option value="1">Poor</option>
            <option value="2">Fair</option>
            <option value="3">Great</option>
            <option value="4">Fantastic</option>
            <option value="5">Fantastic Plus</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="overall_standing" />
          <select {...register("overall")} className="form-select">
            <option value="">Selecione</option>
            <option value="1">Poor</option>
            <option value="2">Fair</option>
            <option value="3">Great</option>
            <option value="4">Fantastic</option>
            <option value="5">Fantastic Plus</option>
          </select>
        </div>
        { /*
        <div className="col-md-6 mt-3">
        <FormattedMessage id="reliability" />
          <select {...register("reliability")} className="form-select">
            <option value="" >Selecione</option>
            <option value="1">Poor</option>
            <option value="2">Fair</option>
            <option value="3">Great</option>
            <option value="4">Fantastic</option>
            <option value="5">Fantastic Plus</option>
          </select>

        </div>
        */ }
        <div className="col-md-6 mt-3">
          <FormattedMessage id="safety_compliance" />
          <select {...register("safety")} className="form-select">
            <option value="">Selecione</option>
            <option value="1">Poor</option>
            <option value="2">Fair</option>
            <option value="3">Great</option>
            <option value="4">Fantastic</option>
            <option value="5">Fantastic Plus</option>
          </select>
        </div>
      </div>

      <div className="row">


        <div className="col-md-6 mt-3">
          <FormattedMessage id="team" />
          <select {...register("team")} className="form-select">
            <option value="">Selecione</option>
            <option value="1">Poor</option>
            <option value="2">Fair</option>
            <option value="3">Great</option>
            <option value="4">Fantastic</option>
            <option value="5">Fantastic Plus</option>
          </select>
        </div>

        <div className="col-md-3 mt-3">
          <FormattedMessage id="routes-count" />
          <input
            type="number"
            {...register("routes")}
            value={routes}
            className="form-control"
            onChange={(e) => setRoutes(e.target.value.replace(/[^0-9]/g, ''))}
          />
        </div>

        <div className="col-md-3 mt-3">
          <FormattedMessage id="packages-count" />
          <input
            type="number"
            {...register("packages")}
            value={packages}
            className="form-control"
            onChange={(e) => setPackages(e.target.value.replace(/[^0-9]/g, ''))}
          />
        </div>

      </div>

      <div className="row bd-highlight">

        <div className="col-12 col-md-12">
          <FormattedMessage id="edit_save">
            {(edit_save) => {
              return (
                <button type="submit"
                  className="btn btn-orange btn-confirm float-end"
                >
                  <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                  {id ? edit_save.edit : edit_save.save}
                </button>
              );
            }}
          </FormattedMessage>


          <button
            className="btn btn-secondary btn-confirm float-end"
            onClick={() => {
              if (mode === "scorecard") {
                history.push("/scorecard");
              }
              else {
                console.log(setModal)
                setModal(false);
              }
            }}
          >
            <FormattedMessage id="cancel" />
          </button>
        </div>
      </div>
    </form>
  );
}