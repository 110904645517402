import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";
import {FormattedMessage} from "react-intl";

export default function ImportRoster(props) {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [file, setFile] = useState("");
    const [fileName, setFileName ] = useState('');

    function onFileChange(e) {
        console.log(e.target.files[0])
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function importFile() {
        if (file === "") {
            alert("No file uploaded");
        }

        const data = new FormData();
        data.append("filetoupload", file);

        api
            .post("upload", data, {
                headers: {
                    "x-access-token": GetToken(),
                    "Content-Type": "multipart/form-data;",
                },
            })
            .then((data) => {
                console.log(data.data);
                props.setRosterMethod(data.data);
                props.setFileNameRosterImport(fileName);
                history.push('/imports/roster/confirm');
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.log("end request");
            });
    }

    return (
        <>
        <Loading visible={loading} />
        <div className="row">
          <div className="col-12 col-md-12">
            <h5 className="text-left mb-3">
              Roster
            </h5>
          </div>
        </div>
        <div
          className="form-content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <label htmlFor="upload-roster" id="label-upload-roster" className="label-upload-photo">
            {fileName !== '' ? fileName : <FormattedMessage id="browse_file" />}
          </label>
          <input type="file" name="filetoupload" id="upload-roster" className="upload-photo" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onFileChange}/>
          <br />
          <FormattedMessage id="import_file">
            {(placeholder) => {
              return (
                <input
                  type="button"
                  className="btn btn-orange"
                  onClick={importFile}
                  style={{ backgroundColor: "blue" }}
                  value={placeholder}
                  disabled={fileName === ''}
                />
              );
            }}
          </FormattedMessage>
        </div>
      </>
    );
}
