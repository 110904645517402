import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddEdit() {
  const history = useHistory();
  let { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    if (id) {
      //edit
      api
        .put(
          "gate/update/" + id,
          {
            address: data.address,
            password: data.password,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          // title: "Success",
          //}).then(() => {
          history.push("/gates");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "gate/create",
          {
            address: data.address,
            password: data.password,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          history.push("/gates");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  }

  useEffect(() => {

    if (id) {
      api
        .get("gate/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          const value = data.data[0];
          setValue("address", value.address);
          setValue("password", value.password);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {

    }
  }, [id, setValue]);

  return (
    <div className="row">
      <div className="col-md-6 col-12">
        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
          <div className="row">
            <div className="col-md-12  mt-3">
              <FormattedMessage id="address" />
              <input
                {...register("address", { required: true, min: 1 })}
                className="form-control"
              />
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="codes" />
              <input
                {...register("password", { required: true, min: 1 })}
                className="form-control"
              />
            </div>
          </div>

          <div className="d-flex flex-row-reverse bd-highlight">
            <FormattedMessage id="edit_save">
              {(edit_save) => {
                return (
                  <button type="submit"
                    className="btn btn-orange btn-confirm float-end"
                  >
                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                    {id ? edit_save.edit : edit_save.save}
                  </button>
                );
              }}
            </FormattedMessage>
            <button
              className="btn btn-secondary btn-confirm"
              onClick={() => history.push("/gates")}
            >
              <FormattedMessage id="form.cancel" />
            </button>
          </div>
        </form>
      </div>

    </div>

  );
}
