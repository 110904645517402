import React, { useEffect, useState } from "react";
import { faAngleLeft, faAngleRight, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import Loading from "../components/Loading";
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DriverLicenses({ driverLicenses }) {

    const [licenses, setLicenses] = useState([]);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(5);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        const slice = driverLicenses.slice(offset, offset + perPage);
        setLicenses(slice);
        setPageCount(Math.ceil(driverLicenses.length / perPage));
    }, [driverLicenses,offset]);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        let offset = Math.ceil(selectedPage * perPage);
        setOffset(offset);
    };

    return (
        <>
            <div className="table-container">
                <table className="table table-list btn100">
                    <thead>
                        <tr>
                            <th scope="col"><FormattedMessage id="tabela.name" /></th>
                            <th scope="col"><FormattedMessage id="expired_Date" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {licenses.map((driver) => {
                            return (
                                <tr>
                                    <td>
                                        {driver.fname} {driver.lname}
                                    </td>
                                    <td>{driver.dlexpiration}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div>
                <ReactPaginate
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
            </div>
        </>
    );
}
