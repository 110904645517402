import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddEdit() {
  const history = useHistory();
  let { id } = useParams();

  const [carriers, setCarriers] = useState([]);
  const [phone, setPhone] = useState('');
  const [phoneMask, setPhoneMask] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    const phoneReplace = phone
      .replace("+", "")
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace(" ", "");

    if (isNaN(phoneReplace)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid phone",
      });
      return;
    }

    console.log(data);
    if (id) {
      //edit
      api
        .put(
          "device/update/" + id,
          {
            name: data.name,
            phone: phoneReplace,
            status: data.status,
            notes: data.notes,
            carrierid: data.carrierid,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          // }).then(() => {
          history.push("/devices");
          // });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "device/create",
          {
            name: data.name,
            phone: phoneReplace,
            status: data.status,
            notes: data.notes,
            carrierid: data.carrierid,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          history.push("/devices");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  }

  useEffect(() => {
    api
      .get("device/preload", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        setCarriers(data.data.carrier);
      })
      .catch((err) => {
        console.log(err.response);
      });

    api.get('param-country/getall', {
      headers: { "x-access-token": GetToken() },
    }).then(data => {

      const currentLang = localStorage.getItem('language');
      const lang = data.data.find(x => x.code === currentLang)?.phonemask
      setPhoneMask(lang);
    });

    if (id) {
      api
        .get("device/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          const value = data.data[0];

          setValue("name", value.name);
          setPhone(value.phone);
          setValue("notes", value.notes);
          setValue("carrierid", value.carrierid);
          setValue("status", value.status);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {

    }
  }, [id, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-content">
      <div className="row">
        <div className="col-md-6">
          <FormattedMessage id="form.name" />
          <input
            {...register("name", { required: true, min: 1 })}
            className="form-control"
          />
          {errors.name && (
            <span className="required"><FormattedMessage id="form.required" /></span>
          )}
        </div>

        <div className="col-md-6">
          <FormattedMessage id="form.phone" />
          <ReactInputMask
            mask={phoneMask}
            {...register("phone", { required: false })}
            className="form-control"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <FormattedMessage id="form.carrier" />
          <select {...register("carrierid", { required: true })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {carriers.map((carrier) => (
              <option value={carrier.id}>{carrier.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <FormattedMessage id="form.notes" />
          <input {...register("notes")} className="form-control" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.status" />
          <select {...register("status", { required: true })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            <FormattedMessage id='form.inactive'>
              {(message) => <option value="0">{message}</option>}
            </FormattedMessage>

            <FormattedMessage id='form.active'>
              {(message) => <option value="1">{message}</option>}
            </FormattedMessage>
          </select>
        </div>
      </div>

      <div className="d-flex flex-row-reverse bd-highlight">
        <FormattedMessage id="edit_save">
          {(edit_save) => {
            return (
              <button type="submit"
                className="btn btn-orange btn-confirm float-end"
              >
                <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                {id ? edit_save.edit : edit_save.save}
              </button>
            );
          }}
        </FormattedMessage>
        <button
          className="btn btn-secondary btn-confirm"
          onClick={() => history.push("/devices")}
        >
          <FormattedMessage id="form.cancel" />
        </button>
      </div>
    </form>
  );
}
