import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
import { faSave, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const eye = <FontAwesomeIcon icon={faEye} />;
export default function ChangePassword() {

    const [currentPassword, setCurrentPassword] = useState('');
    const [isRevealCurrentPassword, setIsRevealCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
    const history = useHistory();


    function onSubmit(e) {
        e.preventDefault();
        api
            .post(
                "login/change",
                {
                    oldpass: currentPassword,
                    newpass: newPassword,
                },
                { headers: { "x-access-token": GetToken() } }
            )
            .then((data) => {
               
                Swal.fire({
                    icon: "success",
                    title: "Password changed successfully",
                }).then(() => {
                    setCurrentPassword('');
                    setIsRevealCurrentPassword(false);
                    setNewPassword('');
                    setIsRevealNewPassword(false);
                    localStorage.setItem("token", data.data.token);
                });
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.response.data.message,
                });
            });
    }

    return (
        <div className="container mt-3">
            <div className='row mb-3'>
                <div className='col-6 col-md-10'>
                    <p className="title"><FormattedMessage id="change-password" /></p>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <form onSubmit={onSubmit} className="form-content">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="current-password" />
                                    <div className="pwd-container">
                                        <input
                                            name="currentPassword"
                                            type={isRevealCurrentPassword ? "text" : "password"}
                                            value={currentPassword}
                                            className="form-control"
                                            onChange={e => setCurrentPassword(e.target.value)}
                                        />
                                        <i
                                            onClick={() => setIsRevealCurrentPassword(prevState => !prevState)}
                                        >{eye}</i>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <FormattedMessage id="new-password" />
                                    <div className="pwd-container">
                                        <input
                                            name="newPassword"
                                            type={isRevealNewPassword ? "text" : "password"}
                                            value={newPassword}
                                            className="form-control"
                                            onChange={e => setNewPassword(e.target.value)}
                                        />
                                        <i
                                            onClick={() => setIsRevealNewPassword(prevState => !prevState)}
                                        >{eye}</i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row-reverse bd-highlight">
                                <button type="submit"
                                    className="btn btn-orange btn-confirm float-end"
                                >
                                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                                    <FormattedMessage id="continue" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
