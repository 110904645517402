import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import api from "../api/axios";

import Logo from "../assets/images/urban.png";
import Loading from "../components/Loading";

export default function Define() {
  const history = useHistory();

  let { id } = useParams();

  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [loading, setLoading] = useState(false);

  function HandleSend() {
    setLoading(true);

    api
      .post("define/" + id, { pass: pass })
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.data.message,
        }).then(() => {
          history.push("/");
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      }).finally(_ => {
        setLoading(false);
      });
  }

  return (
    <div className="container mt-5 d-flex justify-content-center flex-wrap">
      <Loading visible={loading} />

      <div className="d-flex justify-content-center mb-5 mt-5 fill">
        <img src={Logo} alt="logo" />
      </div>

      <div className="white-container col-md-6">
        <p className="container-title">Create Password</p>

        <div className="form-floating mb-3">
          <input
            type="password"
            className="form-control"
            id="floatingPasswordInput"
            value={pass}
            onChange={(e) => {
              setPass(e.target.value);
            }}
            placeholder="Password"
          />
          <label htmlFor="floatingPasswordInput">New password</label>
        </div>

        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingConfPasswordInput"
            value={confPass}
            onChange={(e) => {
              setConfPass(e.target.value);
            }}
            placeholder="Password"
          />
          <label htmlFor="floatingConfPasswordInput">
            Confirm new password
          </label>
        </div>

        <div className="d-grid gap-2 col-12 mx-auto mt-4">
          <button onClick={HandleSend} className="btn btn-primary">
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
