import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";
import jwt_decode from "jwt-decode";

import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";

export default function List(props) {

  const history = useHistory();
  const [gates, setGates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState();

  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [selected, setSelected] = useState(0);

  function deleteGateById(id) {
    let newGates = gates;
    newGates = newGates.filter(gates => gates.id != id);
    setGates(newGates);
    setUpdate(!update);
  }

  useEffect(() => {
    UpdateList();
  }, [offset]);

  function UpdateList() {
    setLoading(true);

    api
      .get("gate/getall", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        const SearchTerm = props.searchTerm.toLowerCase();
        const filter = data.data.filter((x) =>
          x.address.toLowerCase().includes(SearchTerm)
        );
        const slice = filter.slice(offset, offset + perPage);
        setGates(slice);
        setPageCount(Math.ceil(filter.length / perPage));
      })
      .catch((err) => {

      })
      .finally(() => {
        setLoading(false);
      });


    const token = GetToken();
    if (token === "null") {
      history.push("/");
      return;
    }
    const userInfo = jwt_decode(token);
    setRole(userInfo.role);

  }

  function DeleteGate(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("gate/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Gate deleted",
            }).then(() => {
              //UpdateList();
              deleteGateById(id);
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  if (role == 1) {

    return (
      <div>
        <Loading visible={loading} />
        <div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>
        <div className="table-container">
          <table className="table table-list btn100">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage id="address" /></th>
                <th scope="col"><FormattedMessage id="codes" /></th>
              </tr>
            </thead>
            <tbody>
              {gates.map((gate) => {
                return (
                  <tr>
                    <td>{gate.address}</td>
                    <td>{gate.password}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>
      </div>
    );

  }


  return (
    <div>
      <Loading visible={loading} />

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="address" /></th>
              <th scope="col"><FormattedMessage id="codes" /></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {gates.map((gate) => {
              return (
                <tr>
                  <td>{gate.address}</td>
                  <td>{gate.password}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history.push("/gates/edit/" + gate.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteGate(gate.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );

}
