import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import {
    faTruck,
} from "@fortawesome/free-solid-svg-icons";
import api, { GetToken } from "../../../api/axios";
import Loading from "../../../components/Loading";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

import moment from "moment";

export default function NetradyneConfirm({ netradyne, fileNameImport }) {
    const history = useHistory();
    const [drivers, setDrivers] = useState([]);
    const [uploadData, setUploadData] = useState();
    const [checkAll, setCheckAll] = useState(true);
    const [uploadObject, setUploadObject] = useState([]);
    const [uploadErrors, setUploadErrors] = useState([]);
    const [visibleDriverModal, setVisibleDriverModal] = useState(false);
    const [startDriverData, setStartDriverData] = useState({})
    const [loading, setLoading] = useState();

    useEffect(() => {
        if (netradyne === undefined) {
            history.push('/coaching/import');
            return;
        }
        api.get("driver/getall", { headers: { "x-access-token": GetToken() } })
            .then((driversReturn) => {
                netradyne.netradyne.map(obj => {
                    obj.active = true;
                    if (typeof obj.userid == 'undefined') {
                        obj.userid = null;
                    }
                });
                setDrivers(driversReturn.data);
                setUploadData(netradyne);
                UpdateUploadObject(netradyne);
            });
    }, []);

    function ChangeDriver(e, index) {
        let data = uploadData;
        data.netradyne[index].userid = e.target.value;
        setUploadData(data);
        UpdateUploadObject(data);
    }


    function UpdateUploadObject(uploadDataFunc) {
        let upload_object = {
            netradyne: [],
        };
        let validDrivers = true;
        uploadDataFunc.netradyne.forEach(retorno => {
            if (!retorno.active) {
                return null;
            }
            if (retorno.userid == undefined || retorno.userid == 0 || retorno.userid == null || retorno.userid == 'undefined') {
                validDrivers = false;
            }
            upload_object.netradyne.push(retorno)
        })
        setUploadObject(upload_object)
    }


    function SaveNetradyne() {
        let upload_object = {
            netradyne: [],
        };
        let validDrivers = true;

        uploadData.netradyne.forEach(retorno => {
            if (!retorno.active) {
                return null;
            }
            if (retorno.userid == undefined || retorno.userid == 0 || retorno.userid == null || retorno.userid == 'undefined') {
                validDrivers = false;
            }
            upload_object.netradyne.push(retorno)
        });

        if (!validDrivers) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'Select Driver',
            })
            return;
        }

        setLoading(true);

        api
            .post("netradyne/save", upload_object, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.data.message,
                }).then(_ => {
                    history.push('/coaching/import')
                })
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: '',
                });

            }).finally(_ => {
                setLoading(false);
                setUploadObject(upload_object)
            })

    }

    function checkAllFunc(e) {
        let uploadDataClone = uploadData;
        uploadDataClone.netradyne.forEach(retorno => {
            retorno.active = e.target.checked;
            return retorno;
        });
        setCheckAll(e.target.checked)
        setUploadData(uploadDataClone);
        UpdateUploadObject(uploadDataClone);
    }

    function ChangeSelect(e, index) {
        let uploadData_clone = uploadData;
        uploadData_clone.netradyne.map((data, key) => {
            if (key != index) {
                return null;
            }
            data.active = !data.active
        });
        setUploadData(uploadData_clone);
        UpdateUploadObject(uploadData_clone);
    }

    return (
        <div >
            <Loading visible={loading} />

            <div className="row mb-3">
                <div className="col-md-8">
                    <p className="title  mb-0 pb-0">Netradyne <FormattedMessage id="import" /> </p>
                    <p>{fileNameImport}</p>
                </div>
                <div className="col-6 col-md-4 text-end">
                    <button className="btn d-inline btn-orange me-3" style={{ backgroundColor: "red" }} onClick={() => { history.push('/coaching/import') }}>
                        <FormattedMessage id="form.cancel" />
                    </button>
                    <button
                        className="btn d-inline btn-orange"
                        style={{ backgroundColor: "blue" }}
                        onClick={SaveNetradyne}
                    >
                        <FormattedMessage id="save" />
                    </button>
                </div>
            </div>

            <div className="table-container">
                <table className="table table-list btn100" style={{ fontSize: 12 }}>
                    <thead>
                        <tr style={{ fontWeight: 600, textAlign: "left" }}>
                            <td>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={checkAll}
                                        onChange={(e) => checkAllFunc(e)}
                                        id="flexCheckIndeterminate"
                                    />
                                </div>
                            </td>
                            <td><FormattedMessage id="tabela.name" /></td>
                            <td><FormattedMessage id="tabela.driver" /></td>
                            <td>Green</td>
                            <td>Overspeeding</td>
                            <td>Score</td>
                            <td>Star</td>
                            <td>Highg</td>
                            <td>Impact</td>
                            <td>Initiated</td>
                            <td>Sign</td>
                        </tr>
                    </thead>
                    <tbody>
                        {uploadData?.netradyne?.map((data, key) => {
                            return (
                                <tr style={{ textAlign: "left" }}>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                key={key}
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={data.active}
                                                onChange={(e) => ChangeSelect(e, key)}
                                            />
                                        </div>
                                    </td>
                                    <td>{data.name}</td>
                                    <td>
                                        <select className="form-select form-select-sm" onChange={(e) => ChangeDriver(e, key)} style={{ borderColor: data.userid == undefined || data.userid == 0 || data.userid == null || data.userid == 'undefined' ? 'red' : '' }}>
                                            <FormattedMessage id="tabela.select_driver">
                                                {(text) => {
                                                    return (
                                                        <option value='0'>{text}</option>
                                                    );
                                                }}
                                            </FormattedMessage>
                                            {drivers.map((driver) => (
                                                <option selected={driver.id == data.userid} value={driver.id}>{driver.fname} {driver.lname}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>{data.green}</td>
                                    <td>{data.overspeeding}</td>
                                    <td>{data.score}</td>
                                    <td>{data.star}</td>
                                    <td>{data.highg}</td>
                                    <td>{data.impact}</td>
                                    <td>{data.initiated}</td>
                                    <td>{data.sign}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
