import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaintenanceFile from "../../components/MaintenanceFile";
import Swal from 'sweetalert2';
import { FormattedMessage } from "react-intl";
import { faSave, faTrash, faEdit, faPlus, faClock } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
//import ReactInputMask from "react-input-mask";
import moment from "moment";
import Loading from "../../components/Loading";

export default function AddEdit(props) {
    const history = useHistory();
    let { id } = useParams();

    const [preload, setPreload] = useState([]);
    const [maintenance, setMaintenance] = useState([]);
    const [visibleHistoric, setVisibleHistoric] = useState(false);
    const [visibleFile, setVisibleFile] = useState(true);
    const [loading, setLoading] = useState(false);
    const [timeMask, setTimeMask] = useState();
    const [date, setDate] = useState("");
    const [mileage, setMileage] = useState("");
    const [vendorId, setVendorId] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [invoice, setInvoice] = useState("");
    const [amount, setAmount] = useState(0);
    const [notes, setNotes] = useState("");
    const [idHistoric, setIdHistoric] = useState("");
    const [vendors, setVendors] = useState([]);
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState("");

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm();

    function onSubmit(data) {
        if (id) {
            //edit
            api
                .put(
                    "vehicle/update/" + id,
                    {
                        name: data.name,
                        deviceid: data.deviceid == "" ? "0" : data.deviceid,
                        space: data.space,
                        plate: data.plate,
                        startdate: data.startdate == "" ? null : data.startdate,
                        enddate: data.enddate == "" ? null : data.enddate,
                        expiration: data.expiration == "" ? null : data.expiration,
                        stateid: data.stateid == "" ? "0" : data.stateid,
                        ownershipid: data.ownershipid == "" ? "0" : data.ownershipid,
                        vin: data.vin,
                        type: data.type,
                        gascard: data.gascard,
                        make: data.make,
                        mileage: data.mileage,
                        model: data.model,
                        year: data.year,
                        companyid: data.companyid == "" ? "0" : data.companyid,
                        rental: data.rental,
                        statusid: data.statusid == "" ? "0" : data.statusid,
                        cdv: data.cdv == "" ? "0" : data.cdv
                    },
                    { headers: { "x-access-token": GetToken() } }
                )
                .then((data) => {
                    //Swal.fire({
                    //    icon: 'success',
                    //    title: 'Success',
                    //}).then(() => {
                    history.push('/vehicles');
                    // });
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response.data.message,
                    });
                });
        } else {
            //create
            api
                .post(
                    "vehicle/create",
                    {
                        name: data.name,
                        deviceid: data.deviceid == "" ? "0" : data.deviceid,
                        space: data.space,
                        plate: data.plate,
                        startdate: data.startdate == "" ? null : data.startdate,
                        enddate: data.enddate == "" ? null : data.enddate,
                        expiration: data.expiration == "" ? null : data.expiration,
                        stateid: data.stateid == "" ? "0" : data.stateid,
                        ownershipid: data.ownershipid == "" ? "0" : data.ownershipid,
                        vin: data.vin,
                        type: data.type,
                        gascard: data.gascard,
                        make: data.make,
                        mileage: data.mileage,
                        model: data.model,
                        year: data.year,
                        companyid: data.companyid == "" ? "0" : data.companyid,
                        rental: data.rental,
                        statusid: data.statusid == "" ? "0" : data.statusid,
                        cdv: data.cdv == "" ? "0" : data.cdv
                    },
                    { headers: { "x-access-token": GetToken() } }
                )
                .then((data) => {
                    //Swal.fire({
                    //   icon: 'success',
                    //    title: 'Success',
                    //}).then(() => {
                    history.push('/vehicles');
                    // });
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.response.data.message,
                    });
                });
        }
    }

    function formatDate(date) {

        return moment(date).format("MM/DD/YYYY");

        /*
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [month, day, year].join('/');
        */
    }

    function getMaintenance() {

        api
            .get("maintenance/getbyvehicleid/" + id, {
                headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
                console.log(data);
                setMaintenance(data.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }



    function onCreate(event) {
        setLoading(true);

        event.preventDefault();

        /*const time_formated =
            (time.getHours() < 10 ? "0" : "") +
            time.getHours() +
            ":" +
            (time.getMinutes() < 10 ? "0" : "") +
            time.getMinutes();

        if (time_formated.includes("NaN")) {
            alert("Invalid Time");
        }*/

        if (idHistoric != '') {
            api
                .put(
                    "maintenance/update/" + idHistoric,
                    {
                        date: date,
                        //time: time_formated,
                        vendorid: !vendorId ? "0" : vendorId,
                        invoice: invoice,
                        amount: !amount ? 0 : amount,
                        notes: notes,
                        mileage: mileage,
                    },
                    { headers: { "x-access-token": GetToken() } }
                )
                .then((data) => {

                    const newMaintenance = maintenance.map(item => {
                        if (item.id === idHistoric) {
                            item.date = date;
                            //item.time = time_formated;
                            item.vendorid = vendorId;
                            item.vendorname = vendorName;
                            item.invoice = invoice;
                            item.amount = !amount ? 0 : amount;
                            item.notes = notes;
                            item.mileage = mileage;
                        }
                        return item;
                    });

                    console.log(newMaintenance);
                    setMaintenance(newMaintenance);
                    setIdHistoric("");
                    setDate("");
                    setMileage("");
                    setVendorId("");
                    setVendorName("");
                    setInvoice("");
                    setAmount("");
                    setNotes("");
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err.response.data.message,
                    });
                }).finally(() => {
                    setVisibleHistoric(false);
                    setLoading(false);
                });
        } else {
            api
                .post(
                    "maintenance/create",
                    {
                        vehicleid: id,
                        date: date,
                        //time: time_formated,
                        vendorid: !vendorId ? "0" : vendorId,
                        invoice: invoice,
                        amount: !amount ? 0 : amount,
                        notes: notes,
                        mileage: mileage,
                    },
                    { headers: { "x-access-token": GetToken() } }
                )
                .then((data) => {

                    if (data.data.insertId) {
                        api
                            .get("maintenance/getbyid/" + data.data.insertId, {
                                headers: { "x-access-token": GetToken() },
                            })
                            .then((data) => {
                                const value = data.data[0];
                                let newMaintenance = [ value, ...maintenance ];
                                setMaintenance(newMaintenance);
                                setIdHistoric("");
                                setDate("");
                                setMileage("");
                                setVendorId("");
                                setVendorName("");
                                setInvoice("");
                                setAmount("");
                                setNotes("");
                            })
                            .catch((err) => {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: err.response.data.message,
                                });
                            }).finally(() => {
                                setVisibleHistoric(false);
                                setLoading(false);
                            });
                    }
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err.response.data.message,
                    });
                });
        }


    }


    /*function tConvert(time) {
        if (timeMask !== "12H") {
            return time;
        }
        // Check correct time format and split into components
        time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
    }*/


    useEffect(() => {
        props.clear();
        api
            .get("vehicle/preload/1", { headers: { "x-access-token": GetToken() } })
            .then((dataPreload) => {
                setPreload(dataPreload.data);

                if (id) {
                    api
                        .get("vehicle/getbyid/" + id, {
                            headers: { "x-access-token": GetToken() },
                        })
                        .then((data) => {
                            const value = data.data[0];

                            setValue("name", value.name);
                            setValue("deviceid", value.deviceid);
                            setValue("space", value.space);
                            setValue("plate", value.plate);
                            setValue("stateid", value.stateid);
                            setValue("ownershipid", value.ownershipid);
                            setValue("vin", value.vin);
                            setValue("type", value.type);
                            setValue("make", value.make)
                            setValue("gascard", value.gascard);
                            setValue("mileage", value.mileage);
                            setValue("model", value.model);
                            setValue("year", value.year);
                            setValue("companyid", value.companyid);
                            setValue("rental", value.rental);
                            setValue("statusid", value.statusid);
                            setValue("cdv", value.cdv);

                            if (value.startdate != null) {
                                setValue("startdate", value.startdate);
                            }

                            if (value.enddate != null) {
                                setValue("enddate", value.enddate);
                            }

                            if (value.expiration != null) {
                                setValue("expiration", value.expiration);
                            }
                        })
                        .catch((err) => {
                            console.log(err.response);
                        });

                    api
                        .get("param-country/getall", {
                            headers: { "x-access-token": GetToken() },
                        })
                        .then((data) => {
                            const currentLang = localStorage.getItem("language");
                            const lang = data.data.find((x) => x.code === currentLang)?.timemask;
                            setTimeMask(lang);
                        });

                } else {
                    //setValue("startdate", moment().format('YYYY-MM-DD'));
                    //setValue("enddate", moment().format('YYYY-MM-DD'));
                    //setValue("expiration", moment().format('YYYY-MM-DD'));
                }
            })
            .catch((err) => {
                console.log(err.response);
            });

    }, [id]);

    useEffect(() => {
        api
            .get("/param-vendor/getall", { headers: { "x-access-token": GetToken() } })
            .then((dataVendors) => {
                setVendors(dataVendors.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    function closeModal() {
        setIdHistoric("");
        setDate("");
        setMileage("");
        setVendorId("");
        setVendorName("");
        setInvoice("");
        setAmount("");
        setNotes("");
        setVisibleHistoric(false);
    }

    function editMaintenance(idMaintenance) {
        api
            .get("maintenance/getbyid/" + idMaintenance, {
                headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
                const value = data.data[0];
                setDate(value.date);
                setMileage(value.mileage);
                setVendorId(value.vendorid);
                setVendorName(value.vendorname);
                setInvoice(value.invoice);
                setAmount(value.amount);
                setNotes(value.notes);
                setIdHistoric(idMaintenance);

                /*const current = new Date();
                const dateTimeTwo = new Date(
                    `${current.getFullYear()}/${current.getMonth() + 1
                    }/${current.getDate()} ${value.time}`
                );
                setTime(dateTimeTwo);
                */
            })
            .catch((err) => {
                console.log(err.response);
            }).finally(() => {
                setVisibleHistoric(true);
            });
    }


    function DeleteMaintenance(id) {
        setLoading(true);

        Swal.fire({
            title: "Confirm delete",
            text: "Are you sure you want to delete this item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#565e64",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                api
                    .delete("maintenance/delete/" + id, {
                        headers: { "x-access-token": GetToken() },
                    })
                    .then(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Item deleted",
                        }).then(() => {
                            //UpdateList();
                            let newMaintenance = maintenance;
                            newMaintenance = newMaintenance.filter(item => item.id != id);
                            setMaintenance(newMaintenance);
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: err.response.data.message,
                        });
                    }).finally(() => {
                        setLoading(false);

                    });
            }
        });
    }

    return (

        <>
            <Loading visible={loading} />

            <Modal ariaHideApp={false}
                isOpen={visibleHistoric}>

                <form onSubmit={onCreate} className="form-content">
                    <div className="row">
                        <div className="col-md-12  mt-3">
                            <h4><FormattedMessage id="vehicle_maintenance" /></h4>
                        </div>
                        <div className="col-md-4  mt-3">
                            <FormattedMessage id="form.date" />
                            <input
                                className="form-control"
                                type="date"
                                value={date}
                                required
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-md-4  mt-3">
                            <FormattedMessage id="mileage" />
                            <input
                                className="form-control"
                                type="number"
                                value={mileage}
                                onChange={(e) => {
                                    setMileage(e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-md-4  mt-3">
                            <FormattedMessage id="vendor" />
                            <select 
                                onChange={(e) => {
                                    setVendorId(e.target.value);
                                    setVendorName(e.target.selectedOptions[0].text);
                                }}
                                value={vendorId}
                                className="form-select">
                                <FormattedMessage id="form.select">
                                    {(placeholder) => {
                                        return <option value="">{placeholder}</option>;
                                    }}
                                </FormattedMessage>
                                {vendors?.map((param) => (
                                    <option value={param.id}>{param.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-4  mt-3">
                            <FormattedMessage id="invoice" />
                            <input
                                className="form-control"
                                type="text"
                                value={invoice}
                                onChange={(e) => {
                                    setInvoice(e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-md-4  mt-3">
                            <FormattedMessage id="amount" />
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                pattern="^\d+(?:\.\d{1,2})?$"
                                value={amount}
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-md-12 mt-3">
                            <FormattedMessage id="details" />
                            <textarea className="form-control"
                                rows="2"
                                style={{ resize: 'none' }}
                                maxLength="2048"
                                value={notes}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />
                        </div>

                    </div>

                    { idHistoric &&
                        <div className="col-md-12 mt-3">
                            <MaintenanceFile maintenanceId = {idHistoric} />
                        </div>
                    }

                    <div className="d-flex flex-row-reverse bd-highlight">
                        <button
                            type="submit"
                            className="btn btn-primary btn-confirm float-end"
                        >
                            <FormattedMessage id="save" />
                        </button>

                        <button
                            className="btn btn-secondary btn-confirm float-end"
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            <FormattedMessage id="form.cancel" />
                        </button>

                    </div>
                </form>
            </Modal>
            <form onSubmit={handleSubmit(onSubmit)} className="form-content">
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.name" />
                        <input
                            {...register("name", { required: true })}
                            className="form-control"
                        />
                        {errors.name && (
                            <span className="required"><FormattedMessage id="form.required" /></span>
                        )}
                    </div>

                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.assigned_device" />
                        <select {...register("deviceid", { required: false })} className="form-select">
                            <FormattedMessage id="form.select">
                                {(placeholder) => {
                                    return <option value="">{placeholder}</option>;
                                }}
                            </FormattedMessage>
                            {preload?.device?.map((param) => (
                                <option value={param.id}>{param.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.parking_space" />
                        <input {...register("space")} className="form-control" />
                    </div>

                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.license_plate" />
                        <input {...register("plate")} className="form-control" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.license_plate_expiration" />
                        <input
                            {...register("expiration", { required: false })}
                            className="form-control"
                            type="date"
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <div className="col-md-6">
                                <FormattedMessage id="form.start_date" />
                                <input
                                    {...register("startdate", { required: false })}
                                    className="form-control"
                                    type="date"
                                />
                            </div>

                            <div className="col-md-6">
                                <FormattedMessage id="form.end_date" />
                                <input
                                    {...register("enddate", { required: false })}
                                    className="form-control"
                                    type="date"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.state" />
                        <select {...register("stateid", { required: false })} className="form-select">
                            <FormattedMessage id="form.select">
                                {(placeholder) => {
                                    return <option value="">{placeholder}</option>;
                                }}
                            </FormattedMessage>
                            {preload?.state?.map((param) => (
                                <option value={param.id}>{param.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-6 mt-3">
                        VIN
                        <input {...register("vin")} className="form-control" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.company" />
                        <select {...register("company", { required: false })} className="form-select">
                            <FormattedMessage id="form.select">
                                {(placeholder) => {
                                    return <option value="">{placeholder}</option>;
                                }}
                            </FormattedMessage>
                            {preload?.company?.map((param) => (
                                <option value={param.id}>{param.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.ownership" />
                        <select {...register("ownership", { required: false })} className="form-select">
                            <FormattedMessage id="form.select">
                                {(placeholder) => {
                                    return <option value="">{placeholder}</option>;
                                }}
                            </FormattedMessage>
                            {preload?.ownership?.map((param) => (
                                <option value={param.id}>{param.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <FormattedMessage id="form.vehicle_type" />
                                <input {...register("type")} className="form-control" />
                            </div>
                            <div className="col-md-6 mt-3">
                                Gas Card
                                <input {...register("gascard")} className="form-control" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <FormattedMessage id="form.make" />
                                <input {...register("make")} className="form-control" type='number' />
                            </div>
                            <div className="col-md-6 mt-3">
                                <FormattedMessage id="form.mileage" />
                                <input {...register("mileage")} className="form-control" type='number' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <FormattedMessage id="form.model" />
                                <input {...register("model")} className="form-control" />
                            </div>

                            <div className="col-md-6 mt-3">
                                <FormattedMessage id="form.year" />
                                <input {...register("year")} className="form-control" type='number' />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-3">
                        <FormattedMessage id="form.rental" />
                        <input {...register("rental")} className="form-control" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mt-3">
                        Status
                        <select {...register("statusid", { required: false })} className="form-select">
                            <FormattedMessage id="form.select">
                                {(placeholder) => {
                                    return <option value="">{placeholder}</option>;
                                }}
                            </FormattedMessage>
                            {preload?.status?.map((param) => (
                                <option value={param.id}>{param.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 mt-3">
                        CDV
                        <select {...register("cdv", { required: false })} className="form-select">
                            <FormattedMessage id="form.select">
                                {(placeholder) => {
                                    return <option value="">{placeholder}</option>;
                                }}
                            </FormattedMessage>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-4">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" onClick={getMaintenance} data-bs-toggle="collapse" data-bs-target="#collapseOne" >
                                    <FormattedMessage id="vehicle_maintenance" />
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div className="row text-end">
                                        <div className="col-12 col-md-3 offset-md-9">
                                            <a className="btn btn-orange btn100" onClick={() => {
                                                setVisibleHistoric(true);
                                            }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                                                <FormattedMessage id="add_history_record" />
                                            </a>
                                        </div>
                                    </div>


                                    <div className="table-container">
                                        <table className="table table-list btn100">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><FormattedMessage id="date" /></th>
                                                    <th scope="col"><FormattedMessage id="mileage" /></th>
                                                    <th scope="col"><FormattedMessage id="vendor" /></th>
                                                    <th scope="col"><FormattedMessage id="invoice" /></th>
                                                    <th scope="col"><FormattedMessage id="amount" /></th>
                                                    <th scope="col"><FormattedMessage id="details" /></th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {maintenance?.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{formatDate(item.date)}</td>
                                                            <td>{item.mileage}</td>
                                                            <td>{item.vendorname}</td>
                                                            <td>{item.invoice}</td>
                                                            <td>$ {item.amount}</td>
                                                            <td>{item.notes}</td>
                                                            <td className='icon-cell'>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    onClick={() => editMaintenance(item.id)}
                                                                />
                                                            </td>
                                                            <td className='icon-cell'>
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    onClick={() => DeleteMaintenance(item.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row bd-highlight">

                    <div className="col-12 col-md-7">

                        {id ? (
                            <a onClick={() => history.push("/vehicles/historic/" + id)} className="btn btn-secondary btn-confirm">
                                <FormattedMessage id="historic" />
                            </a>
                        ) : null}

                        {id ? (
                            <a onClick={() => history.push("/vehicles/assigned/" + id)} className="btn btn-secondary btn-confirm">
                                <FormattedMessage id="assignment" />
                            </a>
                        ) : null}

                    </div>

                    <div className="col-12 col-md-5 text-end">
                        <FormattedMessage id="edit_save">
                            {(edit_save) => {
                                return (
                                    <button type="submit"
                                        className="btn btn-orange btn-confirm float-end"
                                    >
                                        <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                                        {id ? edit_save.edit : edit_save.save}
                                    </button>
                                );
                            }}
                        </FormattedMessage>
                        <button
                            className="btn btn-secondary btn-confirm"
                            onClick={() => history.push("/vehicles")}
                        >
                            <FormattedMessage id="form.cancel" />
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
