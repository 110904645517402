import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";

export default function ViewScore({ mode = "score", setModal = null, scoreId }) {
  const history = useHistory();
  const [score, setScore] = useState([]);

  const isEdit = scoreId != null;
  let id = scoreId;

  useEffect(() => {

    if (id) {
      api
        .get("score-user/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          console.log(data.data);
          const value = data.data[0];
          setScore(data.data[0]);
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response?.data.message,
          });
        });
    }

  }, [id]);


  return (
    <div className="p-3">
      <div className="row ps-2">
        <div className="col-12 col-md-12">
          <h2 className="float-start me-4"><FormattedMessage id="drivers" /></h2>
          <small><FormattedMessage id="menu.scorecard" /></small><br />
          <b><FormattedMessage id="week" /> {score.week} - {score.year}</b>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.year" />
          <p style={{fontWeight:'bold'}}>{score.year}</p>
        </div>
        <div className="col-md-3 mt-3">
          <FormattedMessage id="week" />
          <p style={{fontWeight:'bold'}}>{score.week}</p>
        </div>
        <div className="col-md-3 mt-3">
          <FormattedMessage id="overall" />
          <p style={{fontWeight:'bold'}}>{score.overall}</p>
        </div>
        <div className="col-md-3 mt-3">
          <FormattedMessage id="delivered" />
          <p style={{fontWeight:'bold'}}>{score.delivered}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mt-2">
          <FormattedMessage id="focus" />
          <p style={{fontWeight:'bold'}}>{score.focus}</p>
        </div>
        <div className="col-md-3 mt-2">
          Fico
          <p style={{fontWeight:'bold'}}>{score.fico}</p>
        </div>

        <div className="col-md-3 mt-2">
          <FormattedMessage id="seatbelt" />
          <p style={{fontWeight:'bold'}}>{score.seatbelt}</p>
        </div>
        <div className="col-md-3 mt-2">
          <FormattedMessage id="speeding" />
          <p style={{fontWeight:'bold'}}>{score.speeding}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-2">
          <FormattedMessage id="distract" />
          <p style={{fontWeight:'bold'}}>{score.distract}</p>
        </div>
        <div className="col-md-2 mt-2">
          <FormattedMessage id="distance" />
          <p style={{fontWeight:'bold'}}>{score.distance}</p>
        </div>

        <div className="col-md-2 mt-2">
          <FormattedMessage id="violations" />
          <p style={{fontWeight:'bold'}}>{score.violations}</p>
        </div>
        <div className="col-md-2 mt-2">
          DRC
          <p style={{fontWeight:'bold'}}>{score.dcr}</p>
        </div>
        <div className="col-md-2 mt-2">
          POD
          <p style={{fontWeight:'bold'}}>{score.pod}</p>
        </div>
        <div className="col-md-2 mt-2">
          CC
          <p style={{fontWeight:'bold'}}>{score.cc}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-2">
          DAR
          <p style={{fontWeight:'bold'}}>{score.dar}</p>
        </div>
        <div className="col-md-2 mt-2">
          SWC-POD
          <p style={{fontWeight:'bold'}}>{score.swcpod}</p>
        </div>

        <div className="col-md-2 mt-2">
          SWC-CC
          <p style={{fontWeight:'bold'}}>{score.swccc}</p>
        </div>
        <div className="col-md-2 mt-2">
          SWC-SC
          <p style={{fontWeight:'bold'}}>{score.swcsc}</p>
        </div>
        <div className="col-md-2 mt-2">
          SWC-AD
          <p style={{fontWeight:'bold'}}>{score.swcad}</p>
        </div>
        <div className="col-md-2 mt-2">
          DNRS
          <p style={{fontWeight:'bold'}}>{score.dnrs}</p>
        </div>
      </div>
      <div className="row bd-highlight">
        <div className="col-12 col-md-12">
          <button
            className="btn btn-secondary btn-confirm float-end"
            onClick={() => {
              setModal(false);
            }}
          >
            <FormattedMessage id="cancel" />
          </button>
        </div>
      </div>
    </div>
  );
}
