import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";
import ListItem from "./listItem";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSort, faSortUp, faSortDown, faTrashAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import { id } from "date-fns/locale";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Modal from 'react-modal';


const customStyles = {
  content: {
    top: '50%',
    with: '70%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function List({ date, setLastField, setLastOrder, search }) {
  const [preload, setPreload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rosters, setRoster] = useState([]);
  const [driversOrder, setDriversOrder] = useState();
  const [vehiclesOrder, setVehiclesOrder] = useState();
  const [routeOrder, setRouteOrder] = useState();
  //const [deviceOrder, setDeviceOrder] = useState();
  //const [statusOrder, setStatusOrder] = useState();
  const [timeOrder, setTimeOrder] = useState();
  const [stagingOrder, setStagingOrder] = useState();
  const [timeMask, setTimeMask] = useState();
  const [checkAll, setCheckAll] = useState();
  const [messages, setMessages] = useState();
  const [allRosters, setAllRosters] = useState()
  const [update, setUpdate] = useState(false);
  const [waves, setWaves] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const [issue, setIssue] = useState(false);
  const [counseling, setCounseling] = useState(false);
  const [statusName, setStatusName] = useState();

  const [typeOrder, setTypeOrder] = useState();
  const [order, setOrder] = useState();
  const [rosterId, setRosterId] = useState();



  const history = useHistory();

  useEffect(() => {
    const token = GetToken();

    if (token == "null") {
      history.push("/");
      return;
    }

    const userInfo = jwt_decode(token);
    var dateExpiring = new Date(userInfo.exp * 1000).toLocaleString().substr(0, 10);
    var dateNow = new Date().toLocaleString().substr(0, 10);

    dateExpiring = dateExpiring.split('/')[2] + '-' + dateExpiring.split('/')[1] + '-' + dateExpiring.split('/')[0];
    dateNow = dateNow.split('/')[2] + '-' + dateNow.split('/')[1] + '-' + dateNow.split('/')[0];

    //console.log('login expiring: ', new Date(userInfo.exp * 1000).toLocaleString(), ', date now: ', new Date().toLocaleString())
    //Verify if token expired
    var dateUserInfo = new Date(userInfo.exp * 1000);
    var dateActual = new Date(Date.now());

    if (dateActual.getTime() < dateUserInfo.getTime()) {
      if (dateNow == dateExpiring) {
        api
          .post("login/refresh", { headers: { "x-access-token": GetToken() } })
          .then((data) => {
            localStorage.setItem("token", data.data.token);
          })
          .catch((err) => {
            console.log('login expired, redirecting to login');
            localStorage.setItem("token", null);
            history.push("/");
          });
        return;
      }
    } else {
      console.log('login expired, redirecting to login');
      localStorage.setItem("token", null);
      history.push("/");
      return;
    }

    setVehiclesOrder('');
    setDriversOrder('');
    setTypeOrder('asc');
    setOrder('time');
    setLastOrder('asc');
    setLastField('time');

    UpdateList();



  }, [date, search]);

  function UpdateList() {
    setLoading(true);
    if (!date) return;
    api
      .post("message/getbydate", { date: date }, { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setMessages(data.data);
      }).catch(err => {
        console.log(err);
      });
    api
      .get("roster/getbytotaldate/" + date, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        setWaves(data.data);
      }).catch(err => {
        console.log(err);
      });

    api
      .get("roster/preload", { headers: { "x-access-token": GetToken() } })
      .then((preloadApi) => {
        setPreload(preloadApi.data);

        api
          .get("roster/getbydate/" + date, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            const SearchTerm = search.toLowerCase();
            if (!data.data) {
              return;
            }
            setAllRosters(data.data);

            const filter = data.data.filter((x) => {
              if (x.staging == null) {
                x.staging = "";
              }

              if (x.notes == null) {
                x.notes = "";
              }

              if (x.route == null) {
                x.route = "";
              }

              return x.notes.toLowerCase().includes(SearchTerm) ||
                x.route.toLowerCase().includes(SearchTerm) ||
                x.staging.toLowerCase().includes(SearchTerm) ||
                (preloadApi.data?.driver?.filter(y => y.id == x.driverid)[0]?.fname.toLowerCase() + ' ' + preloadApi.data?.driver?.filter(y => y.id == x.driverid)[0]?.lname.toLowerCase()).includes(SearchTerm) ||
                preloadApi.data?.vehicle?.filter(y => y.id == x.vehicleid)[0]?.name.toLowerCase().includes(SearchTerm) ||
                preloadApi.data?.device?.filter(y => y.id == x.deviceid)[0]?.name.toLowerCase().includes(SearchTerm)
            });

            let new_roster = filter;
            new_roster.map(x => x.active = false);
            new_roster.map(x => x.route = x.route == null ? "" : x.route);
            new_roster.map(x => x.staging = x.staging == null ? "" : x.staging);
            new_roster.map(x => x.space = x.space == null ? "" : x.space);

            setRoster(new_roster);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });

    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const currentLang = localStorage.getItem("language");
        const lang = data.data.find((x) => x.code === currentLang)?.timemask;
        setTimeMask(lang);
      });

  }

  // type (asc or desc)
  function ReordeerDrivers(field, type) {
    let data = rosters;
    data.sort((a, b) => {
      const asc = type === "asc";
      const driverA = preload.driver.find((x) => x.id == a[field]);
      const driverAName = driverA?.fname + " " + driverA?.lname;

      const driverB = preload.driver.find((x) => x.id == b[field]);
      const driverBName = driverB?.fname + " " + driverB?.lname;

      if (driverAName.toLowerCase() < driverBName.toLowerCase()) {
        return asc ? -1 : 1;
      } else if (driverAName.toLowerCase() > driverBName.toLowerCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });

    setRoster(data);
    setDriversOrder(type);

    setTypeOrder(type);
    setOrder(field);

  }

  // type (asc or desc)
  function ReordeerVehicle(field, type) {
    let data = rosters;
    data.sort((a, b) => {
      const asc = type === "asc";

      const driverA = preload.driver.find((x) => x.id == a['driverid']);
      const driverAName = driverA?.fname + " " + driverA?.lname;
      const driverB = preload.driver.find((x) => x.id == b['driverid']);
      const driverBName = driverB?.fname + " " + driverB?.lname;

      const vehicleA = preload.vehicle.find((x) => x.id == a[field]);
      let vehicleAName = vehicleA?.name;

      if (vehicleAName === undefined) vehicleAName = "";

      const vehicleB = preload.vehicle.find((x) => x.id == b[field]);
      let vehicleBName = vehicleB?.name;
      if (vehicleBName === undefined) vehicleBName = "";

      const fieldA = vehicleAName + " - " + driverAName;
      const fieldB = vehicleBName + " - " + driverBName;

      if (fieldA.toLowerCase() < fieldB.toLowerCase()) {
        return asc ? -1 : 1;
      } else if (fieldA.toLowerCase() > fieldB.toLowerCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });

    setRoster(data);
    setVehiclesOrder(type);

    setTypeOrder(type);
    setOrder(field);
  }
  /*
    function ReordeerDevice(field, type) {
      let data = rosters;
      data.sort((a, b) => {
        const asc = type === "asc";
        const deviceA = preload.device.find((x) => x.id == a[field]);
        let deviceAName = deviceA?.name;
  
        if (deviceAName === undefined) deviceAName = "";
  
        const deviceB = preload.device.find((x) => x.id == b[field]);
        let deviceBName = deviceB?.name;
        if (deviceBName === undefined) deviceBName = "";
  
        if (deviceAName.toLowerCase() < deviceBName.toLowerCase()) {
          return asc ? -1 : 1;
        } else if (deviceAName.toLowerCase() > deviceBName.toLowerCase()) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      });
  
      setRoster(data);
      setDeviceOrder(type);
    }
  
  
    function ReordeerStatus(field, type) {
      let data = rosters;
      data.sort((a, b) => {
        const asc = type === "asc";
        const statusA = preload.status.find((x) => x.id == a[field]);
        let statusAName = statusA?.name;
  
        if (statusAName === undefined) statusAName = "";
  
        const statusB = preload.status.find((x) => x.id == b[field]);
        let statusBName = statusB?.name;
        if (statusBName === undefined) statusBName = "";
  
        if (statusAName.toLowerCase() < statusBName.toLowerCase()) {
          return asc ? -1 : 1;
        } else if (statusAName.toLowerCase() > statusBName.toLowerCase()) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      });
      setRoster(data);
      setStatusOrder(type);
    }
    */
  function Reordeer(field, type, setFunction) {
    let data = [...rosters];

    data.sort((a, b) => {

      const driverA = preload.driver.find((x) => x.id == a['driverid']);
      const driverAName = driverA?.fname + " " + driverA?.lname;
      const driverB = preload.driver.find((x) => x.id == b['driverid']);
      const driverBName = driverB?.fname + " " + driverB?.lname;

      const asc = type === "asc";
      const weekA = a[field] + " - " + driverAName;
      const weekB = b[field] + " - " + driverBName;
      if (weekA.toLowerCase() < weekB.toLowerCase()) {
        return asc ? -1 : 1;
      } else if (weekA.toLowerCase() > weekB.toLowerCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    /*
        data.sort((a, b) => {
          const asc = type === "asc";
    
          if (a[field] < b[field]) {
            return asc ? -1 : 1;
          } else if (a[field] > b[field]) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        });
    */
    setRoster(data);
    setFunction(type);

    setTypeOrder(type);
    setOrder(field);
  }

  function setRosterById(id, newRosterLine) {
    let newRosters = rosters;

    newRosters.map((roster) => {
      if (roster.id == id) {
        return newRosterLine;
      }

      return roster;
    });

    setRoster(newRosters);
    setUpdate(!update);
  }

  function deleteRosterById(id) {
    let newRosters = rosters;
    newRosters = newRosters.filter(roster => roster.id != id);

    var field = order;
    var type = typeOrder;

    if (order !== "") {
      if (order == 'time' || order == 'route') {
        newRosters.sort((a, b) => {
          const asc = type === "asc";
          const driverA = preload.driver.find((x) => x.id == a['driverid']);
          const driverAName = driverA?.fname + " " + driverA?.lname;

          const driverB = preload.driver.find((x) => x.id == b['driverid']);
          const driverBName = driverB?.fname + " " + driverB?.lname;

          if (driverAName.toLowerCase() < driverBName.toLowerCase()) {
            return asc ? -1 : 1;
          } else if (driverAName.toLowerCase() > driverBName.toLowerCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        })
      }
      if (order == 'driverid') {
        newRosters.sort((a, b) => {
          const asc = type === "asc";
          const driverA = preload.driver.find((x) => x.id == a['driverid']);
          const driverAName = driverA?.fname + " " + driverA?.lname;
          const driverB = preload.driver.find((x) => x.id == b['driverid']);
          const driverBName = driverB?.fname + " " + driverB?.lname;


          if (driverAName.toLowerCase() < driverBName.toLowerCase()) {
            return asc ? -1 : 1;
          } else if (driverAName.toLowerCase() > driverBName.toLowerCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        });
      }
      if (order == 'vehicleid') {
        newRosters.sort((a, b) => {

          const asc = type === "asc";

          const driverA = preload.driver.find((x) => x.id == a['driverid']);
          const driverAName = driverA?.fname + " " + driverA?.lname;
          const driverB = preload.driver.find((x) => x.id == b['driverid']);
          const driverBName = driverB?.fname + " " + driverB?.lname;


          const vehicleA = preload.vehicle.find((x) => x.id == a[field]);
          let vehicleAName = vehicleA?.name;
          if (vehicleAName === undefined) vehicleAName = "";
          const vehicleB = preload.vehicle.find((x) => x.id == b[field]);
          let vehicleBName = vehicleB?.name;
          if (vehicleBName === undefined) vehicleBName = "";

          const fieldA = vehicleAName + " - " + driverAName;
          const fieldB = vehicleBName + " - " + driverBName;

          if (fieldA.toLowerCase() < fieldB.toLowerCase()) {
            return asc ? -1 : 1;
          } else if (fieldA.toLowerCase() > fieldB.toLowerCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        });
      }
    }
    setRoster(newRosters);
    setUpdate(!update);

  }

  function sendToTime(time) {
    let ids = [];
    rosters.forEach((x) => {
      if (x.time == time) {
        ids.push(x.id);
      }
    });
    let obj = {
      rosterids: ids,
      date: date,
    };
    api
      .post("message/send", obj, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.data.message,
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.message,
        });
      });
  }

  function sendToSelected() {
    let ids = [];
    rosters.forEach((x) => {
      if (x.active) {
        ids.push(x.id);
      }
    });
    let obj = {
      rosterids: ids,
      date: date,
    };
    api
      .post("message/send", obj, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.data.message,
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.message,
        });
      });
  }

  function sendToAll() {
    let ids = [];
    rosters.forEach((x) => {
      ids.push(x.id);
    });

    let obj = {
      rosterids: ids,
      date: date,
    };

    api
      .post("message/send", obj, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.data.message,
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.message,
        });
      });
  }

  function DeleteAll() {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete all selected items?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        if (rosters.filter(x => x.active === true).length == 0) {
          Swal.fire({
            icon: "warning",
            title: "Ops",
            text: "Select a Roster",
          })

          return;
        }

        setLoading(true);

        rosters.filter(x => x.active === true).forEach(roster => {
          api
            .delete("roster/delete/" + roster.id, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {

            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response?.data?.message,
              });
            });


        })

        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Rosters deleted",
          })
          setLoading(false);
          UpdateList();
        }, 5000)
      }
    });
  }

  function tConvert(time) {

    if (timeMask !== "12H") {
      return time;
    }
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  function openModal(roster, statusid) {
    const result = preload?.status?.filter(x => x.id == statusid).map(x => x.name);
    setRosterId(roster);
    setVisibleModal(true);
    setStatusName(result);
  }


  function closeModal() {
    setRosterId('');
    setVisibleModal(false);
    setIssue(false);
    setCounseling(false);
    setStatusName('');
  }


  function changeIssue() {
    setIssue(!issue)
    setCounseling(false)
  }

  function createIssue() {

    if (rosterId) {
      api
        .post("issue/roster/" + rosterId, {
          "counseling": counseling,
        }, { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          const value = data.data;
          if (value.res) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: value.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value.message,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        })
        .finally(() => {
          closeModal();
        });
    }


  }



  return (
    <div>
      <Loading visible={loading} />

      <Modal
        isOpen={visibleModal} style={customStyles}>
        <div className="row">
          <div className="col-md-12  mt-3">
            <p className="p-0 m-0">{statusName}</p>
          </div>
          <div className="col-md-12  mt-3">

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="issueInput"
                onChange={() =>
                  changeIssue()
                }
              />
              <label
                className="form-check-label"
                htmlFor="issueInput"
              >
                <FormattedMessage id="issue" />
              </label>
            </div>

          </div>

          <div className="col-md-12  mt-3">
            <div className="form-check">
              <input
                disabled={!issue}
                className="form-check-input"
                type="checkbox"
                id="counselingInput"
                checked={issue && counseling == true}
                onChange={() => setCounseling(!counseling)}
              />
              <label
                className="form-check-label"
                htmlFor="counselingInput"
              >
                <FormattedMessage id="counseling" />
              </label>
            </div>
          </div>

        </div>

        <div className="d-flex flex-row-reverse bd-highlight">

          <button
            type="submit"
            className="btn btn-primary btn-confirm float-end"
            onClick={() => {
              createIssue();
            }}
            disabled={!issue}
          >
            <FormattedMessage id="save" />
          </button>
          <button
            className="btn btn-secondary  btn-confirm float-end"
            onClick={() => {
              closeModal();
            }}
          >
            <FormattedMessage id="close" />
          </button>

        </div>
      </Modal>

      <div style={{ textAlign: 'right', marginBottom: '20px', marginRight: '20px' }}>
        {waves.map((data) => {
          return (
            <span style={{ 'marginRight': '10px', 'marginLeft': '10px' }}>
              Wave {tConvert(data.time.split(":")[0] + ':' + data.time.split(":")[1])}: <b>{data.total}</b>
            </span>
          );
        })
        }
        Total Rosters: <b>{rosters.length}</b>
      </div>
      <div className="table-responsive">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" checked={checkAll} onClick={(e) => {
                    setCheckAll(e.target.checked);
                    let rost = [...rosters];
                    rost.map(x => x.active = e.target.checked);
                    setRoster(rost);
                  }} />
                </div>
              </th>
              <th scope="col" style={{ minWidth: "125px" }}>
                <div
                  onClick={() => {
                    Reordeer(
                      "time",
                      timeOrder === "asc" ? "desc" : "asc",
                      setTimeOrder
                    );
                    setLastField('time');
                    setLastOrder(timeOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="tabela.time" />{" "}
                  <FontAwesomeIcon icon={(order != 'time' ? faSort : (order == 'time' && typeOrder == 'asc' ? faSortUp : faSortDown))} />
                </div>{" "}
              </th>
              <th scope="col" style={{ minWidth: "140px" }}>
                <div
                  onClick={() => {
                    ReordeerDrivers(
                      "driverid",
                      driversOrder === "asc" ? "desc" : "asc"
                    );
                    setLastField('driver');
                    setLastOrder(driversOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="tabela.driver" />{" "}
                  <FontAwesomeIcon icon={(order != 'driverid' ? faSort : (order == 'driverid' && typeOrder == 'asc' ? faSortUp : faSortDown))} />
                </div>{" "}
              </th>
              <th scope="col" style={{ minWidth: "150px" }}>
                <div
                  onClick={() => {
                    ReordeerVehicle(
                      "vehicleid",
                      vehiclesOrder === "asc" ? "desc" : "asc"
                    );
                    setLastField('vehicle');
                    setLastOrder(vehiclesOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="tabela.vehicle" />{" "}
                  <FontAwesomeIcon icon={(order != 'vehicleid' ? faSort : (order == 'vehicleid' && typeOrder == 'asc' ? faSortUp : faSortDown))} />
                </div>{" "}
              </th>
              <th scope="col" style={{ minWidth: "110px" }}>
                <div
                  onClick={() => {
                    Reordeer(
                      "route",
                      routeOrder === "asc" ? "desc" : "asc",
                      setRouteOrder
                    );
                    setLastField('route');
                    setLastOrder(routeOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="tabela.route" />{" "}
                  <FontAwesomeIcon icon={(order != 'route' ? faSort : (order == 'route' && typeOrder == 'asc' ? faSortUp : faSortDown))} />
                </div>
              </th>
              <th scope="col" style={{ minWidth: "90px", display: "none" }}>
                <div
                  onClick={() => {
                    Reordeer(
                      "staging",
                      stagingOrder === "asc" ? "desc" : "asc",
                      setStagingOrder
                    );
                    setLastField('staging');
                    setLastOrder(stagingOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="tabela.staging" />{" "}
                  <FontAwesomeIcon icon={(order != 'staging' ? faSort : (order == 'staging' && typeOrder == 'asc' ? faSortUp : faSortDown))} />
                </div>
              </th>
              <th scope="col" style={{ display: "none" }}>
                <FormattedMessage id="tabela.parking" />
              </th>
              <th scope="col" style={{ minWidth: "90px" }}>
                <FormattedMessage id="tabela.status" />
              </th>
              <th scope="col" style={{ minWidth: "110px", width: "110px" }}>
                <button
                  className="btn btn-success btn-sm btn100"
                  onClick={() => UpdateList()}
                  style={{
                    backgroundColor: 'blue',
                    borderColor: 'transparent',
                    width: "25px",
                    display: 'inline',
                    marginRight: '5px',
                    padding: 4,
                  }}
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
                <button
                  className="btn btn-success btn-sm btn100"
                  style={{
                    backgroundColor: "#198754",
                    width: "25px",
                    display: 'inline',
                    padding: 4,
                  }}
                  id="dropdownMenuNot"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuNot">
                  <li>
                    <button class="dropdown-item" onClick={() => sendToAll()}>
                      <FormattedMessage id="notifications.send_to_all" />
                    </button>
                  </li>
                  <li>
                    <button class="dropdown-item" disabled={!rosters.filter(x => x.active).length} onClick={() => sendToSelected()}> <FormattedMessage id="notifications.send_to_selected" />({rosters.filter(x => x.active).length})</button>
                  </li>
                  <hr></hr>
                  {rosters
                    ?.map((item) => item.time)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((data) => {
                      return (
                        <button
                          class="dropdown-item"
                          onClick={() => sendToTime(data)}
                        >
                          <FormattedMessage id="notifications.send_to" />
                          {data.substring(0, data.length - 3)} <FormattedMessage id="notifications.drivers_now" />
                        </button>
                      );
                    })}
                </ul>
                <button
                  className="btn btn-success btn-sm btn100"
                  disabled={rosters.filter(x => x.active === true).length == 0}
                  onClick={DeleteAll}
                  style={{
                    backgroundColor: 'red',
                    borderColor: 'transparent',
                    width: "25px",
                    display: 'inline',
                    marginLeft: '5px',
                    padding: 4,
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {rosters.map((roster) => {
              return (
                <ListItem
                  roster={roster}
                  rosters={allRosters}
                  preload={preload}
                  timeMask={timeMask}
                  update={update}
                  setRosterById={setRosterById}
                  deleteRosterById={deleteRosterById}
                  date={date}
                  UpdateList={UpdateList}
                  messages={messages}
                  openModal={openModal}

                />
              );
            })}
          </tbody>
        </table>


      </div>
    </div >
  );
}
