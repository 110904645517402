import React, { useEffect, useState, useRef } from "react";
import api, { GetToken } from "../../../api/axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";
import {
  faPaperPlane,
  faAngleLeft,
  faAngleRight,
  faSave,
  faSync
} from "@fortawesome/free-solid-svg-icons";
export default function Pending() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [edit, setEdit] = useState(false);
  const [reinforcement, setReinforcement] = useState("");
  const [opportunities, setOpportunities] = useState("");
  const [driver, setDriver] = useState("");
  const [data, setData] = useState("");
  const [smstext, setSMSText] = useState("");
  const [id, setId] = useState("");
  const [date, setDate] = useState("");

  const {
    handleSubmit,
  } = useForm();

  useEffect(() => {
    UpdateList();
    ClearEdit();
  }, [date]);

  function UpdateList() {
    setLoading(true);
    if (date == "") {
      api
        .get("coaching-message/getbypending", { headers: { "x-access-token": GetToken() } })
        .then((preloadApi) => {
          setMessages(preloadApi.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get("coaching-message/getbypendingdate/" + date, { headers: { "x-access-token": GetToken() } })
        .then((preloadApi) => {
          setMessages(preloadApi.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function DecreaseDate() {
    setDate(moment((date != "" ? date : moment().format("YYYY-MM-DD"))).subtract(1, "days").format("YYYY-MM-DD"));
  }

  function IncreaseDate() {
    setDate(moment((date != "" ? date : moment().format("YYYY-MM-DD"))).add(1, "days").format("YYYY-MM-DD"));
  }


  function handleEditing(id) {
    var result = messages.find(x => x.id === id);
    setReinforcement(result.reinforcement != null ? result.reinforcement : '');
    setOpportunities(result.opportunities != null ? result.opportunities : '');
    setSMSText(result.smstext != null ? result.smstext : '');
    setData(result.tripdate != null ? result.tripdate : '');
    setDriver(result.fname != null ? result.fname + ' ' + result.lname : '');
    setId(id);
    setEdit(true);
  }

  function onSubmit() {
    api
      .post("coaching-message/send", { coachingids: [id] },
        { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        UpdateList();
      }).catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });
    ClearEdit();
  }

  function sendToAll() {
    let keys = [];
    messages.filter(function (item) {
      keys.push(item.id);
    });
    api
      .post("coaching-message/send", { coachingids: keys },
        { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        UpdateList();
      }).catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });
    ClearEdit();
  }


  function Save() {
    api
      .put(
        "coaching-message/update/" + id,
        {
          opportunities: reinforcement,
          reinforcement: opportunities,
          smstext: smstext,
        },
        { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        messages.map((item) => {
          if (item.id === id) {
            item.reinforcement = reinforcement;
            item.opportunities = opportunities;
            item.smstext = smstext;
          }
        });
      }).catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });

    ClearEdit();
  }

  function Cancel() {
    ClearEdit();
  }


  function ClearEdit() {
    setReinforcement('');
    setOpportunities('');
    setSMSText('');
    setId('');
    setData('');
    setDriver('');
    setEdit(false);
  }

  function ListAll() {
    setDate("");
    ClearEdit();
  }


  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-md-4 col-6">
          <p className="title title-mobile">
            <FormattedMessage id="pending_messages" />
          </p>
        </div>
        <div className="col-md-4 col-6  mb-2 text-right">
          <button
            className="btn btn-primary"
            style={{
              borderColor: 'transparent',
              display: 'inline',
              marginLeft: '5px',
              padding: '5px 10px',
              float: 'right'
            }}
            onClick={ListAll}
          >
            <FormattedMessage id="listar_todas" />
          </button>
          <button
            className="btn btn-success  btn100"
            onClick={() => UpdateList()}
            style={{
              backgroundColor: 'blue',
              borderColor: 'transparent',
              width: "35px",
              display: 'inline',
              marginRight: '15px',
              padding: '8.5',
              float: 'right'
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </button>

        </div>

        <div className="col-md-4 col-12  mb-2">
          <div
            onClick={DecreaseDate}
            style={{
              display: "inline",
              paddingTop: 7,
              paddingBottom: 7,
              width: "10%",
              color: "#fff",
              borderRadius: "5px 0 0 5px",
              float: "left",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#808080",
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" />
          </div>

          <input
            type="date"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => setDate(formatDate(e.target.value))}
            value={date}
            className="form-control col"
            style={{
              display: "inline-block",
              width: "80%",
              borderRadius: 0,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          />

          <div
            onClick={IncreaseDate}
            style={{
              display: "inline",
              paddingTop: 7,
              paddingBottom: 7,
              width: "10%",
              color: "#fff",
              borderRadius: "  0 5px 5px 0",
              float: "right",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#808080",
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} size="lg" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="table-responsive ">
            <table className="table table-list btn100">
              <thead style={{'display': 'block'}}>
                <tr>
                  <th scope="col" >
                    <FormattedMessage id="tabela.driver" />
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => sendToAll()}
                      disabled={!messages.filter(x => x.id).length}
                      style={{
                        borderColor: 'transparent',
                        display: 'inline',
                        marginLeft: '5px',
                        padding: '4px 10px',
                        marginTop: '-5px',
                        float: 'right'
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} /><FormattedMessage id="send_to_all" />
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody style={{'display': 'block','max-height': '420px','overflow-y':'auto','overflow-x':'hidden'}}>
                {messages.map((message) => {
                  return (
                    <tr key={message.id} onClick={() => handleEditing(message.id)} className={"hover-onclick " + (edit && message.id == id ? 'active' : '')}  style={{'display': 'block'}}>
                      <td style={{'display': 'block'}}>
                        {message.fname} {message.lname}<br />
                        <small style={{ fontSize: '11px' }}>{message.tripdate}</small>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

        </div>

        <div className="col-sm-8">
          {/* Loading editing message after submit */}
          {edit &&
            <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '25px' }}>
              <form onSubmit={handleSubmit(onSubmit)} className="form-content" style={{ padding: '0' }}>
                <p>
                  <FormattedMessage id="tabela.driver" />: {driver} <br />
                  <FormattedMessage id="tabela.date" />: {data}
                </p>
                <label> <FormattedMessage id="reinforcement" /></label>
                <textarea className="form-control  mt-1"
                  rows="2"
                  style={{ resize: 'none' }}
                  maxlength="2048"
                  onChange={(e) => setReinforcement(e.target.value)}
                  value={reinforcement}
                />
                <label className="mt-2"><FormattedMessage id="opportunities" /></label>
                <textarea className="form-control mt-1"
                  rows="2"
                  style={{ resize: 'none' }}
                  maxlength="2048"
                  onChange={(e) => setOpportunities(e.target.value)}
                  value={opportunities}
                />
                <label className="mt-2"><FormattedMessage id="sms_text" /></label>
                <textarea className="form-control mt-1"
                  rows="2"
                  style={{ resize: 'none' }}
                  maxlength="2048"
                  onChange={(e) => setSMSText(e.target.value)}
                  value={smstext}
                />
                <button
                  className="btn btn-primary btn-confirm"
                  onClick={(e) => Save()}
                >
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />  <FormattedMessage id="save" />
                </button>
                <button
                  className="btn btn-success btn-confirm"
                  style={{
                    borderColor: 'transparent',
                    display: 'inline',
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} /><FormattedMessage id="send" />
                </button>

                <button
                  className="btn btn-secondary btn-confirm "
                  onClick={(e) => Cancel()}
                >
                  <FormattedMessage id="cancel" />
                </button>
              </form>
            </div>
          }

        </div>

      </div>
    </div >
  );
}


function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;

  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  console.log("formated date", [year, month, day].join("-"));
  return [year, month, day].join("-");
}
