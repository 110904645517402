import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faFileImport, faCog } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import jwt_decode from "jwt-decode";
import api, { GetToken, SaveToken } from "../api/axios";
import { FormattedMessage } from "react-intl";

export default function User() {
  const history = useHistory();
  const location = useLocation()
  const [userName, setUserName] = useState("");
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState([]);

  var pathName = location.pathname;

  useEffect(() => {
    const currentLang = localStorage.getItem('language');

    api.get('param-country/getall', {
      headers: { "x-access-token": GetToken() },
    }).then(data => {
      setCountry(data.data);
    }).catch(_ => {

    });

    if (currentLang === 'null') {

    } else {
      setLanguage(currentLang);
    }

    const token = GetToken();

    if (token == "null") {
      history.push("/");
      return;
    }

    const userInfo = jwt_decode(token);

    if (userInfo.chgpss == 1 && history.location.pathname.toLowerCase() !== '/change-password') {
      history.push("/change-password");
      return;
    }


    var dateExpiring = new Date(userInfo.exp * 1000).toLocaleString().substr(0, 10);
    var dateNow = new Date().toLocaleString().substr(0, 10);

    dateExpiring = dateExpiring.split('/')[2] + '-' + dateExpiring.split('/')[1] + '-' + dateExpiring.split('/')[0];
    dateNow = dateNow.split('/')[2] + '-' + dateNow.split('/')[1] + '-' + dateNow.split('/')[0];

    //console.log('login expiring: ', new Date(userInfo.exp * 1000).toLocaleString(), ', date now: ', new Date().toLocaleString())
    //Verify if token expired
    var dateUserInfo = new Date(userInfo.exp * 1000);
    var dateActual = new Date(Date.now());

    if (dateActual.getTime() < dateUserInfo.getTime()) {
      if (dateNow == dateExpiring) {
        api
          .post("login/refresh", {}, { headers: { "x-access-token": GetToken() } })
          .then((data) => {
            localStorage.setItem("token", data.data.token);
          })
          .catch((err) => {
            console.log('login expired, redirecting to login');
            localStorage.setItem("token", null);
            history.push("/");
          });
        return;
      }
    } else {
      console.log('login expired, redirecting to login');
      localStorage.setItem("token", null);
      history.push("/");
      return;
    }

    let driverMenus = ["dashboard"];
    //!history.location.pathname.toLowerCase().includes('gates') &&
    //!history.location.pathname.toLowerCase().includes('scorecard')
    if (userInfo.role === 1) {
      if (history.location.pathname.toLowerCase() !== '/dashboard' &&
        history.location.pathname.toLowerCase() !== '/gates' &&
        history.location.pathname.toLowerCase() !== '/counseling/list' &&
        history.location.pathname.toLowerCase() !== '/change-password' &&
        history.location.pathname.toLowerCase() !== '/scorecard') {
        history.push("/dashboard");
      }
    }

    setUserName(userInfo.fname);
  }, [location]);

  function Logout() {
    localStorage.setItem("token", null);
    history.push("/");
  }
  const token = GetToken();
  const userInfo = jwt_decode(token);

  return (
    <ul className="navbar-nav  ms-auto flex-nowrap">
      <li className="nav-item dropdown">
        <button
          className="button-link nav-link dropdown-toggle"
          id="navbarDropdownLocale"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {country.length !== 0 && <span style={{ marginRight: 10 }}>{<img src={process.env.PUBLIC_URL + '/' + country?.find((x) => x.code === language)?.code + '.png'} alt='flag icon' />}</span>}
          {country.length === 0 && <span>loading</span>}
        </button>
        <ul className="dropdown-menu" style={{ minWidth: 0 }} aria-labelledby="navbarDropdownLocale">
          {country?.map(language => {
            return (
              <li key={language.code}>
                <button
                  className="button-link dropdown-item"
                  onClick={() => {
                    localStorage.setItem("language", language.code);
                    document.location.reload();
                  }}
                >
                  <img src={process.env.PUBLIC_URL + '/' + language.code + '.png'} alt='flag icon' style={{ marginRight: 5, color: 'black' }} />
                  {language.code}
                </button>
              </li>
            )
          })
          }
        </ul>
      </li>
      {userInfo.role === 0 &&
        <>
          <li className="nav-item dropdown">
            <Link
              className="nav-link"
              to="/imports"
              style={
                pathName.includes("/imports")
                  ? { color: "var(--button-primary" }
                  : {}
              }
            >
              <FontAwesomeIcon
                icon={faFileImport}
                style={{ marginRight: 2 }}
              />
              {" "}
              <FormattedMessage id="imports" />
            </Link>

          </li>

          <li className="nav-item dropdown">
            <button
              className="button-link nav-link dropdown-toggle"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={
                pathName.includes("/params") || pathName.includes("/users")
                  ? { color: "var(--button-primary" }
                  : {}
              }

            >
              <FontAwesomeIcon icon={faCog} style={{ marginRight: 2 }} />{" "}
              <FormattedMessage id="settings" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/carrier"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/carrier")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.carrier" />
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/counseling"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/counseling") && pathName.includes("/params")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="counseling" />
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/company"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/company")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.company" />
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/driver-status"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/driver-status")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  DAs Status
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/issue"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/issue") && pathName.includes("/params")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="issue" />
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/ownership"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/ownership")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.ownership" />
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="/params/roster-status"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/roster-status")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.roster_status" />
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="/users"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/users")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.users" />
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="/params/vehicle-status"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/vehicle-status")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.vehicles_status" />
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="/params/vendor"
                  onClick={document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show")}
                  style={
                    pathName.includes("/vendor")
                      ? { color: "var(--button-primary" }
                      : {}
                  }
                >
                  <FormattedMessage id="menu.vendor" />
                </Link>
              </li>
            </ul>
          </li>
        </>
      }
      <li className="nav-item dropdown">
        <button
          className="button-link nav-link dropdown-toggle"
          id="navbarDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faUser} style={{ marginRight: 2 }} />{" "}
          <span style={{ marginRight: 10 }}>{userName}</span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          {userInfo.role === 0 &&
            <li>
              <Link
                className="dropdown-item"
                to="/company-settings"
                style={
                  pathName.includes("/company-settings")
                    ? { color: "var(--button-primary" }
                    : {}
                }
              >
                <FormattedMessage id="company_settings" />
              </Link>
            </li>
          }
          <li>
            <Link
              className="dropdown-item"
              to="/change-password"
              style={
                pathName.includes("/change-password")
                  ? { color: "var(--button-primary" }
                  : {}
              }
            >
              <FormattedMessage id='change-password' />
            </Link>
          </li>

          <li>
            <a href="javascript:void(0);" className="dropdown-item" onClick={Logout}>
              <FormattedMessage id='logout' />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
}
