import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import jwt_decode from "jwt-decode";
import List from "./list";
import AddEdit from "./addEdit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

export default function Gates() {
  const history = useHistory();
  const [role, setRole] = useState();

  const [search, setSearch] = useState("");
  var pathName = useLocation().pathname;

  function CreateGate() {
    history.push("/gates/add");
  }

  useEffect(() => {
    document.title = "LoadOut - Gates";

    const token = GetToken();
    if (token === "null") {
      history.push("/");
      return;
    }
    const userInfo = jwt_decode(token);
    setRole(userInfo.role);

  });

  if (role == 1) {

    return (
      <Switch>
        <div className="container mt-3">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <p className="title">
                <FormattedMessage id="menu.gates" />
              </p>
            </div>

            {(!pathName.includes('add') && !pathName.includes('edit')) && <div className="col-md-6 row">
              <div className="col-6 col-md-12">
                <FormattedMessage id="form.search">
                  {(placeholder) => {
                    return (
                      <input
                        className="form-control"
                        placeholder={placeholder}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    );
                  }}
                </FormattedMessage>
              </div>
            </div>}

          </div>
          <Route
            exact
            path="/gates"
            component={(props) => <List searchTerm={search} />}
          />
          <Route path="/gates/add" component={AddEdit} />
          <Route path="/gates/edit/:id" component={AddEdit} />
        </div>
      </Switch>
    );
  }


  return (
    <Switch>
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <p className="title">
              <FormattedMessage id="menu.gates" />
            </p>
          </div>

          {(!pathName.includes('add') && !pathName.includes('edit')) && <div className="col-md-6 row">
            <div className="col-6 col-md-8">
              <FormattedMessage id="form.search">
                {(placeholder) => {
                  return (
                    <input
                      className="form-control"
                      placeholder={placeholder}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  );
                }}
              </FormattedMessage>
            </div>
            <div className="col-6 col-md-4">
              <button className="btn btn-orange btn100" onClick={CreateGate}>
                <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                <FormattedMessage id="form.create" />
              </button>
            </div>
          </div>}

        </div>
        <Route
          exact
          path="/gates"
          component={(props) => <List searchTerm={search} />}
        />
        <Route path="/gates/add" component={AddEdit} />
        <Route path="/gates/edit/:id" component={AddEdit} />
      </div>
    </Switch>
  );

}
