import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";

import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddEdit() {
  const history = useHistory();
  let { id } = useParams();
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState([]);
  const [phoneMask, setPhoneMask] = useState("");
  const [image, setImage] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function getNotify(app, sms, email) {
    const notify =
      "[" +
      app +
      needsComma("", app, sms) +
      sms +
      needsComma(app, sms, email) +
      email +
      "]";

    return notify === "[]" ? null : notify;
  }

  function needsComma(prev, value, next) {
    if (prev === "" && value === "" && next === "") return "";
    if (value === "" && prev !== "" && next !== "") return ",";
    if (value !== "" && next === "") return "";
    if (value === "") return "";
    return ",";
  }

  function onSubmit(data) {
    let phoneReplace = '';

    if (phone != undefined && phone != null) {
      phoneReplace = phone
        .replace("+", "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");
    }

    if (isNaN(phoneReplace)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid phone",
      });
      return;
    }

    const appNot = data.notificationApp ? "0" : "";
    const smsNot = data.notificationSMS ? "1" : "";
    const emailNot = data.notificationEmail ? "2" : "";
    const notify = getNotify(appNot, smsNot, emailNot);

    if (id) {
      //edit
      api
        .put(
          "user/update/" + id,
          {
            fname: data.fName,
            lname: data.lName,
            email: data.email,
            phone: phoneReplace,
            status: data.status,
            role: data.role,
            notify: notify,
            countryid: data.countryid,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then(() => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          history.push("/users");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "user/create",
          {
            fname: data.fName,
            lname: data.lName,
            email: data.email,
            phone: phoneReplace,
            status: data.status,
            notify: notify,
            role: data.role,
            countryid: data.countryid,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then(() => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          // }).then(() => {
          history.push("/users");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  }

  useEffect(() => {
    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        setCountry(data.data);
        const currentLang = localStorage.getItem("language");
        const lang = data.data.find((x) => x.code === currentLang)?.phonemask;
        setPhoneMask(lang);

        if (id) {
          api
            .get("user/getbyid/" + id, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              const value = data.data[0];
              setValue("fName", value.fname);
              setValue("lName", value.lname);
              setImage(value.image);
              setPhone(value.phone);
              setValue("email", value.email);
              setValue("status", value.status);
              setValue("role", value.role);
              setValue("notificationApp", value.notify.includes("0"));
              setValue("notificationSMS", value.notify.includes("1"));
              setValue("notificationEmail", value.notify.includes("2"));
              setValue("countryid", value.countryid);
            })
            .catch((err) => {
              console.log(err.response);
            });
        } else {

        }
      });


  }, [id, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-content">
      {image ? (
        <div className="row">
          <div className="col-md-2 mb-3">
            <img src={image} class="img-thumbnail" />
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-md-6">
          <FormattedMessage id="form.first_name" />
          <input
            {...register("fName", { required: true, min: 1 })}
            className="form-control"
          />
          {errors.fName && (
            <span className="required">
              <FormattedMessage id="form.required" />
            </span>
          )}
        </div>

        <div className="col-md-6">
          <FormattedMessage id="form.last_name" />
          <input
            {...register("lName", { required: true, min: 1 })}
            className="form-control"
          />
          {errors.lName && (
            <span className="required">
              <FormattedMessage id="form.required" />
            </span>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <FormattedMessage id="form.phone" />
          <InputMask
            mask={phoneMask}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            value={phone}
            className="form-control"
          />
        </div>

        <div className="col-md-6">
          E-mail
          <input
            {...register("email", {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            })}
            className="form-control"
          />
          {errors.email && (
            <span className="required">
              <FormattedMessage id="form.validemail" />
            </span>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.country" />
          <select {...register("countryid", { required: true })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {country.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-3 mt-3">
          Status
          <select {...register("status", { required: true })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            <FormattedMessage id="form.active">
              {(message) => <option value="1">{message}</option>}
            </FormattedMessage>

            <FormattedMessage id="form.inactive">
              {(message) => <option value="0">{message}</option>}
            </FormattedMessage>
          </select>
        </div>

        <div className="col-md-2 mt-3">
          <FormattedMessage id="form.role" />

          <select {...register("role", { required: true })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            <FormattedMessage id="form.admin">
              {(message) => <option value="0">{message}</option>}
            </FormattedMessage>

            <FormattedMessage id="form.driver">
              {(message) => <option value="1">{message}</option>}
            </FormattedMessage>
          </select>
        </div>

        <div className="col-md-4 mt-3">
          <FormattedMessage id="form.notification" />
          <div className="row">
            <div className="col-md-4">
              <div className="form-check">
                <input
                  {...register("notificationApp")}
                  className="form-check-input"
                  type="checkbox"
                  id="notificationAppInput"
                />
                <label
                  className="form-check-label"
                  htmlFor="notificationAppInput"
                >
                  APP
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  {...register("notificationSMS")}
                  className="form-check-input"
                  type="checkbox"
                  id="notificationSmsInput"
                />
                <label
                  className="form-check-label"
                  htmlFor="notificationSmsInput"
                >
                  SMS
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  {...register("notificationEmail")}
                  className="form-check-input"
                  type="checkbox"
                  id="notificationEmailInput"
                />
                <label
                  className="form-check-label"
                  htmlFor="notificationEmailInput"
                >
                  E-MAIL
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row-reverse bd-highlight">
        <FormattedMessage id="edit_save">
          {(edit_save) => {
            return (
              <button type="submit"
                className="btn btn-orange btn-confirm float-end"
              >
                <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                {id ? edit_save.edit : edit_save.save}
              </button>
            );
          }}
        </FormattedMessage>
        <button
          className="btn btn-secondary btn-confirm"
          onClick={() => history.push("/users")}
        >
          <FormattedMessage id="form.cancel" />
        </button>
      </div>
    </form>
  );
}
