import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";

export default function AddEditScore({ mode = "score", setModal = null, scoreId, setUpdateList }) {
  const history = useHistory();
  const [week, setWeek] = useState(0);
  const [year, setYear] = useState(0);
  const [driverId, setDriverId] = useState(0);
  const [driver, setDriver] = useState([]);
  const isEdit = scoreId != null;
  let id = scoreId;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    if (id) {
      //edit
      api
        .put(
          "score-user/update/" + id,
          {
            year: data.year,
            week: data.week,
            overall: data.overall,
            delivered: data.delivered,
            focus: data.focus,
            fico: data.fico,
            seatbelt: data.seatbelt,
            speeding: data.speeding,
            distract: data.distract,
            distance: data.distance,
            violations: data.violations,
            dcr: data.dcr,
            dar: data.dar,
            swcpod: data.swcpod,
            swccc: data.swccc,
            swcsc: data.swcsc,
            swcad: data.swcad,
            dnrs: data.dnrs,
            pod: data.pod,
            cc: data.cc,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          if (mode === "score") {
            history.push("/drivers/scores/" + driverId);
            setModal(false);
            setUpdateList();
          }
          else {
            console.log(setModal)
            setModal(false);
          }
          //});
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response?.data.message,
          });
        });
    }
  }

  useEffect(() => {

    if (id) {
      api
        .get("score-user/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          console.log(data.data);
          const value = data.data[0];
          setDriverId(value.userid);
          setYear(value.year);
          setWeek(value.week);
          setValue("year", value.year);
          setValue("week", value.week);
          setValue("overall", value.overall);
          setValue("delivered", value.delivered);
          setValue("focus", value.focus);
          setValue("fico", value.fico);
          setValue("seatbelt", value.seatbelt);
          setValue("speeding", value.speeding);
          setValue("distract", value.distract);
          setValue("distance", value.distance);
          setValue("violations", value.violations);
          setValue("dcr", value.dcr);
          setValue("dar", value.dar);
          setValue("swcpod", value.swcpod);
          setValue("swccc", value.swccc);
          setValue("swcsc", value.swcsc);
          setValue("swcad", value.swcad);
          setValue("dnrs", value.dnrs);
          setValue("pod", value.pod);
          setValue("cc", value.cc);

          api
            .get("driver/getbyid/" + value.userid, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              setDriver(data.data[0]);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response?.data.message,
              });
            });

        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response?.data.message,
          });
        });



    } else {

    }
  }, [id]);


  return (
    <>
      <div className="row ps-4">
        <div className="col-12 col-md-12">
          <h2 className="float-start me-4">Driver: {driver.fname} {driver.lname}</h2>
          <small><FormattedMessage id="menu.scorecard" /></small><br />
          <b><FormattedMessage id="week" /> {week} - {year}</b>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-content">
        <div className="row">
          <div className="col-md-3 mt-3">
            <FormattedMessage id="form.year" />
            <input
              type="number"
              min="1900" max="2099" step="1"
              {...register("year", { required: true, min: 4 })}
              className="form-control"
            />
          </div>
          <div className="col-md-3 mt-3">
            <FormattedMessage id="week" />
            <input
              type="number"
              min="1" max="53" step="1"
              {...register("week", { required: true, min: 1 })}
              className="form-control"
            />
          </div>
          <div className="col-md-3 mt-3">
            <FormattedMessage id="overall" />
            <input
              type="number"
              step="1"
              {...register("overall")}
              className="form-control"
            />
          </div>
          <div className="col-md-3 mt-3">
            <FormattedMessage id="delivered" />
            <input
              type="number"
              step="1"
              {...register("delivered")}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mt-3">
            <FormattedMessage id="focus" />
            <input
              {...register("focus")}
              className="form-control"
            />
          </div>
          <div className="col-md-3 mt-3">
            Fico
            <input
              type="number" step="1"
              {...register("fico")}
              className="form-control"
            />
          </div>

          <div className="col-md-3 mt-3">
            <FormattedMessage id="seatbelt" />

            <input
              type="number" step="0.01"
              {...register("seatbelt",)}
              className="form-control"
            />
          </div>
          <div className="col-md-3 mt-3">
            <FormattedMessage id="speeding" />
            <input
              type="number" step="0.01"
              {...register("speeding")}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-3">
            <FormattedMessage id="distract" />
            <input
              type="number" step="0.01"
              {...register("distract")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            <FormattedMessage id="distance" />
            <input
              type="number" step="0.01"

              {...register("distance")}
              className="form-control"
            />
          </div>

          <div className="col-md-2 mt-3">
            <FormattedMessage id="violations" />
            <input
              type="number" step="0.01"
              {...register("violations")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            DRC
            <input
              type="number" step="0.01"
              {...register("dcr")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            POD
            <input
              type="number"
              {...register("pod")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            CC
            <input
              type="number"
              {...register("cc")}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 mt-3">
            DAR
            <input
              type="number"
              {...register("dar")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            SWC-POD
            <input
              type="number" step="0.01"
              {...register("swcpod")}
              className="form-control"
            />
          </div>

          <div className="col-md-2 mt-3">
            SWC-CC
            <input
              type="number" step="0.01"
              {...register("swccc")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            SWC-SC
            <input
              type="number" step="0.01"
              {...register("swcsc")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            SWC-AD
            <input
              type="number"
              {...register("swcad")}
              className="form-control"
            />
          </div>
          <div className="col-md-2 mt-3">
            DNRS
            <input
              type="number"
              {...register("dnrs")}
              className="form-control"
            />
          </div>
        </div>
        <div className="row bd-highlight">
          <div className="col-12 col-md-12">
            <FormattedMessage id="edit_save">
              {(edit_save) => {
                return (
                  <input
                    type="submit"
                    className="btn btn-orange btn-confirm float-end"
                    value={scoreId ? edit_save.edit : edit_save.save}
                  />
                );
              }}
            </FormattedMessage>
            <button
              className="btn btn-secondary btn-confirm float-end"
              onClick={() => {
                setModal(false);
              }}
            >
              <FormattedMessage id="cancel" />
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
