import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import jwt_decode from "jwt-decode";
import Loading from "../../components/Loading";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-modal';
import ViewScore from '../scorecard/viewScore';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  faPaperPlane, faSort, faTrashAlt,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faEdit,
  faEye,
  faTachometerAlt
} from "@fortawesome/free-solid-svg-icons";
import { id } from "date-fns/locale";
import ReactPaginate from "react-paginate";


export default function List({ setLastField, setLastOrder }) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [scorecards, setScoreCard] = useState([]);
  const [timeMask, setTimeMask] = useState();
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [update, setUpdate] = useState(false);
  const [overallOrder, setOverallOrder] = useState();
  const [safetyOrder, setSafetyOrder] = useState();
  const [reliabilityOrder, setReliabilityOrder] = useState();
  const [teamOrder, setTeamOrder] = useState();
  const [qualityOrder, setQualityOrder] = useState();
  const [weekOrder, setWeekOrder] = useState();
  const [role, setRole] = useState();
  const [scores, setScores] = useState([]);
  const [visibleScoreModal, setVisibleScoreModal] = useState(false);
  const [scoreId, setScoreId] = useState(0);
  const [selected, setSelected] = useState(0);
  const [counts, setCounts] = useState([]);
  const [year, setYear] = useState(new Date());
 

  function handleVisibleModal(value) {
    setVisibleScoreModal(value);
  }

  function showScore(id) {
    setScoreId(id);
    setVisibleScoreModal(true);
  }


  useEffect(() => {
    UpdateList();
  }, [offset]);

  function onYearChange(e) {
    setYear(e);
  }



  function UpdateList() {

    setLoading(true);

    api
      .get("scorecard/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const slice = data.data.slice(offset, offset + perPage);
        setScoreCard(slice);
        setPageCount(Math.ceil(data.data.length / perPage));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const currentLang = localStorage.getItem("language");
        const lang = data.data.find((x) => x.code === currentLang)?.timemask;
        setTimeMask(lang);
      });

    const token = GetToken();
    if (token === "null") {
      history.push("/");
      return;
    }
    const userInfo = jwt_decode(token);
    setRole(userInfo.role);

    if (userInfo.role == 1) {
      api
        .get("score-user/getbyuserid/" + userInfo.id, {
          headers: { "x-access-token": GetToken() }
        })
        .then((data) => {
          const slice = data.data.slice(offset, offset + perPage);
          setScores(slice);
          setPageCount(Math.ceil(data.data.length / perPage));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

    }
  }


  useEffect(() => {

    if (year.getFullYear() == '') {
      api
        .get("scorecard/getbyyear", { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          setCounts(data.data[0]);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        }).finally(_ => {
          setLoading(false);
        });

    }
    else {
      api
        .get("scorecard/getbyyear/" + year.getFullYear(), { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          setCounts(data.data[0]);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        }).finally(_ => {
          setLoading(false);
        });
    }
  }, [year]);


  function printNameScore(score) {
    let result = '';
    switch (score) {
      case 1:
        result = <FormattedMessage id="poor" />;
        break;
      case 2:
        result = <FormattedMessage id="fair" />;
        break;
      case 3:
        result = <FormattedMessage id="great" />;
        break;
      case 4:
        result = <FormattedMessage id="fantastic" />;
        break;
      case 5:
        result = <FormattedMessage id="fantastic_plus" />;
        break;
      default:
        result = '';
    }
    return result;
  }

  // type (asc or desc)
  function ReorderWeek(field, type) {
    let data = scorecards;
    data.sort((a, b) => {
      const asc = type === "asc";
      const weekA = a['year'] + " - " + a['week'];
      const weekB = b['year'] + " - " + b['week'];
      if (weekA.toLowerCase() < weekB.toLowerCase()) {
        return asc ? -1 : 1;
      } else if (weekA.toLowerCase() > weekB.toLowerCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    setScoreCard(data);
    setWeekOrder(type);
  }
  // type (asc or desc)
  function Reorder(field, type, setFunction) {
    let data = [...scorecards];
    data.sort((a, b) => {
      const asc = type === "asc";

      if (a[field] < b[field]) {
        return asc ? -1 : 1;
      } else if (a[field] > b[field]) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    setScoreCard(data);
    setFunction(type);
  }

  function deleteScoreById(id) {
    let newScorecards = scorecards;
    newScorecards = newScorecards.filter(scorecards => scorecards.id != id);
    setScoreCard(newScorecards);
    setUpdate(!update);
  }

  function DeleteScorecard(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("scorecard/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Scorecard deleted",
            }).then(() => {
              //UpdateList();
              deleteScoreById(id)
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };


  if (role == 1) {

    return (
      <div>

        <Modal isOpen={visibleScoreModal} >
          <ViewScore mode='score' setModal={handleVisibleModal} scoreId={scoreId} />
        </Modal>
        <Loading visible={loading} />
        <div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>
        <div className="table-container">
          <table className="table table-list btn100">
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="week" />
                </th>
                <th scope="col">
                  DAR
                </th>
                <th scope="col">
                  <FormattedMessage id="delivered" />
                </th>
                <th scope="col">
                  Focus
                </th>
                <th scope="col">
                  SWC-POD
                </th>
                <th scope="col">
                  SWC-SC
                </th>
                <th scope="col">
                  DCR
                </th>
                <th scope="col">
                  DNRS
                </th>
                <th scope="col">
                  SWC-CC
                </th>
                <th scope="col">
                  <FormattedMessage id="routes-count" />
                </th>
                <th scope="col">
                  <FormattedMessage id="packages-count" />
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score) => {
                return (
                  <tr>
                    <td>{score.year} - {score.week}</td>
                    <td>{score.dar}</td>
                    <td>{score.delivered}</td>
                    <td>{score.focus}</td>
                    <td>{score.swcpod}</td>
                    <td>{score.swcsc}</td>
                    <td>{score.dcr}</td>
                    <td>{score.dnrs}</td>
                    <td>{score.swccc}</td>
                    <td>{score.routes}</td>
                    <td>{score.packages}</td>
                    <td className='icon-cell'>
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => showScore(score.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>
      </div>
    );

  }
  return (
    <div>
      <div className="row mb-2">
        <div className="col-6 col-md-10">
          <h5 className="mt-4"><FormattedMessage id="scorecard-count" />  {counts?.year} </h5>
        </div>
        <div className="col-6 col-md-2">
          <label><FormattedMessage id="form.year" /></label>
          <DatePicker
            selected={year}
            onChange={(date) => onYearChange(date)}
            className="form-control"
            showYearPicker
            dateFormat="yyyy"
          />
        </div>
      </div>
      <div className="row d-flex justify-content-left ">
        <p className="mb-3">
          <FormattedMessage id="routes-count" />: <b>{counts?.routes}</b><br />
          <FormattedMessage id="packages-count" />: <b>{counts?.packages}</b>
        </p>
      </div>

      <div className="row d-flex justify-content-left mb-4">

        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="fantastic_plus" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['5']}</h5>}
            </div>
          </div>
        </div>

        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="fantastic" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['4']}</h5>}
            </div>
          </div>
        </div>


        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="great" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['3']}</h5>}
            </div>
          </div>
        </div>

        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="fair" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['2']}</h5>}
            </div>
          </div>
        </div>


        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="poor" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['1']}</h5>}
            </div>
          </div>
        </div>

      </div>



      <Loading visible={loading} />
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: "90px" }}>
                <div
                  onClick={() => {
                    ReorderWeek(
                      "week",
                      weekOrder === "asc" ? "desc" : "asc"
                    );
                    setLastField('week');
                    setLastOrder(weekOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="week" /> {" "}
                  <FontAwesomeIcon icon={faSort} />
                </div>{" "}
              </th>
              <th scope="col" style={{ minWidth: "180px" }}>
                <div
                  onClick={() => {
                    Reorder(
                      "overall",
                      overallOrder === "asc" ? "desc" : "asc",
                      setOverallOrder
                    );
                    setLastField('overall');
                    setLastOrder(overallOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="overall_standing" />  {" "}
                  <FontAwesomeIcon icon={faSort} />
                </div>{" "}
              </th>
              <th scope="col" style={{ minWidth: "220px" }}>
                <div
                  onClick={() => {
                    Reorder(
                      "safety",
                      safetyOrder === "asc" ? "desc" : "asc",
                      setSafetyOrder
                    );
                    setLastField('safety');
                    setLastOrder(safetyOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="safety_compliance" /> {" "}
                  <FontAwesomeIcon icon={faSort} />
                </div>{" "}
              </th>
              { /*
              <th scope="col" style={{ minWidth: "120px" }}>
                <div
                  onClick={() => {
                    Reorder(
                      "reliability",
                      reliabilityOrder === "asc" ? "desc" : "asc",
                      setReliabilityOrder
                    );
                    setLastField('reliability');
                    setLastOrder(reliabilityOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="reliability" /> {" "}
                  <FontAwesomeIcon icon={faSort} />
                </div>
              </th>
              */ }
              <th scope="col" style={{ minWidth: "90px" }}>
                <div
                  onClick={() => {
                    Reorder(
                      "team",
                      teamOrder === "asc" ? "desc" : "asc",
                      setTeamOrder
                    );
                    setLastField('team');
                    setLastOrder(teamOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="team" /> {" "}
                  <FontAwesomeIcon icon={faSort} />
                </div>
              </th>
              <th scope="col" style={{ minWidth: "90px" }}>
                <div
                  onClick={() => {
                    Reorder(
                      "quality",
                      qualityOrder === "asc" ? "desc" : "asc",
                      setQualityOrder
                    );
                    setLastField('quality');
                    setLastOrder(qualityOrder === "asc" ? "desc" : "asc");
                  }}
                >
                  <FormattedMessage id="quality" /> {" "}
                  <FontAwesomeIcon icon={faSort} />
                </div>
              </th>
              <th scope="col">
                <FormattedMessage id="routes-count" />
              </th>
              <th scope="col">
                <FormattedMessage id="packages-count" />
              </th>
            </tr>
          </thead>
          <tbody>
            {scorecards.map((scorecard) => {
              return (
                <tr>
                  <td>{scorecard.year} - {scorecard.week}</td>
                  <td>{printNameScore(scorecard.overall)}</td>
                  <td>{printNameScore(scorecard.safety)}</td>
                  { /*
                  <td>{printNameScore(scorecard.reliability)}</td>
                  */ }
                  <td>{printNameScore(scorecard.team)}</td>
                  <td>{printNameScore(scorecard.quality)}</td>
                  <td>{scorecard.routes}</td>
                  <td>{scorecard.packages}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history.push("/scorecard/edit/" + scorecard.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteScorecard(scorecard.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );
}
