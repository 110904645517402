import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

export default function VehicleConfirm({ vehicle, fileNameImport }) {
    const history = useHistory();
    const [uploadData, setUploadData] = useState();
    const [removeData, setRemoveData] = useState();
    const [checkAll, setCheckAll] = useState(true);
    const [loading, setLoading] = useState();

    useEffect(() => {
        if (vehicle === undefined) {
            history.push('/imports');
            return;
        }

        var vehicleData = [];
        vehicle.vehicle.forEach(item => {
            item.active = true;
            vehicleData.push(item);
        });

        setUploadData(vehicleData);
        setRemoveData(vehicle.remove);
    }, []);

    function SaveVehicle() {
        var uploadObject = {
            vehicle: []
        };

        uploadData.forEach(retorno => {
            if (!retorno.active) {
                return;
            }
            delete retorno.active;
            uploadObject.vehicle.push(retorno);
        });

        if (uploadObject.vehicle.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'Select at least one vehicle',
            })
            return;
        }

        setLoading(true);

        api
            .post("/vehicle/save", uploadObject, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.data.message,
                }).then(_ => {
                    history.push('/imports')
                })
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response?.data.message,
                });
            }).finally(_ => {
                setLoading(false);
            })

    }

    function checkAllFunc(e) {
        let uploadDataClone = [...uploadData];
        uploadDataClone.forEach(retorno => {
            retorno.active = e.target.checked;
            return retorno;
        });
        setCheckAll(e.target.checked)
        setUploadData(uploadDataClone);
    }

    function ChangeSelect(e, index) {
        let uploadData_clone = [...uploadData];
        uploadData_clone.map((data, key) => {
            if (key === index) {
                data.active = e.target.checked;
            }
            return data;
        });
        setUploadData(uploadData_clone);
    }

    return (
        <div >
            <Loading visible={loading} />
            <div className="row mb-3">
                <div className="col-md-8">
                    <p className="title mb-0 pb-0"><FormattedMessage id="vehicle_import" /> </p>
                    <p>{fileNameImport}</p>
                </div>
                <div className="col-6 col-md-4  text-end">
                    <button className="btn d-inline btn-orange me-3" style={{ backgroundColor: "red" }} onClick={() => { history.push('/imports') }}>
                        <FormattedMessage id="form.cancel" />
                    </button>

                    <button
                        className="btn d-inline btn-orange"
                        style={{ backgroundColor: "blue" }}
                        onClick={SaveVehicle}
                    >
                        <FormattedMessage id="save" />
                    </button>
                </div>
            </div>

            <div className="table-container">
                <table className="table table-list btn100" style={{ fontSize: 12 }}>
                    <thead>
                        <tr style={{ fontWeight: 600, textAlign: "left" }}>
                            <td>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={checkAll}
                                        onChange={(e) => checkAllFunc(e)}
                                        id="flexCheckIndeterminate"
                                    />
                                </div>
                            </td>
                            <td><FormattedMessage id="tabela.name" /></td>
                            <td><FormattedMessage id="tabela.plate" /></td>
                            <td><FormattedMessage id="tabela.vin" /></td>
                            <td><FormattedMessage id="tabela.gascard" /></td>
                            <td><FormattedMessage id="tabela.mileage" /></td>
                            <td><FormattedMessage id="tabela.parking" /></td>
                            <td><FormattedMessage id="tabela.ownership" /></td>
                            <td><FormattedMessage id="tabela.type" /></td>
                            <td><FormattedMessage id="tabela.make" /></td>
                            <td><FormattedMessage id="tabela.model" /></td>
                            <td><FormattedMessage id="tabela.year" /></td>
                            <td><FormattedMessage id="tabela.company" /></td>
                            <td><FormattedMessage id="tabela.rental" /></td>
                            <td><FormattedMessage id="tabela.status" /></td>
                        </tr>
                    </thead>
                    <tbody>
                        {uploadData?.map((data, key) => {
                            console.log(data);
                            return (
                                <tr style={{ textAlign: "left" }}>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                key={key}
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={data.active}
                                                onChange={(e) => ChangeSelect(e, key)}
                                            />
                                        </div>
                                    </td>
                                    <td>{data.name}</td>
                                    <td>{data.plate}</td>
                                    <td>{data.vin}</td>
                                    <td>{data.gascard}</td>
                                    <td>{data.mileage}</td>
                                    <td>{data.space}</td>
                                    <td>{data.ownership}</td>
                                    <td>{data.type}</td>
                                    <td>{data.make}</td>
                                    <td>{data.model}</td>
                                    <td>{data.year}</td>
                                    <td>{data.company}</td>
                                    <td>{data.rental}</td>
                                    <td>{data.status}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="table-container mb-5">
                <FormattedMessage id="remove" />
                <table className="table table-list btn100" style={{ fontSize: 12 }}>
                    <thead>
                        <tr style={{ fontWeight: 600, textAlign: "left" }}>
                        <td><FormattedMessage id="tabela.name" /></td>
                            <td><FormattedMessage id="tabela.plate" /></td>
                            <td><FormattedMessage id="tabela.vin" /></td>
                            <td><FormattedMessage id="tabela.gascard" /></td>
                            <td><FormattedMessage id="tabela.mileage" /></td>
                            <td><FormattedMessage id="tabela.parking" /></td>
                            <td><FormattedMessage id="tabela.ownership" /></td>
                            <td><FormattedMessage id="tabela.type" /></td>
                            <td><FormattedMessage id="tabela.make" /></td>
                            <td><FormattedMessage id="tabela.model" /></td>
                            <td><FormattedMessage id="tabela.year" /></td>
                            <td><FormattedMessage id="tabela.company" /></td>
                            <td><FormattedMessage id="tabela.rental" /></td>
                            <td><FormattedMessage id="tabela.status" /></td>
                        </tr>
                    </thead>
                    <tbody>
                        {removeData?.map((data, key) => {
                            console.log(data);
                            return (
                                <tr style={{ textAlign: "left" }}>
                                    <td>{data.name}</td>
                                    <td>{data.plate}</td>
                                    <td>{data.vin}</td>
                                    <td>{data.gascard}</td>
                                    <td>{data.mileage}</td>
                                    <td>{data.space}</td>
                                    <td>{data.ownership}</td>
                                    <td>{data.type}</td>
                                    <td>{data.make}</td>
                                    <td>{data.model}</td>
                                    <td>{data.year}</td>
                                    <td>{data.company}</td>
                                    <td>{data.rental}</td>
                                    <td>{data.status}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}