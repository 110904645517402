import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import api, { GetToken } from "../../api/axios";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import AddEditDrivers from '../drivers/addEdit';
//import moment from "moment";

export default function RosterV2Confirm({ rosterv2 }) {
    const history = useHistory();
    const [drivers, setDrivers] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [uploadData, setUploadData] = useState([]);
    const [checkAll, setCheckAll] = useState(true);
    const [uploadObject, setUploadObject] = useState([]);
    const [loading, setLoading] = useState();
    const [uploadErrors, setUploadErrors] = useState([]);
    const [visibleDriverModal, setVisibleDriverModal] = useState(false);
    const [startDriverData, setStartDriverData] = useState({})


    function handleVisibleModal(value) {
        setVisibleDriverModal(value);
        api
            .get("/driver/getall", { headers: { "x-access-token": GetToken() } })
            .then((data) => {
                setDrivers(data.data);
            });
    }

    useEffect(() => {
        if (rosterv2 === undefined) {
            history.push('/imports');
            return;
        }

        api
            .get("/driver/getall", { headers: { "x-access-token": GetToken() } })
            .then((driversReturn) => {
                setDrivers(driversReturn.data);
            });
        
        const formObject = [];
        const count = rosterv2.rosterv2.length;

        rosterv2.rosterv2.forEach((data, index) => {
            const rowData = {
                id: index,
                trasnporterid: data.transporterid,
                importName: data.name,
                driverid: data.userid,
                active: data.triptime !== null ? true : false,
                route: data.route,
                date: data.tripdate,
                time: data.triptime
            }

            formObject.push(rowData);
        })

        rosterv2.notfound.forEach((data, index) => {
            const rowData = {
                id: count + index,
                trasnporterid: data.transporterid,
                importName: data.name,
                driverid: data.userid,
                active: data.triptime !== null ? true : false,
                route: data.route,
                date: data.tripdate,
                time: data.triptime
            }

            formObject.push(rowData);
        })

        /*const sort = formObject.roster.sort((a, b) => {
            return a.importName.toLowerCase() < b.importName.toLowerCase() ? -1 : 1;
        });

        formObject.roster = sort;*/
        setUploadData(formObject);

    }, []);

    function ChangeDriver(e, index) {
        let data = [...uploadData];
        if (e.target.value === 'new_driver') {
            e.target.value = 0
            console.log('new driver', data.roster[index]);
            setStartDriverData({
                transporterID: data.roster[index].trasnporterid,
                fname: data.roster[index].importName.split(' ')[0],
                lname: data.roster[index].importName.split(' ').slice(- (data.roster[index].importName.split(' ').length - 1)).join(' ')
            })
            setVisibleDriverModal(true);
            return;
        }

        data[index].driverid = e.target.value;
        setUploadData(data);

    }

    function ChangeSelect(e, index) {
        let uploadData_clone = [...uploadData];
        uploadData_clone.map((data, key) => {
            if (key === index) {
                data.active = e.target.checked;
            }
            return data;
        });

        setUploadData(uploadData_clone);
    }

    function SaveForce() {
        if (uploadObject === undefined) return;

        let dataUpload = uploadObject;

        dataUpload['force'] = true;

        api
            .post("roster/save", dataUpload, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                //console.log(data)
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.data.message,
                }).then(_ => {
                    history.push('/roster')
                })

            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.message,
                });

            }).finally(_ => {
                setLoading(false);
            })

    }

    function SaveRoster() {
        let upload_object = {
            roster: [],
        };

        let validDrivers = true;

        uploadData.forEach(retorno => {
            if (!retorno.active) {
                return null;
            }

            //console.log('driver id', retorno.driverid);
            if (retorno.driverid === undefined || retorno.driverid === 0 || retorno.driverid === null || retorno.driverid === 'undefined') {
                //console.log('invalid driver');
                //console.log(retorno);
                validDrivers = false;
            }

            let new_item = {
                driverid: retorno.driverid,
                date: retorno.date,
                time: retorno.time,
                route: retorno.route
            };

            upload_object.roster.push(new_item);
        })

        if (!validDrivers) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'Select Driver',
            })

            return;
        }

        if (upload_object.roster.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'No data to save',
            })

            return;
        }

        setLoading(true);
        //console.log('saving roster', upload_object);

        api
            .post("/roster/save", upload_object, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                if (data.data.errno === 1062) {
                    setUploadErrors(data.data.roster);
                    setIsOpen(true);
                }
                else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.data.message,
                    }).then(_ => {
                        history.push('/roster')
                    })
                }
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.data.message,
                });

            }).finally(_ => {
                setLoading(false);
                setUploadObject(upload_object)
            })

    }

    function checkAllFunc(e) {

        let uploadDataClone = uploadData;
        uploadDataClone.map(retorno => {
            if (retorno.time !== null) {
                retorno.active = e.target.checked;
            }
            return retorno;
        });

        setCheckAll(e.target.checked);
        setUploadData(uploadDataClone);
    }

    function dateFormat(date) {
        const sdate = date.split("-");
        const fdate = sdate[1] + "/" + sdate[2] + "/" + sdate[0];
        return fdate;
    }

    function convertFrom24To12Format (time24) {
        const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? ' AM' : ' PM';
        const hours = +sHours % 12 || 12;
      
        return `${hours}:${minutes}${period}`;
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            <Modal isOpen={visibleDriverModal} >
                <h2><FormattedMessage id="tabela.create_driver" /></h2>
                <AddEditDrivers mode='roster' setModal={handleVisibleModal} />
            </Modal>
            <Loading visible={loading} />
            <Modal
                isOpen={modalIsOpen}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <h4 style={{ textAlign: 'center' }}><FormattedMessage id="txt_some_data_conflicts_import" /></h4>
                {/*<table className='table table-list btn100'>
                    <thead>
                    <tr style={{ fontWeight: 600, textAlign: "center" }}>
                        <td><FormattedMessage id="tabela.driver" /></td>
                        <td><FormattedMessage id="tabela.date" /></td>
                    </tr>
                    </thead>
                    <tbody>
                    {uploadErrors.map(data => {
                        return (
                            <tr style={{textAlign: "center"}}>
                                <td>{drivers.find(x => x.id == data?.driverid)?.fname} {drivers.find(x => x.id == data?.driverid)?.lname}</td>
                                <td>{data?.date}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>*/}
                <div style={{ width: '100%', fontSize: 12, color: '#333', textAlign: 'center', margin: 10 }}>
                    <FormattedHTMLMessage id="txt_data_conflicts_import" />
                </div>
                <div className='row'>
                    <div className='col-2'>
                    </div>
                    <div className='col-4'>
                        <button className="btn btn-orange btn100" style={{ backgroundColor: "#FF2626" }} onClick={() => setIsOpen(false)}><FormattedMessage id="not_overwrite" /></button>
                    </div>
                    <div className='col-4'>
                        <button className="btn btn-orange btn100" style={{ backgroundColor: "#059C66" }} onClick={() => SaveForce()}><FormattedMessage id="overwrite" /></button>
                    </div>
                    <div className='col-2'>
                    </div>
                </div>
            </Modal>

            <div className="row mb-3">
                <div className="col-md-9">
                    <p className="title"><FormattedMessage id="rosterv2_import" /></p>
                </div>
                <div className="col-6 col-md-1">
                    <button className="btn btn-orange" style={{ backgroundColor: "red" }} onClick={() => { history.push('/imports') }}>
                        <FormattedMessage id="form.cancel" />
                    </button>
                </div>
                <div className="col-6 col-md-2">
                    <button
                        className="btn btn-orange"
                        style={{ backgroundColor: "blue", width: "100%" }}
                        onClick={SaveRoster}
                    >
                        <FormattedMessage id="save" />
                    </button>
                </div>
            </div>

            <div className="table-container">
                <table className="table table-list btn100" style={{ fontSize: 12 }}>
                    <thead>
                        <tr style={{ fontWeight: 600, textAlign: "center" }}>
                            <td>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={checkAll}
                                        onChange={(e) => checkAllFunc(e)}
                                        id="flexCheckIndeterminate"
                                    />
                                </div>
                            </td>
                            <td><FormattedMessage id="form.transporter_id" /></td>
                            <td><FormattedMessage id="tabela.import_name" /></td>
                            <td><FormattedMessage id="tabela.select_driver" /></td>
                            <td><FormattedMessage id="tabela.route" /></td>
                            <td><FormattedMessage id="tabela.date" /></td>
                            <td><FormattedMessage id="tabela.time" /></td>
                        </tr>
                    </thead>
                    <tbody>
                        {uploadData?.map((data) => {
                            return (
                                <tr style={{ textAlign: "center" }} key={"tr" + data.id}>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                disabled={data.time === null ? true : false}
                                                checked={data.active}
                                                onChange={(e) => ChangeSelect(e, data.id)}
                                            />
                                        </div>
                                    </td>
                                    <td>{data.trasnporterid}</td>
                                    <td style={{ textAlign: "left" }}>{data.importName}</td>
                                    <td>
                                        <select className="form-select form-select-sm" onChange={(e) => ChangeDriver(e, data.id)} style={{ borderColor: data.driverid == 0 && data.active ? 'red' : '' }}>
                                            <FormattedMessage id="tabela.select_driver">
                                                {(text) => {
                                                    return (
                                                        <option value='0'>{text}</option>
                                                    );
                                                }}
                                            </FormattedMessage>
                                            <FormattedMessage id="tabela.create_driver">
                                                {(text) => {
                                                    return (
                                                        <option value='new_driver'>+ {text}</option>
                                                    );
                                                }}
                                            </FormattedMessage>
                                            {drivers.map((driver) => (
                                                <option selected={driver.id === data.driverid} value={driver.id}>{driver.fname} {driver.lname}</option>
                                            ))}

                                        </select>
                                    </td>
                                    <td>
                                        {data.route}
                                    </td>
                                    <td>
                                        {dateFormat(data.date)}
                                    </td>
                                    <td>
                                        {data.time !== null ? convertFrom24To12Format(data.time) : "?"}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
