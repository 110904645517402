import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import api, { GetToken } from "../../../api/axios";
import { FormattedMessage } from "react-intl";

import List from "./list";

export default function State() {
  const [countryId, setCountyID] = useState(0);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        setCountries(data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <Switch>
      <div className="container mt-3">
        <div className='row mb-3'>
          <div className='col-12 col-md-3'>
            <p className="title"><FormattedMessage id="menu.state" /></p>
          </div>
          <div className='col-6 col-md-1'></div>
          <div className='col-6 col-md-3'>
            <select className="form-select" onChange={(e) => { setCountyID(e.target.value); console.log(e.target.value)}}>
              {countries.map(country => <option value={country.id}>{country.name}</option>)}
            </select>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-4'>
          </div>          
        </div>
        <Route exact path="/params/state" render={ () => <List countryId={countryId} />} />
      </div>
    </Switch>
  );
}

