import axios from "axios";

const api = axios.create({
  baseURL: "https://api.dsploadout.com/v1",
  headers: {
    'x-access-token': GetToken(),
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  }
});

function SaveToken(token){
  localStorage.setItem('token', token);
}

function GetToken(){  
  return localStorage.getItem('token');
}

export { SaveToken, GetToken };
export default api;
