import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";

import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import { FormattedMessage } from "react-intl";

export default function List(props) {
  const history = useHistory();
  const [vehicles, setVehicles] = useState([]);
  const [preload, setPreload] = useState([]);

  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalInactive, setTotalInactive] = useState(0);
  const [selected, setSelected] = useState(0);

  function deleteVehicleById(id) {
    let newVehicles = vehicles;
    newVehicles = newVehicles.filter(vehicles => vehicles.id != id);
    setVehicles(newVehicles);
    setUpdate(!update);
  }

  useEffect(() => {
    UpdateList();
  }, [offset]);

  function UpdateList() {
    setLoading(true);

    api
      .get("vehicle/preload/1", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        console.log("preload", data.data);
        setPreload(data.data);

        const total = data.data?.totals?.reduce(function (a, b) {
          return { total: a.total + b.total };
        });
        setTotalVehicles(total.total);

        const totalActive = data.data?.totals?.filter(item => item.statusid == 1).reduce(function (a, b) {
          return { total: a.total + b.total };
        });

        setTotalActive(totalActive.total);

        const totalInactive = data.data?.totals?.filter(item => (item.statusid == 2 || item.statusid == 3 || item.statusid == 4)).reduce(function (a, b) {
          return { total: a.total + b.total };
        });
        setTotalInactive(totalInactive.total);

      })
      .catch((err) => {
        console.log(err.response);
      });

    api
      .get("vehicle/getall", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        const SearchTerm = props.searchTerm.toLowerCase();
        const filter = data.data.filter((x) =>
          x.name.toLowerCase().includes(SearchTerm) || x.vin.toLowerCase().includes(SearchTerm)
        );
        const slice = filter.slice(offset, offset + perPage);
        setVehicles(slice);
        setPageCount(Math.ceil(filter.length / perPage));
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteVehicle(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("vehicle/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Vehicle deleted",
            }).then(() => {
              //UpdateList();
              deleteVehicleById(id);
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span style={{ 'marginRight': '10px' }}> <FormattedMessage id="total_vehicles" />: <b>{totalVehicles}</b> </span>
          <span style={{ 'marginRight': '10px', 'marginLeft': '10px' }}><FormattedMessage id="total_active" />: <b>{totalActive}</b> </span>
          <span style={{ 'marginRight': '10px', 'marginLeft': '10px' }}><FormattedMessage id="total_inactive" />: <b>{totalInactive}</b> </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>

      </div>
      
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="form.name" /> </th>
              <th scope="col"><FormattedMessage id="form.assigned_device" /></th>
              <th scope="col">VIN</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle) => {
              return (
                <tr>
                  <td>{vehicle.name}</td>
                  <td>
                    {
                      preload?.device?.find((x) => x.id === vehicle.deviceid)
                        ?.name
                    }
                  </td>
                  <td>{vehicle.vin}</td>
                  <td>
                    <div
                      className="table-status"
                      style={{
                        backgroundColor: preload?.status
                          ?.find((x) => x.id === vehicle.statusid)
                          ?.name?.includes("Active")
                          ? "green"
                          : "red",
                      }}>
                      {
                        preload?.status?.find((x) => x.id === vehicle.statusid)
                          ?.name
                      }
                    </div>
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() =>
                        history.push("/vehicles/edit/" + vehicle.id)
                      }
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteVehicle(vehicle.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );
}
