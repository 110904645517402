import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import Loading from "../../components/Loading";

import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";

import ToggleSwitch from "../../components/ToggleSwitch";

export default function List(props) {
  const history = useHistory();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [selected, setSelected] = useState(0);

  const [preload, setPreload] = useState([]);
  const [update, setUpdate] = useState(false);
  
  const [checked, setChecked] = useState(true);
  
  function deleteDriversById(id) {
    let newDrivers = drivers;
    newDrivers = newDrivers.filter(drivers => drivers.id !== id);
    setDrivers(newDrivers);
    setUpdate(!update);
  }

  useEffect(() => {
    UpdateList();
  }, []);

  function UpdateList() {
    setLoading(true);
    api
      .get("driver/preload", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setPreload(data.data);
      });

    /*api
      .get("driver/getall", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        const SearchTerm = props.searchTerm.toLowerCase();
        const filter = data.data.filter((x) =>
          (x.fname.toLowerCase() + ' ' + x.lname.toLowerCase()).includes(SearchTerm)
        );
        const slice = filter.slice(offset, offset + perPage);
        setDrivers(slice);
        setPageCount(Math.ceil(filter.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });*/
  }

  function DeleteDrivers(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("driver/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Driver deleted",
            }).then(() => {
              //UpdateList();
              deleteDriversById(id)
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  function maskPhone(phone) {
    var x = phone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
    return '(' + x[1] + ') ' + x[2] + '-' + x[3];
  };

  useEffect(() => {
    setLoading(true);
    api
      .post("driver/gethide", { hide: checked }, { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        const SearchTerm = props.searchTerm.toLowerCase();
        const filter = data.data.filter((x) =>
          (x.fname.toLowerCase() + ' ' + x.lname.toLowerCase()).includes(SearchTerm)
        );
        const slice = filter.slice(offset, offset + perPage);
        setDrivers(slice);
        setPageCount(Math.ceil(filter.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  }, [checked, offset]);

  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <FormattedMessage id="checkbox.terminated">
            {(text) => {
              return (<ToggleSwitch 
                label={text}
                onClick={() => setChecked(!checked)}
                value={checked}
                textClass="h5 pe-2"
              />);
            }}
          </FormattedMessage>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="tabela.name" />
              </th>
              <th scope="col">
                <FormattedMessage id="tabela.email" />
              </th>
              <th scope="col">
                <FormattedMessage id="tabela.phone" />
              </th>
              <th scope="col">
                <FormattedMessage id="tabela.status" />
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver) => {
              return (
                <tr>
                  <td>{driver.fname} {driver.lname}</td>
                  <td>{driver.email}</td>
                  <td>{ (driver.phone?maskPhone(driver.phone):'')}</td>
                  <td>
                    <div
                      className="table-status"
                      style={{
                        backgroundColor: preload?.status
                          ?.find((x) => x.id === driver.statusid)
                          ?.name?.includes("Active")
                          ? "green"
                          : "red",
                      }}
                    >
                      {
                        preload?.status?.find((x) => x.id === driver.statusid)
                          ?.name
                      }
                    </div>
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history.push("/drivers/edit/" + driver.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteDrivers(driver.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );
}
