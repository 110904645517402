import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ImportPod(props) {

  const history = useHistory();
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState();
  const [year, setYear] = useState(new Date());
  const [week, setWeek] = useState("");

  function onFileChange(e) {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  function onYearChange(e) {
    setYear(e);
  }

  function onWeekChange(e) {
    setWeek(e.target.value);
  }

  function importFile() {
    if (file === "") {
      alert("No file uploaded");
    }
    setLoading(true);
    const data = new FormData();
    data.append("filetoupload", file);
    data.append("year", year.getFullYear());
    data.append("week", week);
    api
      .post("upload/pod", data, {
        headers: {
          "x-access-token": GetToken(),
          "Content-Type": "multipart/form-data;",
        },
      })
      .then((data) => {
        props.setPodMethod(data.data);
        props.setFileNamePodImport(fileName);
        props.setPodYearMethod(year.getFullYear());
        props.setPodWeekMethod(week);
        history.push('/imports/pod/confirm');
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response?.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
        console.log("end request");
      });
  }

  return (
    <>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-12 col-md-12">
          <h5 className="text-left mb-3">
            POD - Photo on Delivery
          </h5>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-6 col-md-3">
          <label><FormattedMessage id="form.year" /></label>
          <DatePicker
            selected={year}
            onChange={(date) => onYearChange(date)}
            className="form-control"
            showYearPicker
            dateFormat="yyyy"
          />
        </div>
        <div className="col-6 col-md-3">
          <label><FormattedMessage id="week" /></label>
          <input
            type="number"
            minLength={1}  maxLength={53} step={1} 
            onChange={(e) => onWeekChange(e)}
            className="form-control"
          />
        </div>
      </div>
      <div
        className="form-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <label htmlFor="upload-pod" id="label-upload-pod" className="label-upload-photo">
          {fileName !== '' ? fileName : <FormattedMessage id="browse_file" />}
        </label>
        <input type="file" name="filetopod" id="upload-pod" className="upload-photo" accept="application/pdf" onChange={onFileChange} />
        <br />
        <FormattedMessage id="import_file">
          {(placeholder) => {
            return (
              <input
                type="button"
                className="btn btn-orange"
                onClick={importFile}
                style={{ backgroundColor: "blue" }}
                value={placeholder}
                disabled={fileName === '' || year === '' || week=== ''}
              />
            );
          }}
        </FormattedMessage>
      </div>
    </>
  );
}
