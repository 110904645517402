import React, { useState, useRef, useEffect, useCallback } from 'react'
import api, { GetToken } from "../../api/axios";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Swal from 'sweetalert2';
import Loading from "../../components/Loading";
import { useHistory, useParams } from "react-router-dom";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

export default function Photo(props) {
    const history = useHistory();
    let { id } = useParams();
    const [uploadObject, setUploadObject] = useState([]);
    const [driver, setDriver] = useState([]);
    const [loading, setLoading] = useState();
    const [imgSrc, setImgSrc] = useState('')
    const imgRef = useRef(null);
    const [crop, setCrop] = useState('');
    const [completedCrop, setCompletedCrop] = useState('');
    const scale = 1;
    const rotate = 0;

    function onSelectFile(e) {

        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''))
            reader.readAsDataURL(e.target.files[0])
        }

    }

    function onImageLoad(e) {
        imgRef.current = e.currentTarget
        const { width, height } = e.currentTarget
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                1,
                width,
                height,
            ),
            width,
            height,
        )
        setCrop(crop);
    }

    function Cancel() {
        history.push('/drivers/edit/' + id);
    }

    useEffect(() => {
        props.clear();
        api
            .get("driver/getbyid/" + id, {
                headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
                setDriver(data.data[0]);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.response?.data.message,
                });
            });

    }, []);



    function getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, 'image/jpeg');
        });
    }


    async function importFile() {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, 'name');

        if (croppedImg === "") {
            alert("No file uploaded");
        }

        const data = new FormData();
        data.append("filetoimage", croppedImg);
        setLoading(true);
        api.post("/upload/image/" + id, data, {
            headers: {
                "x-access-token": GetToken(),
                "Content-Type": "multipart/form-data;",
            },
        }).then((data) => {
            history.push('/drivers/edit/' + id);
        })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.message,
                });
            })
            .finally(() => {
                setLoading(false);
                console.log("end request");
            });

    }



    return (
        <div >
            <Loading visible={loading} />
            <h5>  <FormattedMessage id="edit_photo" />: {driver.fname} {driver.lname}</h5>
            <div
                className="form-content"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <label htmlFor="upload-photo" id="label-upload-photo">
                    <FormattedMessage id="browse_file" />
                </label>

                <input type="file" name="filetoimage" id="upload-photo" accept="image/jpg, image/jpeg, image/png" onChange={onSelectFile} />

                <br />
                <div className="container">
                    {imgSrc && (
                        <div className="row ">
                            <div className="col-md-8 offset-md-2  mt-3">
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={1}
                                >
                                    <img
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            </div>
                        </div>
                    )}
                    <br />

                    <div className="row">
                        <div className="col-md-6 mt-3">
                            {completedCrop && (
                                <FormattedMessage id="save">
                                    {(placeholder) => {
                                        return (
                                            <input
                                                type="button"
                                                className="btn btn-orange float-end"
                                                onClick={importFile}
                                                style={{ backgroundColor: "blue" }}
                                                value={placeholder}
                                            />
                                        );
                                    }}
                                </FormattedMessage>
                            )}
                        </div>
                        <div className="col-md-6 mt-3">
                            <button className="btn btn-orange " onClick={() => Cancel()}><FormattedMessage id="cancel" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
