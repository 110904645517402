import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import jwt_decode from "jwt-decode";

import List from "./list";
import Import from "./import";
import AddEdit from "./addEdit";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/Loading";

export default function Scorecards() {

  var pathName = useLocation().pathname;

  const history = useHistory();
  const [score, setScore] = useState();
  const [role, setRole] = useState();

  const [loading, setLoading] = useState(false);
  const [lastOrder, setLastOrder] = useState('asc');
  const [lastField, setLastField] = useState('id');

  useEffect(() => {

    const token = GetToken();
    if (token === "null") {
      history.push("/");
      return;
    }
    const userInfo = jwt_decode(token);
    setRole(userInfo.role);
    
  });

  function CreateScorecard() {
    history.push("/scorecard/add");
  }

  return (
    <Switch>
      <div className="container mt-3">
        <Loading visible={loading} />

        <div className="row mb-3">
          {!pathName.includes("import/confirm") && (
            <div className="col-12 col-md-8">
              <p className="title">
                <FormattedMessage id="menu.scorecard" />
              </p>
            </div>
          )}

          {role == 0 && !pathName.includes("add") &&
            !pathName.includes("edit") &&
            !pathName.includes("import") && (
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-6 offset-6">
                    <button className="btn btn-orange btn100" onClick={CreateScorecard}>
                      <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                      <FormattedMessage id="form.create" />
                    </button>
                  </div>
                  {/*
                  <div className="col-6">
                    <button
                      className="btn btn-orange btn100"
                      style={{ backgroundColor: "blue" }}
                      onClick={() => history.push("/scorecard/import")}
                    >
                      <FontAwesomeIcon icon={faFileImport} className="btn-icon" />
                      <FormattedMessage id="import" />
                    </button>
                  </div>
                */} 
                </div>
              </div>
            )}

        </div>

        {(pathName.includes("/scorecard") && !pathName.includes("/add") && !pathName.includes("/edit") && !pathName.includes("/confirm") && !pathName.includes("/import")) &&
          <List setLastOrder={setLastOrder} setLastField={setLastField} />}
        <Route path="/scorecard/add" component={AddEdit} />
        <Route path="/scorecard/edit/:id" component={AddEdit} />
        <Route
          path="/scorecard/import"
          exact
          component={(props) => <Import setScoreMethod={setScore} />}
        />
      </div>
    </Switch>
  );
}

