import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../../api/axios";
import { FormattedMessage } from "react-intl";

export default function List(props) {
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  let { countryId } = props;

  useEffect(() => {
    UpdateList();
  }, [countryId]);

  function UpdateList() {
    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        setCountries(data.data);

        api
          .get("param-state/getbycountryid/" + countryId, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            console.log(data);
            setStates(data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  return (
    <div className="table-container">
      <table className="table table-list btn100">
        <thead>
          <tr>
            <th scope="col"><FormattedMessage id="tabela.country" /></th>
            <th scope="col"><FormattedMessage id="tabela.name" /></th>
            <th scope="col">Abbrev</th>
          </tr>
        </thead>
        <tbody>
          {states.map((state) => {
            console.log(state);
            return (
              <tr>
                <td>{countries[countryId] && countries[countryId].name}</td>
                <td>{state.name}</td>
                <td>{state.abbrev}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
