import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import ReactPaginate from "react-paginate";
import Modal from 'react-modal';

import { FormattedMessage } from "react-intl";

export default function Netradyne(props) {
  const history = useHistory();
  const [netradynes, setNetradynes] = useState([]);
  const [netradyne, setNetradyne] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [driver, setDriver] = useState([]);
  const [visibleScoreModal, setVisibleScoreModal] = useState(false);

  let { id } = useParams();
  const isEdit = id != null;

  useEffect(() => {
    props.clear();
    UpdateList();
  }, [offset]);

  function UpdateList() {
    setLoading(true);
    api
      .get("netradyne/getbyuserid/" + id, {
        headers: { "x-access-token": GetToken() }
      })
      .then((data) => {
        const slice = data.data.slice(offset, offset + perPage);
        setNetradynes(slice);
        setPageCount(Math.ceil(data.data.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    api
      .get("driver/getbyid/" + id, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        setDriver(data.data[0]);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });
  }

  function OpenNetradyne(id) {
    let netradyne = netradynes.filter(el => el.id === id);
    console.log(netradyne);
    setNetradyne(netradyne[0]);
    setVisibleScoreModal(true);
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
  };

  return (
    <div>
      <Modal isOpen={visibleScoreModal} >
        <div className="row">
          <div className="col-md-12 mt-3">
            <h5> <FormattedMessage id="tabela.driver" /> Netradyne: {driver.fname} {driver.lname}</h5>
          </div>
          <div className="col-md-3 mt-3">
            <p>Acceleration: {netradyne.acceleration}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Braking: {netradyne.braking}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Camera: {netradyne.camera}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Daissue: {netradyne.daissue}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Dakudo: {netradyne.dakudo}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Distance: {netradyne.distance}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Distraction: {netradyne.distraction}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Events: {netradyne.events}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Green: {netradyne.green}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Highg: {netradyne.highg}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Hturn: {netradyne.hturn}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Impact: {netradyne.impact}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Initiated: {netradyne.initiated}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Light: {netradyne.light}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Minutes: {netradyne.minutes}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Overspeeding: {netradyne.overspeeding}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Requested: {netradyne.requested}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Score: {netradyne.score}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Seatbelt: {netradyne.seatbelt}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Speeding: {netradyne.speeding}</p>
          </div>
          <div className="col-md-2 mt-3">
            <p>Star: {netradyne.star}</p>
          </div>
          <div className="col-md-2 mt-3">
            <p>Tripdate: {netradyne.tripdate}</p>
          </div>
          <div className="col-md-2 mt-3">
            <p>Uturn: {netradyne.uturn}</p>
          </div>
          <div className="col-md-2 mt-3">
            <p>Sign: {netradyne.sign}</p>
          </div>
          <div className="col-md-2 mt-3">
            <p>Weaving: {netradyne.weaving}</p>
          </div>
        </div>
        <button
          className="btn btn-secondary btn-confirm float-end"
          onClick={() => {
            setVisibleScoreModal(false);
          }}
        >
          <FormattedMessage id="close" />
        </button>
      </Modal>
      <Loading visible={loading} />
      <div className="row mb-3">
        <div className="col-12">
          <h5 className="float-start" >
            Netradyne: {driver.fname} {driver.lname}
          </h5>
          <a className="btn btn-orange float-end m-0"
            onClick={() => history.push("/drivers/edit/" + id)}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" className="btn-icon mr-4" />
            <FormattedMessage id="return" />
          </a>
        </div>
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                Tripdate
              </th>
              <th scope="col">
                Minutes
              </th>
              <th scope="col">
                Green
              </th>
              <th scope="col">
                Overspeeding
              </th>
              <th scope="col">
                Score
              </th>
              <th scope="col">
                Star
              </th>
              <th scope="col">
                Highg
              </th>
              <th scope="col">
                Impact
              </th>
              <th scope="col">Initiated</th>
              <th scope="col">Sign</th>
            </tr>
          </thead>
          <tbody>
            {netradynes.map((netradyne) => {
              return (
                <tr>
                  <td>{netradyne.tripdate}</td>
                  <td>{netradyne.minutes}</td>
                  <td>{netradyne.green}</td>
                  <td>{netradyne.overspeeding}</td>
                  <td>{netradyne.score}</td>
                  <td>{netradyne.star}</td>
                  <td>{netradyne.highg}</td>
                  <td>{netradyne.impact}</td>
                  <td>{netradyne.initiated}</td>
                  <td>{netradyne.sign}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => OpenNetradyne(netradyne.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}
