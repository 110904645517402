import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";

import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";
import { FormattedMessage } from "react-intl";
import ToggleSwitch from "../../components/ToggleSwitch";

export default function List(props) {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [selected, setSelected] = useState(0);
  const [checked, setChecked] = useState(false);

  function deleteUserById(id) {
    let newRosters = users;

    newRosters = newRosters.filter(users => users.id !== id);

    setUsers(newRosters);
    setUpdate(!update);
  }

  function DeleteUser(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("user/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "User deleted",
            }).then(() => {
              //UpdateList();
              deleteUserById(id);
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  function maskPhone(phone) {
    var x = phone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
    return '(' + x[1] + ') ' + x[2] + '-' + x[3];
  };

  useEffect(() => {
    setLoading(true);
    api
      .post("user/gethide", { hide: checked }, { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        const SearchTerm = props.searchTerm.toLowerCase();
        const filter = data.data.filter((x) =>
          (x.fname.toLowerCase() + ' ' + x.lname.toLowerCase()).includes(SearchTerm)
        );
        const slice = filter.slice(offset, offset + perPage);
        setUsers(slice);
        setPageCount(Math.ceil(filter.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  }, [checked, offset]);

  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <FormattedMessage id="checkbox.terminated">
            {(text) => {
              return (<ToggleSwitch 
                label={text}
                onClick={() => setChecked(!checked)}
                value={checked}
                textClass="h5 pe-2"
              />);
            }}
          </FormattedMessage>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selected}
          />
        </div>
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="tabela.name" />
              </th>
              <th scope="col">E-mail</th>
              <th scope="col">
                <FormattedMessage id="tabela.phone" />
              </th>
              <th scope="col"> <FormattedMessage id="type" /></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr>
                  <td>{user.fname} {user.lname}</td>
                  <td>{user.email}</td>
                  <td>{ (user.phone?maskPhone(user.phone):'') }</td>
                  <td>
                    {user.role === 0 ? "Admin" : "Driver"}
                  </td>
                  <td>
                    <div
                      className="table-status"
                      style={{
                        backgroundColor: user.status === 0
                          ? "red"
                          : "green",
                      }}>
                      {

                        user.status === 0 ? "Inactive" : "Active"
                      }
                    </div>
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history.push("/users/edit/" + user.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteUser(user.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );
}
