import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, value, onClick, textClass, formattedMessage }) => {
	return (
		<div className="tcontainer">
			<span className={textClass}>{label}</span>
			<div className="toggle-switch">
				<input 
					type="checkbox" 
					className="checkbox"
					name={label} 
					id={label} 
					onClick={onClick}
					value={value}
					defaultChecked={value}
				/>
				<label className="label" htmlFor={label}>
					<span className="inner" />
					<span className="switch" />
				</label>
			</div>
		</div>
	);
};

export default ToggleSwitch;
