import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../../api/axios";
import { FormattedMessage } from "react-intl";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import Loading from "../../../components/Loading";

export default function List() {
  const history = useHistory();
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    UpdateList();
  }, []);

  function UpdateList() {
    setLoading(true);

    api
        .get("param-vehicle-status/getall", { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          setVehicles(data.data);
        })
        .catch((err) => {
          console.log(err.response);
        }).finally(_ => {
      setLoading(false)
    })
  }

  function DeleteDriverStatus(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
            .delete("param-vehicle-status/delete/" + id, {
              headers: { "x-access-token": GetToken() },
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Vehicle Status deleted",
              }).then(() => {
                UpdateList();
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response.data.message,
              });
            });
      }
    });
  }

  return (
      <div className="table-container">
        <Loading visible={loading} />
        <table className="table table-list btn100">
          <thead>
          <tr>
            <th scope="col"><FormattedMessage id="tabela.name" /></th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          {vehicles.map((vehicle) => {
            console.log(vehicle);
            return (
                <tr>
                  <td>{vehicle.name}</td>
                  <td className={vehicle.mutable ? 'icon-cell' : 'icon-cell pe-none'}>
                    <FontAwesomeIcon
                        color={vehicle.mutable ? "black" : "#ccc"}
                        icon={faEdit}
                        onClick={() => history.push("/params/vehicle-status/edit/" + vehicle.id)}
                    />
                  </td>
                  <td className={vehicle.mutable ? 'icon-cell' : 'icon-cell pe-none'}>
                    <FontAwesomeIcon
                        color={vehicle.mutable ? "black" : "#ccc"}
                        icon={faTrash}
                        onClick={() => DeleteDriverStatus(vehicle.id)}
                    />
                  </td>
                </tr>
            );
          })}
          </tbody>
        </table>
      </div>
  );
}
