import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../../api/axios";
import {FormattedMessage} from "react-intl";

export default function List() {
  const [contries, setContries] = useState([]);

  useEffect(() => {
    UpdateList();
  }, []);

  function UpdateList() {
    api
      .get("param-country/getall", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setContries(data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  return (
    <div className="table-container">
      <table className="table table-list btn100">
        <thead>
          <tr>
            <th scope="col"><FormattedMessage id="tabela.name" /></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {contries.map((country) => {
            console.log(country);
            return (
              <tr>
                <td>{country.name}</td>                
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
