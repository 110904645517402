import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api, { GetToken } from "../api/axios";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Chart from "../components/Chart";
import { faAngleLeft, faAngleRight, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import jwt_decode from "jwt-decode";
import Loading from "../components/Loading";
import DriverLicenses from "../components/DriverLicenses";

export default function Dashboard() {

  const history = useHistory();
  const [driverLicensesExpiring, setDriverLicensesExpiring] = useState([]);
  const [driverLicensesExpired, setDriverLicensesExpired] = useState([]);
  const [role, setRole] = useState();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeMask, setTimeMask] = useState('');
  const [scoreWeek, setScoreWeek] = useState([]);
  const [scorecards, setScorecards] = useState([]);
  const [preload, setPreload] = useState([]);
  const [counts, setCounts] = useState([]);

  useEffect(() => {

    setLoading(true);

    api.get('param-country/getall', {
      headers: { "x-access-token": GetToken() },
    }).then(data => {
      const currentLang = localStorage.getItem("language");
      const lang = data.data.find((x) => x.code === currentLang)?.timemask;
      setTimeMask(lang);
    }).catch(_ => {

    });

    api
      .get("dashboard/preload", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setDriverLicensesExpiring(data.data.dlexpiring);
        setDriverLicensesExpired(data.data.dlexpired);
        setCounts(data.data.table);

        const scoresCards = data.data.scorecard;
        scoresCards.reverse();
        setScoreWeek(scoresCards[scoresCards.length - 1]);
        setScorecards(scoresCards);

      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      }).finally(_ => {
        setLoading(false);
      });

    const token = GetToken();
    if (token === "null") {
      history.push("/");
      return;
    }

    const userInfo = jwt_decode(token);
    setRole(userInfo.role);

    if (userInfo.role == 1) {
      api
        .get("roster/getbydriverid/" + userInfo.id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          setMessages(data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });

      api
        .get("dashboard/preload-driver", { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          setPreload(data.data);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        }).finally(_ => {
          setLoading(false);
        });
    }

  }, []);

  if (role == 1) {

    return (
      <div className="container mt-3">
        <Loading visible={loading} />
        <h3 className="title">
          <FormattedMessage id="Dashboard" />
        </h3>
        {messages.length == 0 ? (
          "No messages"
        ) : (
          <div className="table-container">
            <table className="table table-list btn100">
              <thead>
                <tr>
                  <th scope="col"><FormattedMessage id="tabela.date" /></th>
                  <th scope="col"><FormattedMessage id="tabela.time" /></th>
                  <th scope="col"><FormattedMessage id="tabela.vehicle" /></th>
                  <th scope="col"><FormattedMessage id="tabela.device" /></th>
                  <th scope="col"><FormattedMessage id="tabela.route" /></th>
                  <th scope="col"><FormattedMessage id="tabela.staging" /></th>
                  <th scope="col"><FormattedMessage id="tabela.parking" /></th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message) => {
                  return (
                    <tr>
                      <td>{message.date}</td>
                      <td>{tConvert(message.time)}</td>
                      <td>{(preload?.vehicle?.filter(x => x.id === message.vehicleid)[0]?.name)}</td>
                      <td>{(preload?.device?.filter(x => x.id === message.deviceid)[0]?.name)} </td>
                      <td>{message.route}</td>
                      <td>{message.staging}</td>
                      <td>{message.space}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }


  function printNameScore(score) {
    let result = '';
    switch (score) {
      case 1:
        result = <FormattedMessage id="poor" />;
        break;
      case 2:
        result = <FormattedMessage id="fair" />;
        break;
      case 3:
        result = <FormattedMessage id="great" />;
        break;
      case 4:
        result = <FormattedMessage id="fantastic" />;
        break;
      case 5:
        result = <FormattedMessage id="fantastic_plus" />;
        break;
      default:
        result = '';
    }
    return result;
  }

  function printCoresScore(score) {
    let result = '';
    switch (score) {
      case 1:
        result = "#F60404";
        break;
      case 2:
        result = "#de7309";
        break;
      case 3:
        result = "#3F7F00";
        break;
      case 4:
        result = "#0000BF";
        break;
      case 5:
        result = "#0000BF";
        break;
      default:
        result = '#0000BF';
    }
    return result;
  }


  function tConvert(time) {

    if (timeMask !== "12H") {
      return time;
    }
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  //CORES
  //#0000BF #3F7F00 #82FF05 #FBE731 #F60404"
  return (
    <div className="container mt-3">
      <Loading visible={loading} />
      <h3 className="title mb-3">
        <FormattedMessage id="Dashboard" />
      </h3>
      <h5 style={{ color: "#A6A6A6", fontSize: "12px", marginBottom: "0" }}><FormattedMessage id="week_storecard" /></h5>
      <h5><FormattedMessage id="week" /> {scoreWeek?.week} - {scoreWeek?.year}  </h5>
      <div className="row d-flex justify-content-between ">

        <div className="col-sm-3">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="overall_standing" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ color: (printCoresScore(scoreWeek?.overall)), fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{(printNameScore(scoreWeek?.overall))}</h5>}
            </div>
          </div>
        </div>
        <div className="col-sm-3 ">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="safety_compliance" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ color: (printCoresScore(scoreWeek?.safety)), fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{(printNameScore(scoreWeek?.safety))}</h5>}
            </div>
          </div>
        </div>
        { /* 
        <div className="col-sm-2 table-list m-3">
          <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="reliability" /></h5>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTachometerAlt}
              color={"#DBDBDB"}
              size="2x"
            />
            {!loading && <h5 style={{ color: (printCoresScore(scoreWeek?.reliability)), fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{(printNameScore(scoreWeek?.reliability))}</h5>}
          </div>
        </div>
*/ }
        <div className="col-sm-3">
          <div className="table-list p-3">

            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="team" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ color: (printCoresScore(scoreWeek?.team)), fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{(printNameScore(scoreWeek?.team))}</h5>}
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="quality" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ color: (printCoresScore(scoreWeek?.quality)), fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{(printNameScore(scoreWeek?.quality))}</h5>}
            </div>
          </div>
        </div>
      </div>
      <h5 className="mt-3"><FormattedMessage id="scorecard-count" /> {counts?.year} </h5>

      <div className="row d-flex justify-content-left ">
        <p className="mb-4">
          <FormattedMessage id="routes-count" />: <b>{counts?.routes}</b> <br />
          <FormattedMessage id="packages-count" />: <b>{counts?.packages}</b>
        </p>
      </div>

      <div className="row d-flex justify-content-left ">

        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="fantastic_plus" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['5']}</h5>}
            </div>
          </div>
        </div>

        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="fantastic" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['4']}</h5>}
            </div>
          </div>
        </div>


        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="great" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['3']}</h5>}
            </div>
          </div>
        </div>

        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="fair" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['2']}</h5>}
            </div>
          </div>
        </div>


        <div className="col">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "12px" }}><FormattedMessage id="poor" /></h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="2x"
              />
              {!loading && <h5 style={{ fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{counts?.['1']}</h5>}
            </div>
          </div>
        </div>

      </div>


      <h5 className="mt-3"><FormattedMessage id="scores" /></h5>
      <div class="table-list mb-5">
        {!loading && <Chart scores={scorecards} />}
      </div>

      <div className="row">
        <div className="col-12 col-sm-6">
          <h5><FormattedMessage id="dl_expired" /></h5>
          {!loading && <DriverLicenses driverLicenses={driverLicensesExpired} />}
        </div>
        <div className="col-12 col-sm-6">
          <h5><FormattedMessage id="dl_expiration" /></h5>
          {!loading && <DriverLicenses driverLicenses={driverLicensesExpiring} />}
        </div>
      </div>
    </div>
  );
}
