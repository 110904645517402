import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";
import jwt_decode from "jwt-decode";
import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
  faEye,
  faPlus,
  faBroom,
  faCommentAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";
import moment from "moment";

export default function List(props) {

  const history = useHistory();
  const [preload, setPreload] = useState([]);
  const [issue, setIssue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(0);
  const [date, setDate] = useState("");
  const [driverid, setDriverid] = useState("");
  const [filter, setFilter] = useState(false);

  var pathName = useLocation().pathname;

  function deleteIssueById(id) {
    let newIssue = issue;
    newIssue = newIssue.filter(x => x.id != id);
    setIssue(newIssue);
  }

  function CreateIssue() {
    history.push("/counseling/issue/add");
  }


  useEffect(() => {

    UpdateList();
  }, [page, filter]);

  function UpdateList() {

    setLoading(true);

    api
      .get("issue/preload", { headers: { "x-access-token": GetToken() } })
      .then((preloadApi) => {
        setPreload(preloadApi.data);
      })
      .catch((err) => {
        console.log(err.response);
      });


    if (filter) {
      api
        .post("issue/getfilter",
          {
            "page": page,
            "rows": perPage,
            "driverid": driverid,
            "date": date
          },
          { headers: { "x-access-token": GetToken() } },
        )
        .then((data) => {
          setIssue(data.data.issue);
          setPageCount(Math.ceil(data.data.rows / perPage));
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });

    } else {
      api
        .post("issue/getall",
          {
            "page": page,
            "rows": perPage
          },
          { headers: { "x-access-token": GetToken() } },
        )
        .then((data) => {
          setIssue(data.data.issue);
          setPageCount(Math.ceil(data.data.rows / perPage));
        })
        .catch((err) => {
        })
        .finally(() => {
          setLoading(false);
        });

    }
  }

  function DeleteIssue(id) {

    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("issue/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Issue deleted",
            }).then(() => {
              //UpdateList();
              deleteIssueById(id);
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });

  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setSelected(selectedPage);
    setPage(selectedPage+1);
  };

  function DecreaseDate() {
    setDate(moment((date != "" ? date : moment().format("YYYY-MM-DD"))).subtract(1, "days").format("YYYY-MM-DD"));
  }

  function IncreaseDate() {
    setDate(moment((date != "" ? date : moment().format("YYYY-MM-DD"))).add(1, "days").format("YYYY-MM-DD"));
  }

  function clearFilters() {
    setFilter(false);
    setPage(1);
    setDriverid('');
    setDate('');
    UpdateList();
  }

  function changeSelectDriver(e) {
    setDriverid(e.target.value);
  }

  function handleFilterClick() {
    setFilter(true);
    setPage(1);
    UpdateList();
  }


  return (
    <div>
      <div className="row mb-2">
        <div className="col-12 col-md-12">
          <p className="title">
            <FormattedMessage id="issue" />
          </p>
        </div>
      </div>
      <div className="row mb-3">

        <div className="col-6 col-md-1">
          <button
            className="btn btn-success  w-100 m-0"
            onClick={() => clearFilters()}
            style={{
              backgroundColor: 'blue',
              borderColor: 'transparent',
              width: "35px",
              display: 'inline',
              marginRight: '15px',
              padding: '8.5',
              float: 'right'
            }}
          >
            <FontAwesomeIcon icon={faBroom} />
          </button>
        </div>

        <div className="col-md-3">
          <select
            className="form-select"
            onChange={(e) => changeSelectDriver(e)}
          >
            <FormattedMessage id="tabela.select_driver">
              {(placeholder) => {
                return <option value="" selected={driverid === ''}>{placeholder}</option>;
              }}
            </FormattedMessage>
            {preload?.driver?.map((data) => (
              <option value={data.id} selected={driverid === data.id}>{data.fname} {data.lname}</option>
            ))}
          </select>
        </div>


        <div className="col-md-4 col-12  mb-2">
          <div
            onClick={DecreaseDate}
            style={{
              display: "inline",
              paddingTop: 7,
              paddingBottom: 7,
              width: "10%",
              color: "#fff",
              borderRadius: "5px 0 0 5px",
              float: "left",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#808080",
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" />
          </div>

          <input
            type="date"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => setDate(e.target.value)}
            value={date}
            className="form-control col"
            style={{
              display: "inline-block",
              width: "80%",
              borderRadius: 0,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          />

          <div
            onClick={IncreaseDate}
            style={{
              display: "inline",
              paddingTop: 7,
              paddingBottom: 7,
              width: "10%",
              color: "#fff",
              borderRadius: "  0 5px 5px 0",
              float: "right",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#808080",
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} size="lg" />
          </div>
        </div>

        <div className="col-6 col-md-2">
          <button className="btn btn-orange btn100" onClick={handleFilterClick}>
            <FormattedMessage id="filter" />
          </button>
        </div>


        <div className="col-6 col-md-2">
          <button className="btn btn-orange btn100" onClick={CreateIssue}>
            <FontAwesomeIcon icon={faPlus} className="btn-icon" />
            <FormattedMessage id="form.create" />
          </button>
        </div>
      </div>

      <Loading visible={loading} />

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="tabela.date" /></th>
              <th scope="col"><FormattedMessage id="tabela.driver" /></th>
              <th scope="col"><FormattedMessage id="description" /> </th>
              <th scope="col"><FormattedMessage id="type" /></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {issue.map((item) => {
              return (
                <tr>
                  <td>{item.date}</td>
                  <td>
                    {preload?.driver?.filter(x => x.statusid == 1 && item.userid == x.id).map((data) => (
                      <div key={data.id}>{data.fname + ' ' + data.lname}</div>
                    ))}
                  </td>
                  <td>{item.description}</td>
                  <td>{preload.issueType && preload.issueType[item.typeid]}</td>
                  <td className='icon-cell'>
                    {item.counselingid !== null ? (
                      <FontAwesomeIcon
                        icon={faCommentAlt}
                        onClick={() => history.push("/counseling/list/edit/" + item.counselingid)}
                      />
                    ) : null}
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history.push("/counseling/issue/edit/" + item.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteIssue(item.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div >
  );

}


function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;

  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  console.log("formated date", [year, month, day].join("-"));
  return [year, month, day].join("-");
}
