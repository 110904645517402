import React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl'

import translations from './translations.json'
import api, { GetToken } from '../api/axios';

export default class IntlProviderConfigured extends React.Component {
    state = {
        loading: true,
        locale: ''
    }

    componentDidMount() {
        this.updateParams();         
    }

    componentDidUpdate(){
        const currentLang = localStorage.getItem('language');
        if(currentLang == null){
            this.updateParams()
        }
    }

    updateParams(){
        api.get('param-country/getall', {
            headers: { "x-access-token": GetToken() },
          }).then(data => {
            data.data.map(language => {
                addLocaleData(require(`react-intl/locale-data/${language.code}`));
            });
    
            const currentLang = localStorage.getItem('language');    
            if(currentLang == null){
                localStorage.setItem('language', 'en');
                this.setState({ locale: 'en', loading: false });
            } else{
                this.setState({ locale: currentLang, loading: false });
            }
          }).catch(err=> {
            this.setState({ locale: 'en', loading: false });
          });   
    }

    render() {
        const locale = this.state?.locale
        const { children } = this.props

        if(this.state.loading) return null

        return (
            <IntlProvider locale={locale} messages={translations[locale]}>
                {children}
            </IntlProvider>
        )
    }
}