import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { faClock, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";

export default function AddEdit() {
  const history = useHistory();
  const [preload, setPreload] = useState([]);
  const [time, setTime] = useState("");
  const [timeMask, setTimeMask] = useState();
  let { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {

    const time_formated =
      (time.getHours() < 10 ? "0" : "") +
      time.getHours() +
      ":" +
      (time.getMinutes() < 10 ? "0" : "") +
      time.getMinutes();

    if (time_formated.includes("NaN")) {
      alert("Invalid Time");
    }


    if (id) {
      //edit
      api
        .put(
          "issue/update/" + id,
          {
            typeid: data.typeid,
            description: data.description,
            date: data.date,
            time: time_formated,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          // title: "Success",
          //}).then(() => {
          history.push("/counseling/issue");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "issue/create",
          {
            userid: data.userid,
            typeid: data.typeid,
            date: data.date,
            description: data.description,
            time: time_formated,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          history.push("/counseling/issue");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  }

  useEffect(() => {

    api
      .get("issue/preload", { headers: { "x-access-token": GetToken() } })
      .then((preloadApi) => {
        setPreload(preloadApi.data);


        if (!id) {
          const current = new Date();
          setTime(current);
        }

        if (id) {

          api
            .get("issue/getbyid/" + id, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              const value = data.data[0];
              setValue("userid", value.userid);
              setValue("typeid", value.typeid);
              setValue("date", value.date);
              setValue("description", value.description);

              const current = new Date();
              const dateTimeTwo = new Date(
                `${current.getFullYear()}/${current.getMonth() + 1
                }/${current.getDate()} ${value.time}`
              );
              setTime(dateTimeTwo);

            })
            .catch((err) => {
              console.log(err.response);
            });
            
        }

      })
      .catch((err) => {
        console.log(err.response);
      });




  }, [id, setValue]);


  function changeSelectDriver(e) {

    const driver = preload.driver?.find(x => x.id == e.target.value);
    setValue("userid", driver?.id);

  }


  return (
    <div className="row">
      <div className="col-12 col-md-12 mb-2">
        <p className="title">
          <FormattedMessage id="issue" />
        </p>
      </div>
      <div className="col-md-6 col-12">
        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
          <div className="row">
            <div className="col-md-12  mt-3">
              <FormattedMessage id="form.delivery_associate" />
              <select
                className="form-select"
                {...register("userid", { required: true })}
                onChange={(e) => changeSelectDriver(e)}
                disabled={id ? true : null}
              >
                <FormattedMessage id="form.select">
                  {(placeholder) => {
                    return <option value="">{placeholder}</option>;
                  }}
                </FormattedMessage>
                {preload?.driver?.map((data) => (
                  <option value={data.id}>{data.fname} {data.lname}</option>
                ))}
              </select>
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="type" />
              <select {...register("typeid", { required: true })} className="form-select">
                <FormattedMessage id="form.select">
                  {(placeholder) => {
                    return <option value="" >{placeholder}</option>;
                  }}
                </FormattedMessage>
                {preload.issueType && Object.entries(preload?.issueType).map((data) => (
                  <option value={data[0]}>{data[1]}</option>
                ))}
              </select>
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="description" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                {...register("description", { required: true, min: 1 })}
              />
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="form.date" />
              <input
                className="form-control"
                type="date"
                {...register("date", { required: true })}
              />
            </div>


            <div className="col-md-12  mt-3">

              <FormattedMessage id="form.time" />
              {timeMask === "12H" ? (
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  value={time}
                  onChange={(e) => {
                    setTime(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  style={{ display: "inherit", margin: 0, padding: 0 }}
                  keyboardIcon={<FontAwesomeIcon icon={faClock} size="xs" />}
                />
              ) : (
                <input
                  type="time"
                  value={moment(time).format("HH:mm")}
                  className="form-control"
                  onChange={(e) => {
                    setTime(new Date("2021/01/01 " + e.target.value));
                    setTime(new Date("2021/01/01 " + e.target.value));
                  }}
                />
              )}
            </div>

          </div>

          <div className="d-flex flex-row-reverse bd-highlight">
            <FormattedMessage id="edit_save">
              {(edit_save) => {
                return (
                  <button type="submit"
                    className="btn btn-orange btn-confirm float-end"
                  >
                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                    {id ? edit_save.edit : edit_save.save}
                  </button>
                );
              }}
            </FormattedMessage>
            <button
              className="btn btn-secondary btn-confirm"
              onClick={() => history.push("/counseling/issue")}
            >
              <FormattedMessage id="form.cancel" />
            </button>
          </div>
        </form>
      </div>

    </div>

  );
}



function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;
  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");

}