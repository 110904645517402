import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

export default function CdfConfirm({ cdf, year, week, fileNameImport }) {

    const history = useHistory();
    const [uploadData, setUploadData] = useState();
    const [checkAll, setCheckAll] = useState(true);
    const [uploadObject, setUploadObject] = useState([]);
    const [uploadErrors, setUploadErrors] = useState([]);
    const [loading, setLoading] = useState();

    useEffect(() => {

        if (cdf === undefined) {
            history.push('/imports');
            return;
        }

        cdf.found.map(obj => {
            obj.active = true;
        });

        setUploadData(cdf);

    }, []);


    function Save() {
        let upload_object = {
            year: year,
            week: parseInt(week),
            feedback: [],
        };

        uploadData.found.forEach(retorno => {
            if (!retorno.active) {
                return null;
            }
            delete retorno.active;
            upload_object.feedback.push(retorno)
        });
        setLoading(true);
        api
            .post("feedback-user/save", upload_object, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.data.message,
                }).then(_ => {
                    history.push('/imports')
                })

            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response?.data.message,
                });

            }).finally(_ => {
                setLoading(false);
                setUploadObject(upload_object)
            })

    }

    function ChangeSelect(e, index) {
        let uploadData_clone = uploadData;
        uploadData_clone.found.map((data, key) => {
            if (key != index) {
                return null;
            }
            data.active = !data.active
        });
        setUploadData(uploadData_clone);
        UpdateUploadObject(uploadData_clone);
    }

    function checkAllFunc(e) {
        let uploadDataClone = uploadData;
        uploadDataClone.found.forEach(retorno => {
            retorno.active = e.target.checked;
            return retorno;
        });
        setCheckAll(e.target.checked)
        setUploadData(uploadDataClone);
        UpdateUploadObject(uploadDataClone);
    }

    function UpdateUploadObject(uploadDataFunc) {
        let upload_object = {
            found: [],
        };
        uploadDataFunc.found.forEach(retorno => {
            upload_object.found.push(retorno)
        })
        setUploadObject(upload_object)
    }


    return (
        <div >
            <Loading visible={loading} />
            <div className="row mb-3">
                <div className="col-md-8">
                    <p className="title mb-0 pb-0">CDF <FormattedMessage id="import" /> </p>
                    <p>{fileNameImport}</p>
                </div>
                <div className="col-6 col-md-4  text-end">
                    <button className="btn d-inline btn-orange me-3" style={{ backgroundColor: "red" }} onClick={() => { history.push('/imports') }}>
                        <FormattedMessage id="form.cancel" />
                    </button>
                    <button
                        className="btn d-inline btn-orange"
                        style={{ backgroundColor: "blue" }}
                        onClick={Save}
                    >
                        <FormattedMessage id="save" />
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <p><FormattedMessage id="found" /></p>
                    <div className="table-container">
                        <table className="table table-list btn100" style={{ fontSize: 12 }}>
                            <thead>
                                <tr style={{ fontWeight: 600, textAlign: "left" }}>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultChecked={checkAll}
                                                onChange={(e) => checkAllFunc(e)}
                                                id="flexCheckIndeterminate"
                                            />
                                        </div>
                                    </td>
                                    <td><FormattedMessage id="tabela.name" /></td>
                                    <td>Transporter ID</td>
                                </tr>
                            </thead>
                            <tbody>
                                {uploadData?.found?.map((data, key) => {
                                    return (
                                        <tr style={{ textAlign: "left" }}>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        key={key}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={data.active}
                                                        onChange={(e) => ChangeSelect(e, key)}
                                                    />
                                                </div>
                                            </td>
                                            <td>{data.name}</td>
                                            <td>{data.transporterid}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-md-6">
                    <p><FormattedMessage id="notfound" /></p>
                    <div className="table-container">
                        <table className="table table-list btn100" style={{ fontSize: 12 }}>
                            <thead>
                                <tr style={{ fontWeight: 600, textAlign: "left" }}>
                                    <td><FormattedMessage id="tabela.name" /></td>
                                    <td>Transporter ID</td>
                                </tr>
                            </thead>
                            <tbody>
                                {uploadData?.notfound?.map((data, key) => {
                                    return (
                                        <tr style={{ textAlign: "left" }}>
                                            <td>{data.name}</td>
                                            <td>{data.transporterid}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    );
}
