import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import ImportMentor from "./importMentor";
import ImportNetradyne from "./importNetradyne";
import ImportPod from "./importPod";
import ImportCdf from "./importCdf";
import ImportRoster from "./importRoster";
import ImportRosterV2 from "./importRosterV2";
import ImportScorecard from "./importScorecard";
import ImportDevice from "./importDevice";
import ImportVehicle from "./importVehicle";
import ImportDriver from "./importDriver";
import ImportGate from "./importGate";

import MentorConfirm from "./mentorConfirm";
import NetradyneConfirm from "./netradyneConfirm";
import CdfConfirm from "./cdfConfirm";
import PodConfirm from "./podConfirm";
import RosterConfirm from "./rosterConfirm";
import RosterV2Confirm from "./rosterV2Confirm";
import DeviceConfirm from "./deviceConfirm";
import DriverConfirm from "./driverConfirm";
import VehicleConfirm from "./vehicleConfirm";
import GateConfirm from "./gateConfirm";

import { FormattedMessage } from "react-intl";

export default function Import() {

  var pathName = useLocation().pathname;
  const history = useHistory();
  const [fileNameImport, setFileNameImport] = useState();
  const [mentor, setMentor] = useState();
  const [netradyne, setNetradyne] = useState();
  const [roster, setRoster] = useState();
  const [rosterv2, setRosterV2] = useState();
  const [Scorecard, setScorecard] = useState();
  const [pod, setPod] = useState();
  const [cdf, setCdf] = useState();
  const [cdfYear, setCdfYear] = useState();
  const [cdfWeek, setCdfWeek] = useState();
  const [podYear, setPodYear] = useState();
  const [podWeek, setPodWeek] = useState();
  const [device, setDevice] = useState();
  const [vehicle, setVehicle] = useState();
  const [driver, setDriver] = useState();
  const [gate, setGate] = useState();

  useEffect(() => {

  }, []);

  return (
    <Switch>
      <div className="container mt-3">

        {!pathName.includes("/imports/roster/confirm") && !pathName.includes("/imports/rosterv2/confirm") && !pathName.includes("/imports/mentor/confirm") && !pathName.includes("/imports/cdf/confirm")  && !pathName.includes("/imports/pod/confirm")  && !pathName.includes("/imports/netradyne/confirm")  && !pathName.includes("/imports/device/confirm")  && !pathName.includes("/imports/vehicle/confirm")  && !pathName.includes("/imports/driver/confirm") && !pathName.includes("/imports/gate/confirm") && (
          <>
            <div className="row">
              <div className="col-md-12">
                <p className="title">
                  <FormattedMessage id="import" />
                </p>
              </div>
            </div>
            <div className="card mb-5">
              <div className="card-header">
                <FormattedMessage id="daily" />
              </div>
              <div className="card-body">
              <Route
                path="/imports"
                exact
                component={(props) => <ImportRoster setRosterMethod={setRoster} setFileNameRosterImport={setFileNameImport} />}
              />
              <hr />
              <br />
              <Route
                path="/imports"
                exact
                component={(props) => <ImportRosterV2 setRosterV2Method={setRosterV2} setFileNameRosterV2Import={setFileNameImport} />}
              />
              <hr />
              <br />
              <Route
                path="/imports"
                exact
                component={(props) => <ImportMentor setMentorMethod={setMentor} setFileNameMentorImport={setFileNameImport} />}
              />
              <hr />
              <br />
              <Route
                path="/imports"
                exact
                component={(props) => <ImportNetradyne setNetradyneMethod={setNetradyne} setFileNameNetradyneImport={setFileNameImport} />}
              />
              </div>
            </div>
            
            <div className="card mb-5">
              <div className="card-header">
                <FormattedMessage id="weekly" />
              </div>
              <div className="card-body">
              <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportScorecard setScorecardMethod={setScorecard}   setFileNameScorecardImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportCdf setCdfMethod={setCdf} setCdfYearMethod={setCdfYear} setCdfWeekMethod={setCdfWeek} setFileNameCdfImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportPod setPodMethod={setPod} setPodYearMethod={setPodYear} setPodWeekMethod={setPodWeek} setFileNamePodImport={setFileNameImport} />}
                />
              </div>
            </div>

            <div className="card mb-5">
              <div className="card-header">
                <FormattedMessage id="bulky" />
              </div>
              <div className="card-body">
                <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportDevice setDeviceMethod={setDevice}   setFileNameDeviceImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportVehicle setVehicleMethod={setVehicle}   setFileNameVehicleImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportDriver setDriverMethod={setDriver}   setFileNameDriverImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/imports"
                  exact
                  component={(props) => <ImportGate setGateMethod={setGate}   setFileNameGateImport={setFileNameImport} />}
                />
              </div>
            </div>
          </>
        )}

        <Route
          path="/imports/roster/confirm"
          exact
          component={(props) => <RosterConfirm roster={roster} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/rosterv2/confirm"
          exact
          component={(props) => <RosterV2Confirm rosterv2={rosterv2} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/mentor/confirm"
          exact
          component={(props) => <MentorConfirm mentor={mentor} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/netradyne/confirm"
          exact
          component={(props) => <NetradyneConfirm netradyne={netradyne} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/cdf/confirm"
          exact
          component={(props) => <CdfConfirm cdf={cdf} year={cdfYear} week={cdfWeek} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/pod/confirm"
          exact
          component={(props) => <PodConfirm pod={pod} year={podYear} week={podWeek} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/device/confirm"
          exact
          component={(props) => <DeviceConfirm device={device} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/vehicle/confirm"
          exact
          component={(props) => <VehicleConfirm vehicle={vehicle} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/driver/confirm"
          exact
          component={(props) => <DriverConfirm driver={driver} fileNameImport={fileNameImport} />}
        />
        <Route
          path="/imports/gate/confirm"
          exact
          component={(props) => <GateConfirm gate={gate} fileNameImport={fileNameImport} />}
        />

      </div>
    </Switch >
  );
}


