import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faEye,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";

export default function Pod(props) {
  const history = useHistory();
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState([]);

  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(0);
  const [perPage] = useState(30);
  const [pageCount, setPageCount] = useState(0);

  let { id } = useParams();

  useEffect(() => {
    props.clear();
    UpdateList();
  }, [page]);

  function UpdateList() {

    setLoading(true);

    api
      .post("/pod-user/getbyuserid/" + id,
        {
          "page": page,
          "rows": perPage
        },
        { headers: { "x-access-token": GetToken() } },
      )
      .then((data) => {
        setFeedbacks(data.data.poduser);
        setPageCount(Math.ceil(data.data.rows / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    api
      .get("driver/getbyid/" + id, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        setDriver(data.data[0]);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });

  }


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setSelected(selectedPage);
    setPage(selectedPage + 1);
  };


  function deleteIssueById(id) {
    let newFeedbacks = feedbacks;
    newFeedbacks = newFeedbacks.filter(x => x.id != id);
    setFeedbacks(newFeedbacks);
  }


  function DeleteCounseling(id) {

    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("pod-user/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "POD deleted",
            }).then(() => {
              //UpdateList();
              deleteIssueById(id);
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });

  }

  return (
    <div>
      <Loading visible={loading} />
      <div className="row mb-3">
        <div className="col-12">
          <h5 className="float-start" >
            <FormattedMessage id="tabela.driver" />: {driver.fname} {driver.lname}
          </h5>
          <a className="btn btn-orange float-end m-0"
            onClick={() => history.push("/drivers/edit/" + id)}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" className="btn-icon mr-4" />
            <FormattedMessage id="return" />
          </a>
        </div>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="form.year" />
              </th>
              <th scope="col">
                <FormattedMessage id="week" />
              </th>
              <th scope="col">
                <FormattedMessage id="opport" />
              </th>
              <th scope="col">
                <FormattedMessage id="success" />
              </th>
              <th scope="col">
                <FormattedMessage id="bypass" />
              </th>
              <th scope="col">
                <FormattedMessage id="rejects" />
              </th>
              <th scope="col">
                <FormattedMessage id="blurry" />
              </th>
              <th scope="col">
                <FormattedMessage id="human" />
              </th>
              <th scope="col">
                <FormattedMessage id="nopack" />
              </th>
              <th scope="col">
                <FormattedMessage id="incar" />
              </th>
              <th scope="col">
                <FormattedMessage id="inhand" />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {feedbacks.map((item) => {
              return (
                <tr>
                  <td>{item.year}</td>
                  <td>{item.week}</td>
                  <td>{item.opport}</td>
                  <td>{item.success}</td>
                  <td>{item.bypass}</td>
                  <td>{item.rejects}</td>
                  <td>{item.blurry}</td>
                  <td>{item.human}</td>
                  <td>{item.nopack}</td>
                  <td>{item.incar}</td>
                  <td>{item.inhand}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history.push("/drivers/pod/view/" + item.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteCounseling(item.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div >
  );
}
