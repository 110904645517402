import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../../api/axios";

import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddEdit() {
    const history = useHistory();
    let { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    function onSubmit(data) {
        console.log("submit", data);
        if (id) {
            //edit
            api
                .put(
                    "param-carrier/update/" + id,
                    {
                        name: data.Name,
                    },
                    { headers: { "x-access-token": GetToken() } }
                )
                .then((data) => {
                    history.push("/params/carrier");

                    //Swal.fire({
                    //    icon: 'success',
                    //    title: 'Success',
                    //}).then(() => {
                    //});
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err.response.data.message,
                    });
                });
        } else {
            //create

            api
                .post(
                    "param-carrier/create",
                    {
                        name: data.Name,
                    },
                    { headers: { "x-access-token": GetToken() } }
                )
                .then((data) => {
                    history.push("/params/carrier");

                    //Swal.fire({
                    //    icon: 'success',
                    //     title: 'Success',
                    //}).then(() => {
                    //     history.push("/params/carrier");
                    // });
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err.response.data.message,
                    });
                })
        }
    }

    useEffect(() => {
        if (id) {
            api
                .get("param-carrier/getbyid/" + id, {
                    headers: { "x-access-token": GetToken() },
                })
                .then((data) => {
                    const value = data.data[0];

                    console.log(value);

                    setValue("Name", value.name);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    });

    return (
        <div className="row">
            <div className="col-md-6 col-12">
                <form onSubmit={handleSubmit(onSubmit)} className="form-content">
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <FormattedMessage id="form.name" />
                            <input
                                {...register("Name", { required: true, min: 3 })}
                                className="form-control"
                            />
                            {errors.Name && (
                                <span className="required"><FormattedMessage id="form.required" /></span>
                            )}
                        </div>
                    </div>

                    <div className="d-flex flex-row-reverse bd-highlight">
                        <FormattedMessage id="edit_save">
                            {(edit_save) => {
                                return (
                                    <button type="submit"
                                        className="btn btn-orange btn-confirm float-end"
                                    >
                                        <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                                        {id ? edit_save.edit : edit_save.save}
                                    </button>
                                );
                            }}
                        </FormattedMessage>
                        <button
                            className="btn btn-secondary btn-confirm"
                            onClick={() => history.push("/params/carrier")}
                        >
                            <FormattedMessage id="cancel" />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
