import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";

import Header from "./components/Header";
import LeftMenu from "./components/LeftMenu";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users/index";
import Vehicles from "./pages/vehicles/index";
import Drivers from "./pages/drivers/index";
import Devices from "./pages/devices/index";
import Routines from "./pages/roster/index";
import ForgotPassword from "./pages/forgot_password";
import Define from "./pages/define";
import Carrier from "./pages/params/carrier/index";
import Company from "./pages/params/company";
import Scorecard from "./pages/scorecard/index";
import DriverStatus from "./pages/params/driverstatus";
import Ownership from "./pages/params/ownership";
import VehicleStatus from "./pages/params/vehiclestatus";
import Vendor from "./pages/params/vendor/index";
import Roster from "./pages/params/roasterstatus";
import Country from "./pages/params/country";
import State from "./pages/params/state";
import Gates from "./pages/gates/index";
import Ranking from "./pages/coaching/ranking/index";
import Messages from "./pages/coaching/messages/index";
import Import from "./pages/coaching/import/index";
import ParamsIssue from "./pages/params/issue/index";
import ParamsCounseling from "./pages/params/counseling/index";
import Issue from "./pages/issue/index";
import Counseling from "./pages/counseling/index";
import WhiteUp from "./pages/coaching/writeup/index";
import Imports from "./pages/imports/index";
import ChangePassword from "./pages/change-password/index";
import CompanySettings from "./pages/company-settings/index";

export default function Routes() {
  return (
    <BrowserRouter>
      <Header />

      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/forgot_password" exact component={ForgotPassword} />
        <Route path="/define/:id" exact component={Define} />
      </Switch>
      <div className="container-fluid"  >
        <div className="row main "  >
          <LeftMenu />
          <div className="col-md-10">
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/imports" component={Imports} />
              <Route path="/users" component={Users} />
              <Route path="/vehicles" component={Vehicles} />
              <Route path="/drivers" component={Drivers} />
              <Route path="/devices" component={Devices} />
              <Route path="/roster" component={Routines} />
              <Route path="/scorecard" component={Scorecard} />
              <Route path="/gates" component={Gates} />
              {/* Counseling */}
              <Route path="/counseling/list" component={Counseling} />
              <Route path="/counseling/issue" component={Issue} />
              {/* Coaching */}
              <Route path="/coaching/ranking" component={Ranking} />
              <Route path="/coaching/messages" component={Messages} />
              <Route path="/coaching/import" component={Import} />
              <Route path="/coaching/write-up" component={WhiteUp} />
              {/* Params */}
              <Route path='/params/carrier' component={Carrier} />
              <Route path='/params/company' component={Company} />
              <Route path='/params/driver-status' component={DriverStatus} />
              <Route path='/params/ownership' component={Ownership} />
              <Route path='/params/vehicle-status' component={VehicleStatus} />
              <Route path='/params/roster-status' component={Roster} />
              <Route path='/params/scorecardstatus' component={Scorecard} />
              <Route path='/params/country' component={Country} />
              <Route path='/params/state' component={State} />
              <Route path='/params/issue' component={ParamsIssue} />
              <Route path='/params/counseling' component={ParamsCounseling} />
              <Route path='/params/vendor' component={Vendor} />
              <Route path='/change-password' component={ChangePassword} />
              <Route path='/company-settings' component={CompanySettings} />
              
            </Switch>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
