import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Modal from 'react-modal';
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Swal from 'sweetalert2';
import Loading from "../../components/Loading";


export default function Import(props) {
    const history = useHistory();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [uploadObject, setUploadObject] = useState([]);
    const [uploadErrors, setUploadErrors] = useState([]);

    const [loading, setLoading] = useState();
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState('');

    function onFileChange(e) {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function Cancel() {
        setIsOpen(false);
        history.push('/scorecard');
    }

    function SaveForce() {
        if (uploadObject === undefined) return;
        let dataUpload = uploadObject;
        setLoading(true);

        api
            .post("scorecard/save", dataUpload, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.data.message,
                }).then(_ => {
                    history.push('/scorecard')
                })

            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.message,
                });
            }).finally(_ => {
                setLoading(false);
            })

    }

    function importFile() {
        if (file === "") {
            alert("No file uploaded");
        }

        const data = new FormData();
        data.append("filetoupload", file);
        setLoading(true);

        api.post("upload/score", data, {
                headers: {
                    "x-access-token": GetToken(),
                    "Content-Type": "multipart/form-data;",
                },
            })
            .then((data) => {
                setLoading(false);
                if (data.data.errno === 1062) {
                    delete data.data.res;
                    delete data.data.errno;
                    delete data.data.message;
                    setUploadObject(data.data)
                    setIsOpen(true);
                }
                else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.data.message,
                    }).then(_ => {
                        history.push('/scorecard')
                    })
                }
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.message,
                });
            })
            .finally(() => {
                setLoading(false);
                console.log("end request");
            });
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div >
            <Loading visible={loading} />
            <Modal
                isOpen={modalIsOpen}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <h4 style={{ textAlign: 'center' }}><FormattedMessage id="txt_some_data_conflicts_import" /></h4>
                <div style={{ width: '100%', fontSize: 12, color: '#333', textAlign: 'center', margin: 10 }}>
                    <FormattedHTMLMessage id="txt_data_conflicts_import" />
                </div>
                <div className='row'>
                    <div className='col-2'>
                    </div>
                    <div className='col-4'>
                        <button className="btn btn-orange btn100" style={{ backgroundColor: "#FF2626" }} onClick={() => Cancel()}><FormattedMessage id="not_overwrite" /></button>
                    </div>
                    <div className='col-4'>
                        <button className="btn btn-orange btn100" style={{ backgroundColor: "#059C66" }} onClick={() => SaveForce()}><FormattedMessage id="overwrite" /></button>
                    </div>
                    <div className='col-2'>
                    </div>
                </div>
            </Modal>
            <div
                className="form-content"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >

                <label htmlFor="upload-photo" id="label-upload-photo">
                    {fileName !== '' ? fileName : <FormattedMessage id="browse_file" />}
                </label>
                <input type="file" name="filetoupload" id="upload-photo" accept="application/pdf" onChange={onFileChange} />
                <br />
                <FormattedMessage id="import_file">
                    {(placeholder) => {
                        return (
                            <input
                                type="button"
                                className="btn btn-orange"
                                onClick={importFile}
                                style={{ backgroundColor: "blue" }}
                                value={placeholder}
                                disabled={fileName === ''}
                            />
                        );
                    }}
                </FormattedMessage>
            </div>
        </div>
    );
}
