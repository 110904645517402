import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import List from "./list";


export default function Ranking() {
  var pathName = useLocation().pathname;

  const history = useHistory();
  const [roster, setRoster] = useState();
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [lastOrder, setLastOrder] = useState('asc');
  const [lastField, setLastField] = useState('id');

  useEffect(() => {

  }, []);

  return (
    <Switch>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-12">
            <p className="title">
              Ranking
            </p>
          </div>
        </div>
        <List setLastOrder={setLastOrder} setLastField={setLastField} />
      </div>
    </Switch >
  );
}


