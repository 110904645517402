import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import List from "./list";
import AddEdit from "./addEdit";
import Scores from "./scores";
import Photo from "./photo";
import Mentor from "./mentor";
import Netradyne from "./netradyne";
import Cdf from "./cdf";
import CdfEdit from "./cdfEdit";
import Pod from "./pod";
import PodEdit from "./podEdit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

export default function Drivers() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  var pathName = useLocation().pathname;

  function CreateUser() {
    history.push("/drivers/add");
  }

  useEffect(() => {
    document.title = "LoadOut - DAs";
  });

  return (
    <Switch>
      <div className="container mt-3" style={{ marginBottom: 200 }}>
        <div className="row mb-3">
          <div className="col-12 col-md-6">

            {!pathName.includes('cdf') && !pathName.includes('pod') &&
              <p className="title">
                DAs 
              </p>
            }
            {pathName.includes('cdf') &&
              <p className="title">
                Customer Delivery Feedback
              </p>
            }
            {pathName.includes('pod') &&
              <p className="title">
                Photo on Delivery
              </p>
            }
          </div>
          {(!pathName.includes('add') && !pathName.includes('edit') && !pathName.includes('scores') && !pathName.includes('netradyne') && !pathName.includes('mentor') && !pathName.includes('cdf') && !pathName.includes('pod') && !pathName.includes('photo')) && <div className="col-md-6 row">
            <div className="col-6 col-md-8">
              <FormattedMessage id="form.search">
                {(placeholder) => {
                  return (
                    <input
                      className="form-control"
                      placeholder={placeholder}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  );
                }}
              </FormattedMessage>
            </div>
            <div className="col-6 col-md-4">
              <button className="btn btn-orange btn100" onClick={CreateUser}>
                <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                <FormattedMessage id="form.create" />
              </button>
            </div>
          </div>}

        </div>
        <Route
          exact
          path="/drivers"
          component={(props) => <List searchTerm={search} />}
        />
        <Route path="/drivers/add" render={() => <AddEdit clear={() => setSearch('')} />} />
        <Route path="/drivers/edit/:id" render={() => <AddEdit clear={() => setSearch('')} />} />
        <Route path="/drivers/photo/:id" render={() => <Photo clear={() => setSearch('')} />} />
        <Route path="/drivers/scores/:id" render={() => <Scores clear={() => setSearch('')} />} />
        <Route path="/drivers/mentor/:id" render={() => <Mentor clear={() => setSearch('')} />} />
        <Route path="/drivers/netradyne/:id" render={() => <Netradyne clear={() => setSearch('')} />} />
        <Route path="/drivers/cdf/list/:id" render={() => <Cdf clear={() => setSearch('')} />} />
        <Route path="/drivers/cdf/view/:id" render={() => <CdfEdit clear={() => setSearch('')} />} />

        <Route path="/drivers/pod/list/:id" render={() => <Pod clear={() => setSearch('')} />} />
        <Route path="/drivers/pod/view/:id" render={() => <PodEdit clear={() => setSearch('')} />} />

      </div>
    </Switch>
  );
}
