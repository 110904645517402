import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export default function AddEdit({ clear, mode = "driver", setModal = null, startData = {} }) {
  const history = useHistory();
  let { id } = useParams();
  const isEdit = id != null;

  const [stateParam, setStateParam] = useState([]);
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [hourly, setHourly] = useState([]);
  const [gender, setGender] = useState([]);
  const [pronoun, setPronoun] = useState([]);
  const [phoneMask, setPhoneMask] = useState("");
  const [vehicles, setVehicles] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  function getNotify(app, sms, email) {
    const notify =
      "[" +
      app +
      needsComma("", app, sms) +
      sms +
      needsComma(app, sms, email) +
      email +
      "]";

    return notify === "[]" ? null : notify;
  }



  function needsComma(prev, value, next) {
    if (prev === "" && value === "" && next === "") return "";
    if (value === "" && prev !== "" && next !== "") return ",";
    if (value !== "" && next === "") return "";
    if (value === "") return "";
    return ",";
  }

  function onSubmit(data) {
    console.log(phone);

    let phoneReplace = "";

    if (phone !== null && phone !== "") {
      phoneReplace = phone
        .replace("+", "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");

      if (isNaN(phoneReplace)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid phone",
        });
        return;
      }
    }

    const appNot = data.notificationApp ? "0" : "";
    const smsNot = data.notificationSMS ? "1" : "";
    const emailNot = data.notificationEmail ? "2" : "";

    const notify = getNotify(appNot, smsNot, emailNot);

    if (id) {
      //edit
      api
        .put(
          "driver/update/" + id,
          {
            statusid: data.statusid == "" ? "0" : data.statusid,
            transporterid: data.transporterID,
            genderid: data.genderid == "" ? "0" : data.genderid,
            hourlyid: data.hourlyid == "" ? "0" : data.hourlyid,
            pronounid: data.pronounid == "" ? "0" : data.pronounid,
            vehicleid: data.vehicleid == "" ? "0" : data.vehicleid,
            vehicle2id: data.vehicle2id == "" ? "0" : data.vehicle2id,
            gascardpin: data.gascardpin,
            final: data.final == '' ? null : data.final,
            dob: data.dob == '' ? null : data.dob,
            hired: data.hired == '' ? null : data.hired,
            fired: data.fired == '' ? null : data.fired,
            dlexpiration: data.dlexpiration == '' ? null : data.dlexpiration,
            cdv: data.cdv == "" ? "0" : data.cdv,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          if (mode === "driver") {
            history.push("/drivers");
          } else {
            console.log(setModal)
            setModal(false);
          }
          //});
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response?.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "driver/create",
          {
            fname: data.fName,
            lname: data.lName,
            email: data.email,
            phone: phoneReplace,
            statusid: data.statusid,
            notify: notify,
            transporterid: data.transporterID,
            genderid: data.genderid,
            hourlyid: data.hourlyid,
            pronounid: data.pronounid,
            vehicleid: data.vehicleid,
            vehicle2id: data.vehicle2id,
            gascardpin: data.gascardpin,
            final: data.final == '' ? null : data.final,
            dob: data.dob == '' ? null : data.dob,
            hired: data.hired == '' ? null : data.hired,
            fired: data.fired == '' ? null : data.fired,
            dlexpiration: data.dlexpiration == '' ? null : data.dlexpiration,
            cdv: data.cdv
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          if (mode === "driver") {
            history.push("/drivers");
          }
          else {
            console.log(setModal)
            setModal(false);
          }
          //});
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  }

  function handleChangeStatus(e) {
    if (e == 5) {
      if (getValues('fired') == '0000-00-00') {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        setValue("fired", today);
      }

    }
  }

  useEffect(() => {
    if (clear) {
      clear();
    }
    api
      .get("driver/preload", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        setStateParam(data.data.status);
        setHourly(data.data.hourly);
        setGender(data.data.gender);
        setPronoun(data.data.pronoun);
        setVehicles(data.data.vehicle);

        api
          .get("param-country/getall", {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            const currentLang = localStorage.getItem("language");
            const lang = data.data.find((x) => x.code === currentLang)?.phonemask;
            setPhoneMask(lang);

            if (id) {
              api
                .get("driver/getbyid/" + id, {
                  headers: { "x-access-token": GetToken() },
                })
                .then((data) => {
                  const value = data.data[0];
                  setValue("fName", value.fname);
                  setValue("lName", value.lname);
                  setPhone(value.phone);
                  setImage(value.image);
                  setValue("email", value.email);
                  setValue("statusid", value.statusid);
                  setValue("transporterID", value.transporterid);
                  setValue("hourlyid", value.hourlyid);
                  setValue("genderid", value.genderid);
                  setValue("pronounid", value.pronounid);
                  setValue("vehicleid", value.vehicleid);
                  setValue("vehicle2id", value.vehicle2id);
                  setValue("gascardpin", value.gascardpin);
                  setValue("hired", value.hired);
                  setValue("dob", value.dob);
                  setValue("final", value.final);
                  setValue("dlexpiration", value.dlexpiration);
                  setValue("cdv", value.cdv);
                  setValue("fired", value.fired);

                })
                .catch((err) => {
                  console.log(err.response);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.response?.data.message,
                  });
                });
            }

            if (startData && Object.keys(startData).length !== 0) {
              console.log('start data', startData);
              setValue("transporterID", startData.transporterID);
              setValue("fName", startData.fname);
              setValue("lName", startData.lname);
            }
          });
      });
  }, [id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-content">
      {image ? (
        <div className="row">
          <div className="col-md-2">
            <img src={image} class="img-thumbnail" />
          </div>
        </div>
      ) : null}
      <div className="row">
        {id ? (
          <div className="col-md-4 mt-3">
            <FormattedMessage id="form.photo" /><br />
            <a onClick={() => history.push("/drivers/photo/" + id)} className="btn btn-primary">
              <FormattedMessage id="edit_photo" />
            </a>
          </div>
        ) : null}
        <div className={id ? 'col-md-4 mt-3' : 'col-md-6 mt-3'}>
          <FormattedMessage id="form.first_name" />{" "}
          {id ? (
            <div style={{ display: "inline" }}>
              {" "}
              - <a href={"/users/edit/" + id}><FormattedMessage id="form.edit_user" /></a>
            </div>
          ) : null}
          <input
            {...register("fName", { required: true, min: 1 })}
            className="form-control"
            disabled={isEdit}
          />
        </div>
        <div className={id ? 'col-md-4 mt-3' : 'col-md-6 mt-3'}>

          <FormattedMessage id="form.last_name" />
          <input
            {...register("lName", { required: true, min: 1 })}
            className="form-control"
            disabled={isEdit}
          />
          {errors.lName && (
            <span className="required">
              <FormattedMessage id="form.required" />
            </span>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.phone" />
          <ReactInputMask
            mask={phoneMask}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            className="form-control"
            disabled={isEdit}
          />
          {errors.phone && (
            <span className="required">
              <FormattedMessage id="form.validphone" />
            </span>
          )}
        </div>
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.email" />
          <input
            {...register("email", {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            })}
            className="form-control"
            disabled={isEdit}
          />
          {errors.email && (
            <span className="required">
              <FormattedMessage id="form.validemail" />
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.transporter_id" />
          <input
            {...register("transporterID", {
              required: false,
            })}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.dl_expiration" />
          <input
            {...register("dlexpiration", {
              required: false,
            })}
            type="date"
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.status" />

          <select {...register("statusid", { required: false })} onChange={(e) => handleChangeStatus(e.target.value)} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {stateParam.map((param) => (
              <option value={param.id}>{param.name}</option>
            ))}
          </select>

        </div>

        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.work_time" />
          <select {...register("hourlyid")} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {hourly?.map((param) => (
              <option value={param.id}>{param.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.gender" />
          <select {...register("genderid")} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {gender?.map((param) => (
              <option value={param.id}>{param.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.pronoun" />
          <select {...register("pronounid")} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {pronoun?.map((param) => (
              <option value={param.id}>{param.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.date_of_birth" />
          <input
            {...register("dob", {
              required: false,
            })}
            type="date"
            className="form-control"
          />
        </div>
        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.final" />
          <input
            {...register("final", {
              required: false,
            })}
            type="date"
            className="form-control"
          />
        </div>

        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.gas_card_pin" />
          <input
            {...register("gascardpin", {
              required: false,
            })}
            className="form-control"
          />
        </div>

        <div className="col-md-3 mt-3">
          CDV
          <select {...register("cdv", { required: false })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                  return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </select>
        </div>
      </div>

      <div className="row">

        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.vehicle" />
          <select {...register("vehicleid", { required: false })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {vehicles?.map((param) => (
              <option value={param.id}>{param.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.vehicle" /> 2
          <select {...register("vehicle2id", { required: false })} className="form-select">
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {vehicles?.map((param) => (
              <option value={param.id}>{param.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-3 mt-3">
          <FormattedMessage id="form.hired" />
          <input
            {...register("hired", {
              required: false,
            })}
            type="date"
            className="form-control"
          />
        </div>

        <div className="col-md-3 mt-3">
          <FormattedMessage id="terminated" />
          <input
            {...register("fired", {
              required: false,
            })}
            type="date"
            className="form-control"
          />
        </div>

      </div>

      <div className="row bd-highlight">

        <div className="col-12 col-md-7">
          {id ? (
            <a onClick={() => history.push("/drivers/scores/" + id)} className="btn btn-secondary btn-confirm">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                color={"#DBDBDB"}
                size="1x"
                style={{ marginRight: "10px" }}
              />
              <FormattedMessage id="view_scorecard" />
            </a>
          ) : null}

          {id ? (
            <a onClick={() => history.push("/drivers/mentor/" + id)} className="btn btn-secondary btn-confirm">
              <FontAwesomeIcon
                icon={faShieldAlt}
                color={"#DBDBDB"}
                size="1x"
                style={{ marginRight: "10px" }}
              />
              <FormattedMessage id="view_mentor" />
            </a>
          ) : null}


          {id ? (
            <a onClick={() => history.push("/drivers/netradyne/" + id)} className="btn btn-secondary btn-confirm">
              <FontAwesomeIcon
                icon={faShieldAlt}
                color={"#DBDBDB"}
                size="1x"
                style={{ marginRight: "10px" }}
              />
              <FormattedMessage id="view_netradyne" />
            </a>
          ) : null}


          {id ? (
            <a onClick={() => history.push("/drivers/cdf/list/" + id)} className="btn btn-secondary btn-confirm">
              <FontAwesomeIcon
                icon={faShieldAlt}
                color={"#DBDBDB"}
                size="1x"
                style={{ marginRight: "10px" }}
              />
              CDF
            </a>
          ) : null}


          {id ? (

            <a onClick={() => history.push("/drivers/pod/list/" + id)} className="btn btn-secondary btn-confirm">
              <FontAwesomeIcon
                icon={faShieldAlt}
                color={"#DBDBDB"}
                size="1x"
                style={{ marginRight: "10px" }}
              />
              POD
            </a>
          ) : null}

        </div>

        <div className="col-12 col-md-5">

          <FormattedMessage id="edit_save">
            {(edit_save) => {
              return (
                <button type="submit"
                  className="btn btn-orange btn-confirm float-end"
                >
                  <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                  {id ? edit_save.edit : edit_save.save}
                </button>
              );
            }}
          </FormattedMessage>

          <button
            className="btn btn-secondary btn-confirm float-end"
            onClick={() => {
              if (mode === "driver") {
                history.push("/drivers");
              }
              else {
                console.log(setModal)
                setModal(false);
              }
            }}
          >
            <FormattedMessage id="cancel" />
          </button>

        </div>


      </div>
    </form>
  );
}

function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;

  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
