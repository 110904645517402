import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import List from "./list";
import AddEdit from "./addEdit";

export default function Issue() {

  useEffect(() => {
    document.title = "LoadOut - Issue";
  });

  return (
    <Switch>
      <div className="container mt-3">
        <div className="row">
          <Route
            exact
            path="/counseling/issue"
            component={(props) => <List />}
          />
          <Route path="/counseling/issue/add" component={AddEdit} />
          <Route path="/counseling/issue/edit/:id" component={AddEdit} />
        </div>
      </div>
    </Switch>
  );

}
