import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Issue from "./issue/index";
import Counseling from "./counseling/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../components/Loading";

export default function WhiteUp() {
  var pathName = useLocation().pathname;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <Switch>
      <div className="container mt-3">
        <div className="mt-1">
          <Issue />
        </div>
        <div className="mt-5">
          <Counseling />
        </div>
      </div>
    </Switch >
  );
}


