import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import { FormattedMessage } from "react-intl";

export default function Historic(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [historic, setHistoric] = useState([]);
  const [selected, setSelected] = useState(0);
  const [timeMask, setTimeMask] = useState();
  const [name, setName] = useState();

  let { id } = useParams();
  const isEdit = id != null;

  useEffect(() => {
    props.clear();
    UpdateList();
  }, [offset]);

  function UpdateList() {
    setLoading(true);
    api
      .get("vehicle/historic/" + id, {
        headers: { "x-access-token": GetToken() }
      })
      .then((data) => {
        const slice = data.data.slice(offset, offset + perPage);
        setHistoric(slice);
        setPageCount(Math.ceil(data.data.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const currentLang = localStorage.getItem("language");
        const lang = data.data.find((x) => x.code === currentLang)?.timemask;
        setTimeMask(lang);
      });

    api
      .get("vehicle/getbyid/" + id, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const value = data.data[0];
        setName(value.name);
      })


  }


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };


  function tConvert(time) {

    if (timeMask !== "12H") {
      return time;
    }
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-12 col-md-12">
          <p className="title mb-0 pb-0">
          <FormattedMessage id="historic" />
          </p>
          <h4>
          <FormattedMessage id="tabela.vehicle" />: 
          <a onClick={() => history.push("/vehicles/edit/" + id)} style={{ 'marginLeft': '15px', 'cursor': 'pointer', 'textDecoration': 'underline', 'color': "#0d6efd" }}>
            {name}
          </a>
          </h4>
        </div>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="tabela.date" /> </th>
              <th scope="col"><FormattedMessage id="tabela.time" /></th>
              <th scope="col"><FormattedMessage id="tabela.name" /></th>
            </tr>
          </thead>
          <tbody>
            {historic.map((item) => {
              return (
                <tr>
                  <td>{item.date}</td>
                  <td>{tConvert(item.time.split(":")[0] + ':' + item.time.split(":")[1])}</td>
                  <td><a href={"/drivers/edit/" + item.driverid}>{item.name}</a></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );
}
