import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faTrash,
  faEdit,

} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import Modal from 'react-modal';

import AddEditScore from '../drivers/addEditScore'

import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";

export default function Scores(props) {
  const history = useHistory();
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [preload, setPreload] = useState([]);
  const [update, setUpdate] = useState(false);
  const [scoreId, setScoreId] = useState(0)
  const [visibleScoreModal, setVisibleScoreModal] = useState(false);
  const [driver, setDriver] = useState([]);

  function handleVisibleModal(value) {
    setVisibleScoreModal(value);
  }

  let { id } = useParams();
  const isEdit = id != null;

  function deleteScoreById(id) {
    let newScores = scores;
    newScores = newScores.filter(scores => scores.id != id);
    setScores(newScores);
    setUpdate(!update);
  }

  function EditDriverScore(id) {
    setScoreId(id);
    setVisibleScoreModal(true);
  }

  useEffect(() => {
    props.clear();
    UpdateList();
  }, [offset]);

  function UpdateList() {
    setLoading(true);
    api
      .get("score-user/getbyuserid/" + id, {
        headers: { "x-access-token": GetToken() }
      })
      .then((data) => {
        const slice = data.data.slice(offset, offset + perPage);
        setScores(slice);
        setPageCount(Math.ceil(data.data.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    api
      .get("driver/getbyid/" + id, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        setDriver(data.data[0]);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });
  }

  function DeleteDriverScore(id) {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("score-user/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Score deleted",
            }).then(() => {
              //UpdateList();
              deleteScoreById(id)
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
  };

  return (
    <div>
      <Modal isOpen={visibleScoreModal} >
        <AddEditScore mode='score' setModal={handleVisibleModal} setUpdateList={UpdateList} scoreId={scoreId} />
      </Modal>
      <Loading visible={loading} />
      <div className="row mb-3">
        <div className="col-12">
          <h5 className="float-start" >
            Driver Scorecard: {driver.fname} {driver.lname}
          </h5>
          <a className="btn btn-orange float-end m-0"
            onClick={() => history.push("/drivers/edit/" + id)}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" className="btn-icon mr-4" />
            <FormattedMessage id="return" />
          </a>
        </div>
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="week" />
              </th>
              <th scope="col">
                DAR
              </th>
              <th scope="col">
                <FormattedMessage id="delivered" />
              </th>
              <th scope="col">
                Focus
              </th>
              <th scope="col">
                SWC-POD
              </th>
              <th scope="col">
                SWC-SC
              </th>
              <th scope="col">
                DCR
              </th>
              <th scope="col">
                DNRS
              </th>
              <th scope="col">
                SWC-CC
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score) => {
              return (
                <tr>
                  <td>{score.year} - {score.week}</td>
                  <td>{score.dar}</td>
                  <td>{score.delivered}</td>
                  <td>{score.focus}</td>
                  <td>{score.swcpod}</td>
                  <td>{score.swcsc}</td>
                  <td>{score.dcr}</td>
                  <td>{score.dnrs}</td>
                  <td>{score.swccc}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => EditDriverScore(score.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteDriverScore(score.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}
