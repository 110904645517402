import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane, faSort, faTrashAlt,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faEdit,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { id } from "date-fns/locale";
import ReactPaginate from "react-paginate";
import Loading from "../../../components/Loading";

export default function List({ setLastField, setLastOrder }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [scorecards, setScoreCard] = useState([]);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    UpdateList();
    setLoading(false);

  }, []);

  function UpdateList() {
    setLoading(true);
  }


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  return (
    <div>
      <Loading visible={loading} />
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col" >
                <FormattedMessage id="tabela.driver" />
              </th>
              <th scope="col">
                <FormattedMessage id="problems" />
              </th>
              <th scope="col">
                <FormattedMessage id="compliments" />
              </th>
              <th scope="col">
                <FormattedMessage id="punctuation" />
              </th>
              <th scope="col">
                <FormattedMessage id="classification" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={selected}
        />
      </div>
    </div>
  );
}
