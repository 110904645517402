import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import ReactPaginate from "react-paginate";
import Modal from 'react-modal';
import { FormattedMessage } from "react-intl";

export default function Mentor(props) {
  const history = useHistory();
  const [mentors, setMentors] = useState([]);
  const [mentor, setMentor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [driver, setDriver] = useState([]);
  const [visibleScoreModal, setVisibleScoreModal] = useState(false);

  let { id } = useParams();
  const isEdit = id != null;

  function handleVisibleModal(value) {
    setVisibleScoreModal(value);
  }

  useEffect(() => {
    props.clear();
    UpdateList();
  }, [offset]);

  function UpdateList() {

    setLoading(true);

    api
      .get("mentor/getbyuserid/" + id, {
        headers: { "x-access-token": GetToken() }
      })
      .then((data) => {
        const slice = data.data.slice(offset, offset + perPage);
        setMentors(slice);
        setPageCount(Math.ceil(data.data.length / perPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    api
      .get("driver/getbyid/" + id, {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        console.log(data);
        setDriver(data.data[0]);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response?.data.message,
        });
      });
  }

  function OpenMentor(id) {
    let mentor = mentors.filter(el => el.id === id);
    setMentor(mentor[0]);
    setVisibleScoreModal(true);
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
  };

  return (
    <div>
      <Modal isOpen={visibleScoreModal} >
        <div className="row">
          <div className="col-md-12 mt-3">
            <h5><FormattedMessage id="tabela.driver" /> Mentor: {driver.fname} {driver.lname}</h5>
          </div>
          <div className="col-md-3 mt-3">
            <p>Accel: {mentor.accel}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Backup: {mentor.backup}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Braking: {mentor.braking}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Cornering: {mentor.cornering}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Daissue: {mentor.daissue}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Dakudo: {mentor.dakudo}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Distraction: {mentor.distraction}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Engineoff: {mentor.engineoff}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Fico: {mentor.fico}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Idling: {mentor.idling}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Miles: {mentor.miles}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Mpg: {mentor.mpg}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Postdvcr: {mentor.postdvcr}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Predvcr: {mentor.predvcr}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Seatbelt: {mentor.seatbelt}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Speeding: {mentor.speeding}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Sse: {mentor.sse}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Tassigned: {mentor.tassigned}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Tcompleted: {mentor.tcompleted}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Tripdate: {mentor.tripdate}</p>
          </div>
          <div className="col-md-3 mt-3">
            <p>Triptime: {mentor.triptime}</p>
          </div>
        </div>
        <button
          className="btn btn-secondary btn-confirm float-end"
          onClick={() => {
            setVisibleScoreModal(false);
          }}
        >
          <FormattedMessage id="close" />
        </button>
      </Modal>
      <Loading visible={loading} />
      <div className="row mb-3">
        <div className="col-12">
          <h5 className="float-start" >
            Mentor: {driver.fname} {driver.lname}
          </h5>
          <a className="btn btn-orange float-end m-0"
            onClick={() => history.push("/drivers/edit/" + id)}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" className="btn-icon mr-4" />
            <FormattedMessage id="return" />
          </a>
        </div>
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                Tripdate
              </th>
              <th scope="col">
                Miles
              </th>
              <th scope="col">
                Triptime
              </th>
              <th scope="col">
                Fico
              </th>
              <th scope="col">
                Accel
              </th>
              <th scope="col">
                Braking
              </th>
              <th scope="col">
                Cornering
              </th>
              <th scope="col">
                Speeding
              </th>
              <th scope="col">Distraction</th>
              <th scope="col">Seatbelt</th>
              <th scope="col">Backup</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {mentors.map((item) => {
              return (
                <tr>
                  <td>{item.tripdate}</td>
                  <td>{item.miles}</td>
                  <td>{item.triptime}</td>
                  <td>{item.fico}</td>
                  <td>{item.accel}</td>
                  <td>{item.braking}</td>
                  <td>{item.cornering}</td>
                  <td>{item.speeding}</td>
                  <td>{item.distraction}</td>
                  <td>{item.seatbelt}</td>
                  <td>{item.backup}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => OpenMentor(item.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div >
  );
}
