import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import {
    faTruck,
} from "@fortawesome/free-solid-svg-icons";
import api, { GetToken } from "../../api/axios";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import Modal from 'react-modal';
import Swal from 'sweetalert2'

import AddEditDrivers from '../drivers/addEdit'
import moment from "moment";

export default function RosterConfirm({ roster }) {
    const history = useHistory();
    const [drivers, setDrivers] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [uploadData, setUploadData] = useState();
    const [datesToUpload, setDatestoUpload] = useState([]);
    const [checkAll, setCheckAll] = useState(true);
    const [uploadObject, setUploadObject] = useState([]);
    const [loading, setLoading] = useState();
    const [uploadErrors, setUploadErrors] = useState([]);
    const [visibleDriverModal, setVisibleDriverModal] = useState(false);
    const [teste, setTeste] = useState(true);
    const [startDriverData, setStartDriverData] = useState({})


    function handleVisibleModal(value) {
        setVisibleDriverModal(value);
        api
            .get("driver/getall", { headers: { "x-access-token": GetToken() } })
            .then((data) => {
                setDrivers(data.data);
            });
    }

    useEffect(() => {
        if (roster === undefined) {
            history.push('/roster');
            return;
        }

        console.log(roster);

        api
            .get("driver/getall", { headers: { "x-access-token": GetToken() } })
            .then((driversReturn) => {
                setDrivers(driversReturn.data);

                const formObject = {
                    roster: []
                }

                let datesToUpload_object = {
                    C: {
                        active: moment(roster.dates.C).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.C
                    },
                    D: {
                        active: moment(roster.dates.D).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.D
                    },
                    E: {
                        active: moment(roster.dates.E).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.E
                    },
                    F: {
                        active: moment(roster.dates.F).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.F
                    },
                    G: {
                        active: moment(roster.dates.G).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.G
                    },
                    H: {
                        active: moment(roster.dates.H).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.H
                    },
                    I: {
                        active: moment(roster.dates.I).isBefore(moment(moment().format('YYYY-MM-DD'))) ? null : true,
                        date: roster.dates.I
                    }
                }

                setDatestoUpload(datesToUpload_object);

                roster.roster.forEach((data, index) => {
                    let driverId = 0

                    driversReturn.data.find(x => {
                        if (x.transporterid == data.B) {
                            driverId = x.id
                        }
                    })

                    const rowData = {
                        id: index,
                        trasnporterid: data.B,
                        importName: data.A,
                        driverid: driverId,
                        active: true,
                        dates: []
                    }

                    if (data?.C !== undefined) {
                        let date_object = {
                            time: data.C,
                            date: roster.dates.C
                        }

                        rowData.dates.push(date_object);
                    }

                    if (data?.D !== undefined) {
                        let date_object = {
                            time: data.D,
                            date: roster.dates.D
                        }

                        rowData.dates.push(date_object);
                    }

                    if (data?.E !== undefined) {
                        let date_object = {
                            time: data.E,
                            date: roster.dates.E
                        }

                        rowData.dates.push(date_object);
                    }

                    if (data?.F !== undefined) {
                        let date_object = {
                            time: data.F,
                            date: roster.dates.F
                        }

                        rowData.dates.push(date_object);
                    }

                    if (data?.G !== undefined) {
                        let date_object = {
                            time: data.G,
                            date: roster.dates.G
                        }

                        rowData.dates.push(date_object);
                    }

                    if (data?.H !== undefined) {
                        let date_object = {
                            time: data.H,
                            date: roster.dates.H
                        }

                        rowData.dates.push(date_object);
                    }

                    if (data?.I !== undefined) {
                        let date_object = {
                            time: data.I,
                            date: roster.dates.I
                        }

                        rowData.dates.push(date_object);
                    }

                    formObject.roster.push(rowData);
                })

                setUploadData(formObject);

                UpdateUploadObject(formObject, datesToUpload_object);

            });
    }, []);

    function ChangeDriver(e, index) {
        let data = uploadData;
        if (e.target.value == 'new_driver') {
            e.target.value = 0
            console.log('new driver', data.roster[index]);
            setStartDriverData({
                transporterID: data.roster[index].trasnporterid,
                fname: data.roster[index].importName.split(' ')[0],
                lname: data.roster[index].importName.split(' ').slice(- (data.roster[index].importName.split(' ').length - 1)).join(' ')
            })
            setVisibleDriverModal(true);
            return;
        }
        data.roster[index].driverid = e.target.value;
        setUploadData(data);
        setTeste(!teste);
        UpdateUploadObject(data, datesToUpload);
    }

    function ChangeDateToUpload(e, letter) {
        console.log(e.target.checked, letter);
        let dates = datesToUpload;
        dates[letter].active = e.target.checked;
        setDatestoUpload(dates);
        setTeste(!teste);
        UpdateUploadObject(uploadData, dates);
    }

    function ChangeSelect(e, index) {
        let uploadData_clone = uploadData;
        uploadData_clone.roster.forEach(data => {
            let obj = data;
            if (data.id === index) {
                obj.active = !obj.active;
                return obj;
            }
            return obj;
        })
        setUploadData(uploadData_clone);
        setTeste(!teste);
        UpdateUploadObject(uploadData_clone, datesToUpload);
    }

    function SaveForce() {
        if (uploadObject === undefined) return;

        let dataUpload = uploadObject;

        dataUpload['force'] = true;

        api
            .post("roster/save", dataUpload, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                console.log(data)
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: data.data.message,
                }).then(_ => {
                    history.push('/roster')
                })

            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response.message,
                });

            }).finally(_ => {
                setLoading(false);
            })

    }

    function UpdateUploadObject(uploadDataFunc, datesUploadObj) {
        let upload_object = {
            roster: [],
        };

        let validDrivers = true;

        uploadDataFunc.roster.forEach(retorno => {
            retorno.dates?.forEach(data => {
                if (!retorno.active) {
                    return null;
                }

                let add = false;

                if (datesUploadObj.C.date === data.date) {
                    add = datesUploadObj.C.active
                }

                if (datesUploadObj.D.date === data.date) {
                    add = datesUploadObj.D.active
                }

                if (datesUploadObj.E.date === data.date) {
                    add = datesUploadObj.E.active
                }

                if (datesUploadObj.F.date === data.date) {
                    add = datesUploadObj.F.active
                }

                if (datesUploadObj.G.date === data.date) {
                    add = datesUploadObj.G.active
                }

                if (datesUploadObj.H.date === data.date) {
                    add = datesUploadObj.H.active
                }

                if (datesUploadObj.I.date === data.date) {
                    add = datesUploadObj.I.active
                }

                if (!add) {
                    return null;
                }

                if (retorno.driverid == undefined || retorno.driverid == 0 || retorno.driverid == null || retorno.driverid == 'undefined') {
                    validDrivers = false;
                }

                let new_item = {
                    driverid: retorno.driverid,
                    date: data.date,
                    time: data.time,
                    notes: ''
                };

                upload_object.roster.push(new_item)
            })
        })

        setUploadObject(upload_object)

    }

    function SaveRoster() {
        let upload_object = {
            roster: [],
        };

        let validDrivers = true;

        console.log(datesToUpload);


        uploadData.roster.forEach(retorno => {
            retorno.dates.forEach(data => {
                if (!retorno.active) {
                    return null;
                }

                let add = false;

                if (datesToUpload.C.date === data.date) {
                    add = datesToUpload.C.active
                }

                if (datesToUpload.D.date === data.date) {
                    add = datesToUpload.D.active
                }

                if (datesToUpload.E.date === data.date) {
                    add = datesToUpload.E.active
                }

                if (datesToUpload.F.date === data.date) {
                    add = datesToUpload.F.active
                }

                if (datesToUpload.G.date === data.date) {
                    add = datesToUpload.G.active
                }

                if (datesToUpload.H.date === data.date) {
                    add = datesToUpload.H.active
                }

                if (datesToUpload.I.date === data.date) {
                    add = datesToUpload.I.active
                }

                console.log(data.date, add, retorno);

                if (!add) {
                    return null;
                }

                console.log('driver id', retorno.driverid);
                if (retorno.driverid == undefined || retorno.driverid == 0 || retorno.driverid == null || retorno.driverid == 'undefined') {
                    console.log('invalid driver');
                    console.log(retorno);
                    validDrivers = false;
                }

                let new_item = {
                    driverid: retorno.driverid,
                    date: data.date,
                    time: data.time,
                    notes: ''
                };

                upload_object.roster.push(new_item)
            })
        })

        if (!validDrivers) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'Select Driver',
            })

            return;
        }

        setLoading(true);


        console.log('saving roster', upload_object);

        api
            .post("roster/save", upload_object, {
                headers: {
                    "x-access-token": GetToken(),
                },
            })
            .then((data) => {
                if (data.data.errno === 1062) {
                    setUploadErrors(data.data.roster);
                    setIsOpen(true);
                }
                else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: data.data.message,
                    }).then(_ => {
                        history.push('/roster')
                    })
                }
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: '',
                });

            }).finally(_ => {
                setLoading(false);
                setUploadObject(upload_object)
            })

    }

    function checkAllFunc(e) {

        let uploadDataClone = uploadData;
        uploadDataClone.roster.forEach(retorno => {
            retorno.active = e.target.checked;
            return retorno;
        });
        setCheckAll(e.target.checked)

        setUploadData(uploadDataClone);
        UpdateUploadObject(uploadDataClone, datesToUpload);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div >
            <Modal isOpen={visibleDriverModal} >
                <h2><FormattedMessage id="tabela.create_driver" /></h2>
                <AddEditDrivers mode='roster' setModal={handleVisibleModal} />
            </Modal>
            <Loading visible={loading} />
            <Modal
                isOpen={modalIsOpen}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <h4 style={{ textAlign: 'center' }}><FormattedMessage id="txt_some_data_conflicts_import" /></h4>
                {/*<table className='table table-list btn100'>
                    <thead>
                    <tr style={{ fontWeight: 600, textAlign: "center" }}>
                        <td><FormattedMessage id="tabela.driver" /></td>
                        <td><FormattedMessage id="tabela.date" /></td>
                    </tr>
                    </thead>
                    <tbody>
                    {uploadErrors.map(data => {
                        return (
                            <tr style={{textAlign: "center"}}>
                                <td>{drivers.find(x => x.id == data?.driverid)?.fname} {drivers.find(x => x.id == data?.driverid)?.lname}</td>
                                <td>{data?.date}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>*/}
                <div style={{ width: '100%', fontSize: 12, color: '#333', textAlign: 'center', margin: 10 }}>
                    <FormattedHTMLMessage id="txt_data_conflicts_import" />
                </div>
                <div className='row'>
                    <div className='col-2'>
                    </div>
                    <div className='col-4'>
                        <button className="btn btn-orange btn100" style={{ backgroundColor: "#FF2626" }} onClick={() => setIsOpen(false)}><FormattedMessage id="not_overwrite" /></button>
                    </div>
                    <div className='col-4'>
                        <button className="btn btn-orange btn100" style={{ backgroundColor: "#059C66" }} onClick={() => SaveForce()}><FormattedMessage id="overwrite" /></button>
                    </div>
                    <div className='col-2'>
                    </div>
                </div>
            </Modal>

            <div className="row mb-3">
                <div className="col-md-9">
                    <p className="title"><FormattedMessage id="roster_import" /></p>
                </div>
                <div className="col-6 col-md-1">
                    <button className="btn btn-orange" style={{ backgroundColor: "red" }} onClick={() => { history.push('/roster') }}>
                        <FormattedMessage id="form.cancel" />
                    </button>
                </div>
                <div className="col-6 col-md-2">
                    <button
                        className="btn btn-orange"
                        style={{ backgroundColor: "blue", width: "100%" }}
                        onClick={SaveRoster}
                    >
                        <FormattedMessage id="save" />
                    </button>
                </div>
            </div>

            <div className="table-container">
                <table className="table table-list btn100" style={{ fontSize: 12 }}>
                    <thead>
                        <tr style={{ fontWeight: 600, textAlign: "center" }}>
                            <td>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={checkAll}
                                        onChange={(e) => checkAllFunc(e)}
                                        id="flexCheckIndeterminate"
                                    />
                                </div>
                            </td>
                            <td><FormattedMessage id="form.transporter_id" /></td>
                            <td><FormattedMessage id="tabela.import_name" /></td>
                            <td><FormattedMessage id="tabela.select_driver" /></td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.C && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.C}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        disabled={moment(roster?.dates.C).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        checked={datesToUpload?.C?.active}
                                        onChange={(e) => { ChangeDateToUpload(e, 'C'); }}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.D && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.D}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        disabled={moment(roster?.dates.D).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        checked={datesToUpload?.D?.active}
                                        onChange={(e) => ChangeDateToUpload(e, 'D')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.E && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.E}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={datesToUpload?.E?.active}
                                        disabled={moment(roster?.dates.E).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        onChange={(e) => ChangeDateToUpload(e, 'E')}

                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.F && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.F}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={datesToUpload?.F?.active}
                                        disabled={moment(roster?.dates.F).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        onChange={(e) => ChangeDateToUpload(e, 'F')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.G && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.G}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={datesToUpload?.G?.active}
                                        disabled={moment(roster?.dates.G).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        onChange={(e) => ChangeDateToUpload(e, 'G')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.H && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.H}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={datesToUpload?.H?.active}
                                        disabled={moment(roster?.dates.H).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        onChange={(e) => ChangeDateToUpload(e, 'H')}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mt-1'>
                                    {uploadObject?.roster?.filter(x => {
                                        return x.date === roster?.dates.I && x.driverid != 0
                                    }).length}
                                </div>
                                {roster?.dates.I}
                                <div
                                    className="form-check form-switch"
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: -10,
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={datesToUpload?.I?.active}
                                        disabled={moment(roster?.dates.I).isBefore(moment(moment().format('YYYY-MM-DD')))}
                                        onChange={(e) => ChangeDateToUpload(e, 'I')}
                                    />
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {uploadData?.roster?.map((data) => {
                            return (
                                <tr style={{ textAlign: "center" }}>
                                    <td>
                                        <div className="form-check">
                                            <input
                                                key={data.id}
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={data.active}
                                                onChange={(e) => ChangeSelect(e, data.id)}
                                            />
                                        </div>
                                    </td>
                                    <td>{data.trasnporterid}</td>
                                    <td>{data.importName}</td>
                                    <td>
                                        <select className="form-select form-select-sm" onChange={(e) => ChangeDriver(e, data.id)} style={{ borderColor: data.driverid == 0 && data.active ? 'red' : '' }}>
                                            <FormattedMessage id="tabela.select_driver">
                                                {(text) => {
                                                    return (
                                                        <option value='0'>{text}</option>
                                                    );
                                                }}
                                            </FormattedMessage>
                                            <FormattedMessage id="tabela.create_driver">
                                                {(text) => {
                                                    return (
                                                        <option value='new_driver'>+ {text}</option>
                                                    );
                                                }}
                                            </FormattedMessage>
                                            {drivers.map((driver) => (
                                                <option selected={driver.id == data.driverid} value={driver.id}>{driver.fname} {driver.lname}</option>
                                            ))}

                                        </select>
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.C }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.D }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.E }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.F }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.G }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.H }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                    <td>
                                        {data?.dates.find(x => { return x.date === roster.dates.I }) ? (
                                            <FontAwesomeIcon icon={faTruck} size="lg" />
                                        ) : null}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
