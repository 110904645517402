import React, { useEffect, useState } from "react";
import { faDownload, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import api, { GetToken } from "../api/axios";
import Loading from "./Loading";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-modal';
import Swal from 'sweetalert2';

export default function MaintenanceFile({ maintenanceId }) {

    const [files, setFiles] = useState([]);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [visibleFile, setVisibleFile] = useState(false);
    const [expandFile, setExpandFile] = useState(false);
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState("");

    function fileDownload(item) {
        var link = document.createElement("a");
        link.href = item.path;
        link.download = item.filename;
        link.click();
    }

    function fileDelete(id) {
        Swal.fire({
            title: "Confirm delete",
            text: "Are you sure you want to delete this item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#565e64",
            confirmButtonText: "Delete",
          }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                api
                    .delete("maintenance-file/delete/" + id, {
                        headers: { "x-access-token": GetToken() },
                    })
                    .then((data) => {
                        console.log(data.data);
                        getMaintenanceFile();
                    })
                    .catch((err) => {
                        console.log(err.response);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.response,
                        });
                    }).finally(() => {
                        setLoading(false);
                    });
            }
          });
    }

    function getMaintenanceFile() {
        setLoading(true);

        api
            .get("maintenance-file/getbymaintenanceid/" + maintenanceId, {
                headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
                console.log(data.data);
                setFiles(data.data);
            })
            .catch((err) => {
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response,
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    function closeModalFile() {
        setFile("");
        setFileName("");
        setVisibleFile(false);
    }

    function onFileChange(e) {
        if(e.target.files[0]){
          setFile(e.target.files[0]);
          setFileName(e.target.files[0].name);
        }
    }

    function importFile() {

        if (file === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "No file uploaded"
            });
            return;
        }

        setLoadingFile(true);

        const data = new FormData();
        data.append("filetomaintenance", file);
        data.append("maintenanceid", maintenanceId);
        data.append("description", text);
        api
            .post("maintenance-file/create", data, {
            headers: {
                "x-access-token": GetToken(),
                "Content-Type": "multipart/form-data;",
            },
            })
            .then((data) => {
                setVisibleFile(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.response?.data.message,
                });
            })
            .finally(() => {
                setLoadingFile(false);
                console.log("end request");
            });
    }

    function formatDate(date) {
        var d = new Date(date.split(" ")[0]),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [month, day, year].join('/');
    }

    function handleFileClose() {
        getMaintenanceFile();
    }

    function accordeonClick() {
        setExpandFile(!expandFile);
        if (!expandFile) {
            getMaintenanceFile();
        }
    }

    return (
        <>
            <Loading visible={loading} />
            <Modal ariaHideApp={false} isOpen={visibleFile} onAfterClose={handleFileClose}>
                <Loading visible={loadingFile} />
                <div className="row d-flex justify-content-center">
                    <div className="row">
                        <div className="col-4 col-md-4">
                            <h5 className="mb-4">
                                <FormattedMessage id="maintenance_file" />
                            </h5>
                        </div>
                    </div>
                    <div
                        className="form-content col-4 col-md-4"
                        style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        }}
                    >
                        <FormattedMessage id="tabela.description">
                        {(placeholder) => {
                            return (
                                <textarea className="form-control"
                                    id="text-file"
                                    rows="2"
                                    placeholder={placeholder}
                                    style={{ resize: 'none' }}
                                    onChange={ (e) => setText(e.target.value) }
                                />
                            );
                        }}
                        </FormattedMessage>
                        <br />
                        <label htmlFor="upload-file" id="label-upload-file" className="label-upload-photo">
                        {fileName !== '' ? fileName : <FormattedMessage id="browse_file" />}
                        </label>
                        <input type="file" name="filetomaintenance" id="upload-file" className="upload-photo" onChange={onFileChange} />
                        <br />
                        <div className="col col-md-4 d-flex justify-content-around">
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    closeModalFile()
                                }}
                            >
                                <FormattedMessage id="form.cancel" />
                            </button>
                            <FormattedMessage id="save">
                            {(placeholder) => {
                                return (
                                <input
                                    type="button"
                                    className="btn btn-orange"
                                    onClick={importFile}
                                    style={{ backgroundColor: "blue" }}
                                    value={placeholder}
                                    disabled={fileName === ''}
                                />
                                );
                            }}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="row mt-4">
                <div class="accordion" id="accordionFile">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" onClick={accordeonClick} data-bs-toggle="collapse" data-bs-target="#collapseTwo" >
                                <FormattedMessage id="maintenance_files" />
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFile">
                            <div class="accordion-body">
                                <div className="row text-end">
                                    <div className="col-12 col-md-3 offset-md-9">
                                        <a className="btn btn-orange btn100" onClick={() => {
                                            setVisibleFile(true);
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                                            <FormattedMessage id="add_maintenance_file" />
                                        </a>
                                    </div>
                                </div>
                                <div className="table-container">
                                    <table className="table table-list btn100">
                                        <thead>
                                            <tr>
                                                <th scope="col"><FormattedMessage id="tabela.date" /></th>
                                                <th scope="col"><FormattedMessage id="tabela.file" /></th>
                                                <th scope="col"><FormattedMessage id="tabela.description" /></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {files.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {formatDate(item.createdat)}
                                                        </td>
                                                        <td>
                                                            {item.filename}
                                                        </td>
                                                        <td>
                                                            {item.description}
                                                        </td>
                                                        <td className='icon-cell'>
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                onClick={() => fileDownload(item)}
                                                            />
                                                        </td>
                                                        <td className='icon-cell'>
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                onClick={() => fileDelete(item.id)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
