let newWorker;

export const registerServiceWorker = () => {

      navigator.serviceWorker
        .register("/sw.js")
        .then(function (registration) {
          console.log(registration);
          registration.addEventListener("updatefound", () => { // [B]
            console.log('skip waiting');

              // Uma atualização no Service Worker foi encontrada, instalando...
              newWorker = registration.installing; // [C]
      
              newWorker.addEventListener("statechange", () => {
                // O estado do Service Worker mudou?
                switch (newWorker.state) {
                  case "installed": {
                    console.log('skip waiting');

                    // Existe um novo Service Worker disponível, mostra a notificação
                    if (navigator.serviceWorker.controller) {
                      console.log('skip waiting');
                      newWorker.postMessage({ action: "skipWaiting" });
                    }

                    break;
                  }
                }
              });
            });
      
            // SUCESSO - ServiceWorker Registrado
            console.log(
              "ServiceWorker registrado com sucesso no escopo: ",
              registration.scope
            );
          })
        .catch(function (err) {
          // registration failed :(
          //console.log('ServiceWorker registration failed: ', err);
        });

        let refreshing;
      navigator.serviceWorker.addEventListener('controllerchange', function () {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
};
