import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import api, { GetToken } from "../../../api/axios";
import Loading from "../../../components/Loading";

export default function ImportMentor(props) {

  const history = useHistory();
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState();

  function onFileChange(e) {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  function importFile() {
    if (file === "") {
      alert("No file uploaded");
    }
    setLoading(true);
    const data = new FormData();
    data.append("filetomentor", file);
    api
      .post("upload/mentor", data, {
        headers: {
          "x-access-token": GetToken(),
          "Content-Type": "multipart/form-data;",
        },
      })
      .then((data) => {
        props.setMentorMethod(data.data);
        props.setFileNameMentorImport(fileName);
        history.push('/coaching/import/mentor/confirm');
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response?.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
        console.log("end request");
      });
  }

  return (
    <>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-12 col-md-12">
          <h5 className="text-left mb-3">
            Mentor
          </h5>
        </div>
      </div>
      <div
        className="form-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <label htmlFor="upload-photo" id="label-upload-photo">
          {fileName !== '' ? fileName : <FormattedMessage id="browse_file" />}
        </label>
        <input type="file" name="filetomentor" id="upload-photo" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onFileChange} />
        <br />
        <FormattedMessage id="import_file">
          {(placeholder) => {
            return (
              <input
                type="button"
                className="btn btn-orange"
                onClick={importFile}
                style={{ backgroundColor: "blue" }}
                value={placeholder}
                disabled={fileName === ''}
              />
            );
          }}
        </FormattedMessage>
      </div>
    </>
  );
}
