import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";

export default function ImportRosterV2(props) {

  const history = useHistory();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState();
  const [text, setText] = useState("");

  function onFileChange(e) {
    if(e.target.files[0]){
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  }

  function importFile() {
    if (file === "") {
      alert("No file uploaded");
    }
    setLoading(true);
    const data = new FormData();
    data.append("filetorosterv2", file);
    api
      .post("/upload/rosterv2", data, {
        headers: {
          "x-access-token": GetToken(),
          "Content-Type": "multipart/form-data;",
        },
      })
      .then((data) => {
        props.setRosterV2Method(data.data);
        props.setFileNameRosterV2Import(fileName);
        history.push('/imports/rosterv2/confirm');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response?.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
        //console.log("end request");
      });
  }

  function importText() {
    if (text.trim() === "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "No text found",
      });
      return;
    }
   
    setLoading(true);
    //const textData = { "textrosterv2": text };
    api
      .post("/upload/text-rosterv2", 
        { textrosterv2: text }, 
        { headers: { "x-access-token": GetToken() } }
      )
      .then((data) => {
        props.setRosterV2Method(data.data);
        props.setFileNameRosterV2Import("Copy and Paste");
        history.push('/imports/rosterv2/confirm');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response?.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
        //console.log("end request");
      });
  }

  return (
    <>
      <Loading visible={loading} />
      <div className="row">
        <div className="col-12 col-md-12">
          <h5 className="text-left mb-3">
            Roster V2
          </h5>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col p-2 justify-flex-center">
          <label htmlFor="upload-rosterv2" id="label-upload-rosterv2" className="label-upload-photo mb-3">
            {fileName !== '' ? fileName : <FormattedMessage id="browse_file" />}
          </label>
          <input type="file" name="filetorosterv2" id="upload-rosterv2" className="upload-photo" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onFileChange} />
          <br />
          <FormattedMessage id="import_file">
            {(placeholder) => {
              return (
                <input
                  type="button"
                  className="btn btn-orange"
                  onClick={importFile}
                  style={{ backgroundColor: "blue" }}
                  value={placeholder}
                  disabled={fileName === ''}
                />
              );
            }}
          </FormattedMessage>
        </div>
        <div className="col p-2">
          <textarea className="form-control"
                    id="text-rosterv2"
                    rows="2"
                    placeholder="Paste text here"
                    style={{ resize: 'none' }}
                    onChange={ (e) => setText(e.target.value) }
                  />
          <br />
          <FormattedMessage id="import_text">
            {(placeholder) => {
              return (
                <input
                  type="button"
                  className="btn btn-orange"
                  onClick={importText}
                  style={{ backgroundColor: "blue" }}
                  value={placeholder}
                  disabled={text === ''}
                />
              );
            }}
          </FormattedMessage>
        </div>
      </div>
    </>
  );
}
