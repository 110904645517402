import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";

import "date-fns";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";

import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSave } from "@fortawesome/free-solid-svg-icons";

export default function AddEdit() {
  const history = useHistory();
  let { id } = useParams();
  const [preload, setPreload] = useState([]);
  const [time, setTime] = useState("");
  const [timeMask, setTimeMask] = useState();

  const { register, handleSubmit, setValue } = useForm();

  function onSubmit(data) {
    const time_formated =
      (time.getHours() < 10 ? "0" : "") +
      time.getHours() +
      ":" +
      (time.getMinutes() < 10 ? "0" : "") +
      time.getMinutes();

    if (time_formated.includes("NaN")) {
      alert("Invalid Time");
    }

    if (formatDate(data.date).includes("NaN")) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid Date",
      });
      return;
    }

    if (id) {
      //edit
     
      api
        .put(
          "roster/update/" + id,
          {
            date: data.date,
            time: time_formated,
            driverid: data.driverid,
            vehicleid: data.vehicleid,
            deviceid: data.deviceid,
            route: data.route,
            staging: data.staging,
            space: data.space,
            statusid: data.statusid,
            notes: data.notes,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then(() => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
            history.push("/roster");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });


    } else {
      //create

      api
        .post(
          "roster/create",
          {
            date: data.date,
            time: time_formated,
            driverid: data.driverid, 
            vehicleid: data.vehicleid == "" ? "0" : data.vehicleid,
            deviceid: data.deviceid == "" ? "0" : data.deviceid,
            route: data.route,
            staging: data.staging,
            space: data.space,
            statusid: data.statusid == "" ? "0" : data.statusid,
            notes: data.notes,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
            history.push("/roster");
          //});
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });

    }
  }

  useEffect(() => {
    api
      .get("roster/preload", {
        headers: { "x-access-token": GetToken() },
      })
      .then((preloadData) => {
        console.log("preload", preloadData.data);

        setPreload(preloadData.data);
        if (!id) {
          const current = new Date();
          setTime(current);
          setValue("statusid", 0);
        }

        if (id) {
          api
            .get("roster/getbyid/" + id, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              const value = data.data[0];
              setValue("date", value.date);
              setValue("driverid", value.driverid);
              setValue("vehicleid", value.vehicleid);
              setValue("deviceid", value.deviceid);
              setValue("route", value.route);
              setValue("staging", value.staging);
              setValue("space", value.space);
              setValue("statusid", value.statusid);
              setValue("notes", value.notes);

              const current = new Date();
              const dateTimeTwo = new Date(
                `${current.getFullYear()}/${
                  current.getMonth() + 1
                }/${current.getDate()} ${value.time}`
              );
              setTime(dateTimeTwo);
            })
            .catch((err) => {
              console.log(err.response);
            });
        } else {
          setValue("date", moment().format('YYYY-MM-DD'));
        }
      })
      .catch((err) => {
        console.log(err.response);
      });

    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        const currentLang = localStorage.getItem("language");
        const lang = data.data.find((x) => x.code === currentLang)?.timemask;
        console.log(data.data.find((x) => x.code === currentLang));
        setTimeMask(lang);
      });
  }, [id, setValue]);


  function changeSelectDriver(e){
    const driver = preload.driver?.find(x=>x.id==e.target.value);
    const vehicle = preload.vehicle?.find(x=>x.id==driver.vehicleid);

    setValue("vehicleid",driver?.vehicleid);
    setValue("space",vehicle?.space);
    setValue("deviceid",vehicle?.deviceid);
  }

  function changeSelectVehicle(e){
    const vehicle = preload.vehicle?.find(x=>x.id==e.target.value);

    setValue("space",vehicle?.space);
    setValue("deviceid",vehicle?.deviceid);
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-content">
      <div className="row">
        <div className="col-md-6">
          <FormattedMessage id="form.delivery_associate" />
          <select
            className="form-select"
            {...register("driverid", { required: true })}
            onChange={(e)=>changeSelectDriver(e)}
          >
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {preload?.driver?.map((data) => (
              <option value={data.id}>{data.fname} {data.lname}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <FormattedMessage id="form.vehicle" />
          <select
            className="form-select"
            {...register("vehicleid")}
            onChange={(e)=>changeSelectVehicle(e)}
          >
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {preload?.vehicle?.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <FormattedMessage id="form.device" />
          <select
            className="form-select"
            {...register("deviceid")}
          >
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {preload?.device?.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <FormattedMessage id="form.parking_space" />
          <input className="form-control" {...register("space")} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.staging" />
          <input className="form-control" {...register("staging")} />
        </div>

        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.route" />
          <input className="form-control" {...register("route")} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          Status
          <select
            className="form-select"
            {...register("statusid")}
          >
            <FormattedMessage id="form.select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            </FormattedMessage>
            {preload?.status?.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.time" />
          {timeMask === "12H" ? (
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              value={time}
              onChange={(e) => {
                setTime(e);
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              style={{ display: "inherit", margin: 0, padding: 0 }}
              keyboardIcon={<FontAwesomeIcon icon={faClock} size="xs" />}
            />
          ) : (
            <input
              type="time"
              value={moment(time).format("HH:mm")}
              className="form-control"
              onChange={(e) => {
                setTime(new Date("2021/01/01 " + e.target.value));
                setTime(new Date("2021/01/01 " + e.target.value));
              }}
            />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.date" />
          <input
            className="form-control"
            type="date"
            {...register("date", { required: true })}
          />
        </div>

        <div className="col-md-6 mt-3">
          <FormattedMessage id="form.notes" />
          <input className="form-control" {...register("notes")} />
        </div>
      </div>

      <div className="d-flex flex-row-reverse bd-highlight">
      <FormattedMessage id="edit_save">
            {(edit_save) => {
              return (
                <button  type="submit"
                className="btn btn-orange btn-confirm float-end"
               >
               <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4"  />
               {id ? edit_save.edit : edit_save.save}
               </button>
              );
            }}
          </FormattedMessage>
        <button
          className="btn btn-secondary btn-confirm"
          onClick={() => history.push("/roster")}
        >
          <FormattedMessage id="form.cancel" />
        </button>
      </div>
    </form>
  );
}

function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;
  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
  
}
