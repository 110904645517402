import React from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import List from "./list";
import AddEdit from "./addEdit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

export default function Issue() {
  var pathName = useLocation().pathname;

  const history = useHistory()

  function CreateIssue() {
    history.push('/params/issue/add')
  }

  return (
    <Switch>
      <div className="container mt-3">
        <div className='row mb-3'>
          <div className='col-6 col-md-10'>
            <p className="title"><FormattedMessage id="issue" /></p>
          </div>
          {(!pathName.includes('add') && !pathName.includes('edit'))  && <div className='col-6 col-md-2'>
            <button className='btn btn-orange btn100' onClick={CreateIssue}>
              <FontAwesomeIcon icon={faPlus} className='btn-icon'/>
              <FormattedMessage id="form.create" />
            </button>
          </div> }
        </div>
        <Route exact path="/params/issue" component={List} />
        <Route path="/params/issue/add" component={AddEdit} />
        <Route path="/params/issue/edit/:id" component={AddEdit} />
      </div>
    </Switch>
  );
}

