import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import List from "./list";
import Historic from "./historic";
import Assigned from "./assigned";
import AddEdit from "./addEdit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

export default function Drivers() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  var pathName = useLocation().pathname;

  function CreateVehicle() {
    history.push("/vehicles/add");
  }

  useEffect(() => {
    document.title = "LoadOut - Vehicles";
  });

  return (
    <Switch>
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <p className="title">
              <FormattedMessage id="menu.vehicles" />
            </p>
          </div>

          {(!pathName.includes('add') && !pathName.includes('edit') && !pathName.includes('historic') && !pathName.includes('assigned')) && <div className="col-md-6 row">
            <div className="col-6 col-md-8">
              <input
                className="form-control"
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-4">
              <button className="btn btn-orange btn100" onClick={CreateVehicle}>
                <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                <FormattedMessage id="form.create" />
              </button>
            </div>
          </div>}

        </div>
        <Route
          exact
          path="/vehicles"
          component={(props) => <List searchTerm={search} />}
        />
        <Route path="/vehicles/add" render={()=> <AddEdit clear={() =>setSearch('')}/>}   />
        <Route path="/vehicles/edit/:id" render={()=> <AddEdit clear={() =>setSearch('')}/>}    />
        <Route path="/vehicles/historic/:id"  render={()=> <Historic clear={() =>setSearch('')}/>}   />
        <Route path="/vehicles/assigned/:id" render={()=> <Assigned clear={() =>setSearch('')}/>}    />
      </div>
    </Switch>
  );
}
