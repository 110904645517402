import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import List from "./list";
import AddEdit from "./addEdit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

export default function Users() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  var pathName = useLocation().pathname;

  function CreateUser() {
    history.push("/users/add");
  }

  useEffect(() => {
    document.title = "LoadOut - Users";
  });

  return (
      <Switch>
        <div className="container mt-3">
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <p className="title">
                <FormattedMessage id="users" />
              </p>
            </div>
            {(!pathName.includes('add') && !pathName.includes('edit')) && <div className="col-md-6 row">
              <div className="col-6 col-md-8">
                <FormattedMessage id="form.search">
                  {(placeholder) => {
                    return (
                        <input
                            className="form-control"
                            placeholder={placeholder}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    );
                  }}
                </FormattedMessage>
              </div>
              <div className="col-6 col-md-4">
                <button className="btn btn-orange btn100" onClick={CreateUser}>
                  <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                  <FormattedMessage id="form.create" />
                </button>
              </div>
            </div> }
          </div>
          <Route
              exact
              path="/users"
              component={(props) => <List searchTerm={search} />}
          />
          <Route path="/users/add" component={AddEdit} />
          <Route path="/users/edit/:id" component={AddEdit} />
        </div>
      </Switch>
  );
}
