import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../../api/axios";
import Loading from "../../../components/Loading";
import { FormattedMessage } from "react-intl";

export default function List() {
  const [drivers, setCarriers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    UpdateList();
  }, []);

  function UpdateList() {
    setLoading(true);

    api
      .get("param-ownership/getall", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setCarriers(data.data);
      })
      .catch((err) => {
        console.log(err.response);
      }).finally(_ => {
        setLoading(false)
      })
  }

  return (
    <div className="table-container">
      <Loading visible={loading} />
      <table className="table table-list btn100">
        <thead>
          <tr>
            <th scope="col"><FormattedMessage id="tabela.name" /></th>
          </tr>
        </thead>
        <tbody>
          {drivers.map((driver) => {
            console.log(driver);
            return (
              <tr>
                <td>{driver.name}</td>                
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
