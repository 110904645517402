import React from "react";
import { Component } from "react";
import { Line } from 'react-chartjs-2';

class Chart extends Component {

    constructor(props) {
        super(props);

        //console.log(props.scores);
        const labels = Object.keys(props.scores);

        this.state = {
            chartData: {
                labels: labels.map(week => 'Week ' + props.scores[week].week),
                datasets: [
                    {
                        label: 'Overall Standing',
                        data: labels.map(overall => props.scores[overall].overall),
                        backgroundColor: "#3D55D6",
                        borderColor: "#3D55D6",
                    },
                    {
                        label: 'Safety & Compliance',
                        data: labels.map(safety => props.scores[safety].safety),
                        backgroundColor: "#4AD8CF",
                        borderColor: "#4AD8CF",
                    },
                    /*
                    {
                        label: 'Reliability',
                        data: labels.map(reliability => props.scores[reliability].reliability),
                        backgroundColor: "#51C27E",
                        borderColor: "#51C27E",
                    },
                    */
                    {
                        label: 'Team',
                        data: labels.map(team => props.scores[team].team),
                        backgroundColor: "#BD36A8",
                        borderColor: "#BD36A8",
                    },
                    {
                        label: 'Quality',
                        data: labels.map(quality => props.scores[quality].quality),
                        backgroundColor: "#ECB907",
                        borderColor: "#ECB907",
                    }
                ]
            }
        }

    }


    render() {
        return (
            <div className="chart">
                <Line
                    data={this.state.chartData}
                    width={500}
                    height={400}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            }
                        },
                    }}
                />
            </div>
        )
    }
}

export default Chart;