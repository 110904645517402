import React from "react";
import Routes from "./routes";
import withClearCache from "./ClearCache";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import IntlProviderConfigured from "./_i18n/IntlProviderConfigured";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp(props) {
    return (
        <IntlProviderConfigured>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Routes />
            </MuiPickersUtilsProvider>
        </IntlProviderConfigured>
    );
}

export default App;