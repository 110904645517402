import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import ReactInputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { faClock, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import Modal from 'react-modal';
import Loading from "../../components/Loading";

export default function AddEdit() {
  
  const history = useHistory();
  const [preload, setPreload] = useState([]);
  const [preloadIssue, setPreloadIssue] = useState([]);
  const [issues, setIssues] = useState([]);
  const [driverid, setDriverid] = useState("");
  const [visibleModalIssues, setVisibleModalIssues] = useState(false);
  const [visibleModalNew, setVisibleModalNew] = useState(false);
  const [time, setTime] = useState("");
  const [timeMask, setTimeMask] = useState();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const [typeid, setTypeid] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  let { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();



  function onCreate(event) {

    event.preventDefault();

    const time_formated =
      (time.getHours() < 10 ? "0" : "") +
      time.getHours() +
      ":" +
      (time.getMinutes() < 10 ? "0" : "") +
      time.getMinutes();

    if (time_formated.includes("NaN")) {
      alert("Invalid Time");
    }

    api
      .post(
        "issue/create",
        {
          userid: driverid,
          typeid: typeid,
          date: date,
          description: description,
          time: time_formated,
        },
        { headers: { "x-access-token": GetToken() } }
      )
      .then((data) => {

        if (data.data.insertId) {
          api
            .get("issue/getbyid/" + data.data.insertId, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              const value = data.data[0];
              let newIssue = [...selected, value]
              setSelected(newIssue);
              setTypeid("");
              setDate("");
              setDescription("");
            })
            .catch((err) => {
              console.log(err.response);
            }).finally(() => {
              setVisibleModalNew(false);
            });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });

  }

  function DeleteCounseling(e, id) {
    e.preventDefault();

    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("counseling/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Counseling deleted",
            }).then(() => {
              //UpdateList();
              history.push("/counseling/list");
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });

  }

  function onSubmit(data) {

    let keys = [];
    selected.filter(function (item) {
      keys.push(item.id);
    });

    if (id) {
      //edit
      api
        .put(
          "counseling/update/" + id,
          {
            userid: data.userid,
            leaderid: data.leaderid,
            typeid: data.typeid,
            date: data.date,
            description: data.description,
            prior: data.prior,
            note: data.note,
            refused: data.refused,
            action: data.action,
            failure: data.failure,
            issueids: keys
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          // title: "Success",
          //}).then(() => {
          history.push("/counseling/list");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {
      //create
      api
        .post(
          "counseling/create",
          {
            userid: data.userid,
            leaderid: data.leaderid,
            typeid: data.typeid,
            date: data.date,
            description: data.description,
            prior: data.prior,
            note: data.note,
            refused: data.refused,
            action: data.action,
            failure: data.failure,
            issueids: keys
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          console.log(data);
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          history.push("/counseling/list");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }

  }


  useEffect(() => {

    api
      .get("counseling/preload", { headers: { "x-access-token": GetToken() } })
      .then((preloadApi) => {
        setPreload(preloadApi.data);

        api
          .get("issue/preload", { headers: { "x-access-token": GetToken() } })
          .then((preloadApi) => {
            setPreloadIssue(preloadApi.data);

            if (id) {
              api
                .get("counseling/getbyid/" + id, {
                  headers: { "x-access-token": GetToken() },
                })
                .then((data) => {
                  const value = data.data[0];
                  setValue("userid", value.userid);
                  setValue("leaderid", value.leaderid);
                  setValue("typeid", value.typeid);
                  setValue("date", value.date);
                  setValue("description", value.description);
                  setValue("prior", value.prior);
                  setValue("note", value.note);
                  setValue("refused", value.refused);
                  if (value.userid != '') {
                    setDriverid(value.userid);
                  }
                })
                .catch((err) => {
                  console.log(err.response);
                });

              api
                .get("issue/getbycounselingid/" + id, {
                  headers: { "x-access-token": GetToken() },
                })
                .then((data) => {
                  setSelected(data.data);
                })
                .catch((err) => {
                  console.log(err.response);
                }).finally(() => {
                });
            }

          })
          .catch((err) => {
            console.log(err.response);
          });

      })
      .catch((err) => {
        console.log(err.response);
      });






  }, [id, setValue]);


  function openModalIssues(e) {

    e.preventDefault();
    if (driverid) {
      api
        .post("issue/getbyuserid/" + driverid, {
          "page": 1,
          "rows": 1000
        },
          { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          setIssues(data.data.issue);
        })
        .catch((err) => {
        })
        .finally(() => {
          setVisibleModalIssues(true);
        });
    }

  }


  function print(e) {
    e.preventDefault();

    setLoading(true);

    if (id) {

      api
        .post("pdf/counseling/" + id, {}, {
          responseType: "arraybuffer",
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          var file = new Blob([data.data], { type: "application/pdf" });
          // Create a link pointing to the ObjectURL containing the blob.
          var fileURL = URL.createObjectURL(file, { oneTimeOnly: true });
          var link = document.createElement("a");
          link.href = fileURL;
          link.download = "Counseling.pdf";
          link.click();
          //setTimeout(function () {
          //  window.URL.revokeObjectURL(data);
          //}, 100);
          //window.open(fileURL);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally((_) => {
          setLoading(false);
        });

    }

  }


  function openModalNew(e) {
    e.preventDefault();
    if (driverid) {
      api
        .post("issue/getbyuserid/" + driverid, { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          setIssues(data.data.issue);
        })
        .catch((err) => {
        })
        .finally(() => {
          setVisibleModalNew(true);
        });
    }

  }

  function changeSelectDriver(e) {

    const driver = preload.driver?.find(x => x.id == e.target.value);
    setValue("userid", driver?.id);
    setDriverid(driver?.id);
    setSelected([]);

  }

  function changeSelectLeader(e) {

    const driver = preload.leader?.find(x => x.id == e.target.value);
    setValue("leaderid", driver?.id);

  }

  function setIssueById(id, issue, checked) {

    if (checked) {

      let newIssue = [...selected, issue]
      setSelected(newIssue);

    } else {

      let newSelected = selected;
      newSelected = newSelected.filter(item => item.id != id);
      setSelected(newSelected);

    }

  }

  return (
    <div className="row">
      <Loading visible={loading} />
      <div className="col-12 col-md-12 mb-2">
        <p className="title">
          <FormattedMessage id="counseling" />
        </p>
      </div>

      <Modal
        isOpen={visibleModalIssues}>
        <div className="table-container">
          <table className="table table-list btn100">
            <thead>
              <tr>
                <th scope="col" style={{ 'width': '30px' }}></th>
                <th scope="col"><FormattedMessage id="tabela.date" /></th>
                <th scope="col"><FormattedMessage id="tabela.driver" /></th>
                <th scope="col"><FormattedMessage id="description" /></th>
                <th scope="col"><FormattedMessage id="type" /></th>
              </tr>
            </thead>
            <tbody>
              {issues.map((item) => {
                return (
                  <tr>
                    <td>
                      <div class="form-check">
                        <input name="notificationApp" class="form-check-input" type="checkbox" checked={selected.some(x => item.id === x.id)}
                          onClick={(e) => {
                            setIssueById(item.id, item, e.target.checked);
                          }} id="notificationAppInput" />
                      </div>
                    </td>
                    <td>{item.date}</td>
                    <td>
                      {preloadIssue?.driver?.filter(x => x.statusid == 1 && item.userid == x.id).map((data) => (
                        <div key={data.id}>{data.fname + ' ' + data.lname}</div>
                      ))}
                    </td>
                    <td>{item.description}</td>
                    <td>{preloadIssue.issueType && preloadIssue.issueType[item.typeid]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <button
          className="btn btn-primary btn-confirm float-end"
          onClick={() => {
            setVisibleModalIssues(false);
          }}
        >
          <FormattedMessage id="close" />
        </button>

      </Modal>


      <Modal
        isOpen={visibleModalNew}>

        <form onSubmit={onCreate} className="form-content">
          <div className="row">

            <div className="col-md-4  mt-3">
              <FormattedMessage id="type" />
              <select onChange={(e) => {
                setTypeid(e.target.value);
              }} className="form-select">
                <FormattedMessage id="form.select">
                  {(placeholder) => {
                    return <option value="">{placeholder}</option>;
                  }}
                </FormattedMessage>
                {preloadIssue.issueType && Object.entries(preloadIssue?.issueType).map((data) => (
                  <option value={data[0]}>{data[1]}</option>
                ))}
              </select>
            </div>

            <div className="col-md-4 mt-3">
              <FormattedMessage id="form.date" />
              <input
                className="form-control"
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>

            <div className="col-md-4  mt-3">

              <FormattedMessage id="form.time" />
              {timeMask === "12H" ? (
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  value={time}
                  onChange={(e) => {
                    setTime(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  style={{ display: "inherit", margin: 0, padding: 0 }}
                  keyboardIcon={<FontAwesomeIcon icon={faClock} size="xs" />}
                />
              ) : (
                <input
                  type="time"
                  value={moment(time).format("HH:mm")}
                  className="form-control"
                  onChange={(e) => {
                    setTime(new Date("2021/01/01 " + e.target.value));
                    setTime(new Date("2021/01/01 " + e.target.value));
                  }}
                />
              )}
            </div>

            <div className="col-md-12 mt-3">
              <FormattedMessage id="description" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>

          </div>

          <div className="d-flex flex-row-reverse bd-highlight">
            <button
              type="submit"
              className="btn btn-primary btn-confirm float-end"

            >
              <FormattedMessage id="save" />
            </button>

            <button
              className="btn btn-secondary  btn-confirm float-end"
              onClick={() => {
                setVisibleModalNew(false);
              }}
            >
              <FormattedMessage id="form.cancel" />
            </button>


          </div>
        </form>

      </Modal>



      <div className="col-md-12 col-12">
        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
          <div className="row">
            <div className="col-md-6  mt-3">
              <FormattedMessage id="leader" />
              <select
                className="form-select"
                {...register("leaderid", { required: true })}
                onChange={(e) => changeSelectLeader(e)}
              >
                <FormattedMessage id="form.select">
                  {(placeholder) => {
                    return <option value="">{placeholder}</option>;
                  }}
                </FormattedMessage>
                {preload?.leader?.map((data) => (
                  <option value={data.id}>{data.fname} {data.lname}</option>
                ))}
              </select>
            </div>

            <div className="col-md-6  mt-3">
              <FormattedMessage id="form.date" />
              <input
                className="form-control"
                type="date"
                {...register("date", { required: true })}
              />
            </div>


            <div className="col-md-6  mt-3">
              <FormattedMessage id="tabela.driver" />
              <select
                className="form-select"
                {...register("userid", { required: true })}
                onChange={(e) => changeSelectDriver(e)}
              >
                <FormattedMessage id="form.select">
                  {(placeholder) => {
                    return <option value="">{placeholder}</option>;
                  }}
                </FormattedMessage>
                {preload?.driver?.map((data) => (
                  <option value={data.id}>{data.fname} {data.lname}</option>
                ))}
              </select>
            </div>

            <div className="col-md-6  mt-3">
              <FormattedMessage id="type" />
              <select {...register("typeid", { required: true })} className="form-select">
                <FormattedMessage id="form.select">
                  {(placeholder) => {
                    return <option value="">{placeholder}</option>;
                  }}
                </FormattedMessage>
                {preload?.type?.map((data) => (
                  <option value={data.id}>{data.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="description" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                {...register("description", { required: true, min: 1 })}
              />
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="prior" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                {...register("prior", { required: true, min: 1 })}
              />
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="note" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                {...register("note", { required: true, min: 1 })}
              />
            </div>


            <div className="col-md-12  mt-3">
              <FormattedMessage id="action" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                {...register("action")}
              />
            </div>

            <div className="col-md-12  mt-3">
              <FormattedMessage id="failure" />
              <textarea className="form-control"
                rows="2"
                style={{ resize: 'none' }}
                maxlength="2048"
                {...register("failure")}
              />
            </div>

          </div>
          <div className="row">
            <div className="col-md-12  mt-4">
              <p style={{ 'fontWeight': 'bold', 'fontSize': '18px' }}><FormattedMessage id="issues" /></p>
            </div>
            <div className="col-md-6">
              <button
                disabled={!driverid}
                className="btn btn-success w-100 m-0"
                onClick={(e) => openModalIssues(e)}
                style={{
                  backgroundColor: 'blue',
                  borderColor: 'transparent',
                  width: "35px",
                  display: 'inline',
                  marginRight: '15px',
                  padding: '8.5',
                  float: 'right'
                }}
              >

                <FormattedMessage id="select_issues" />
              </button>
            </div>

            <div className="col-md-6">
              <button
                disabled={!driverid}
                className="btn btn-success w-100 m-0"
                onClick={(e) => openModalNew(e)}
                style={{
                  backgroundColor: 'blue',
                  borderColor: 'transparent',
                  width: "35px",
                  display: 'inline',
                  marginRight: '15px',
                  padding: '8.5',
                  float: 'right'
                }}
              >
                <FormattedMessage id="add_issue" />
              </button>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12 col-12">
              {selected.length > 0 &&

                <div className="table-container">
                  <table className="table table-list btn100">
                    <thead>
                      <tr>
                        <th scope="col"><FormattedMessage id="tabela.date" /></th>
                        <th scope="col"><FormattedMessage id="tabela.driver" /></th>
                        <th scope="col"><FormattedMessage id="description" /></th>
                        <th scope="col"><FormattedMessage id="type" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selected.map((item) => {
                        return (
                          <tr>
                            <td>{item.date}</td>
                            <td>
                              {preloadIssue?.driver?.filter(x => x.statusid == 1 && item.userid == x.id).map((data) => (
                                <div key={data.id}>{data.fname + ' ' + data.lname}</div>
                              ))}
                            </td>
                            <td>{item.description}</td>
                            <td>{preloadIssue.issueType && preloadIssue.issueType[item.typeid]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              }

            </div>
          </div>

          <div className="row">

            <div className="col-md-12  mt-3">
              <div className="form-check">
                <label
                  className="form-check-label"
                  htmlFor="RefusedInput"
                >
                  <FormattedMessage id="refused" />
                </label>
                <input
                  {...register("refused")}
                  className="form-check-input"
                  type="checkbox"
                  id="RefusedInput"
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row-reverse bd-highlight">

            <FormattedMessage id="edit_save">
              {(edit_save) => {
                return (
                  <button type="submit"
                    className="btn btn-orange btn-confirm float-end"
                    disabled={!selected.length}
                  >
                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                    {id ? edit_save.edit : edit_save.save}
                  </button>
                );
              }}
            </FormattedMessage>
            {id ? (
              <button
                className="btn btn-danger btn-confirm"
                onClick={(e) => DeleteCounseling(e, id)}
              >
                <FormattedMessage id="delete" />
              </button>
            ) : null}

            {id ? (
              <button
                className="btn btn-orange btn-confirm"
                onClick={(e) => print(e)}
              >
                <FormattedMessage id="print" />
              </button>
            ) : null}
            <button
              className="btn btn-secondary btn-confirm"
              onClick={() => history.push("/counseling/list")}
            >
              <FormattedMessage id="form.cancel" />

            </button>
          </div>
        </form>
      </div>

    </div>

  );
}



function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;
  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");

}
