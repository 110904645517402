import React, { useEffect, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faHome,
  faIdCard,
  faMobileAlt,
  faTruck,
  faUser,
  faHistory,
  faSignOutAlt,
  faTachometerAlt,
  faKey,
  faShieldAlt,
  faCommentAlt,
  faFileImport
} from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import api, { GetToken } from "../api/axios";
import jwt_decode from "jwt-decode";

export default function LeftMenu() {
  const history = useHistory();
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState([]);
  const [configClicked, setConfigClicked] = useState(false);

  useEffect(() => {
    const currentLang = localStorage.getItem("language");

    api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        setCountry(data.data);
      });

    if (currentLang === "null") {
    } else {
      setLanguage(currentLang);
    }
  }, []);

  const menusAdmin1 = [
    {
      name: <FormattedMessage id="menu.dashboard" />,
      icon: faHome,
      page: "/dashboard",
    },
    {
      name: <FormattedMessage id="menu.rosters" />,
      icon: faHistory,
      page: "/roster",
    },
    {
      name: <FormattedMessage id="menu.scorecard" />,
      icon: faTachometerAlt,
      page: "/scorecard",
    }
  ];

  const menusAdmin2 = [
    {
      name: <FormattedMessage id="menu.gates" />,
      icon: faKey,
      page: "/gates",
    },
    {
      name: 'DAs',
      icon: faIdCard,
      page: "/drivers",
    },
    {
      name: <FormattedMessage id="menu.vehicles" />,
      icon: faTruck,
      page: "/vehicles",
    },
    {
      name: <FormattedMessage id="menu.devices" />,
      icon: faMobileAlt,
      page: "/devices",
    },
    //{
    //  name: <FormattedMessage id="menu.users" />,
    //  icon: faUser,
    //  page: "/users",
    // },
  ];


  const menusDrivers = [
    {
      name: <FormattedMessage id="menu.dashboard" />,
      icon: faHome,
      page: "/dashboard",
    },
    {
      name: <FormattedMessage id="menu.scorecard" />,
      icon: faTachometerAlt,
      page: "/scorecard",
    },
    {
      name: <FormattedMessage id="counseling" />,
      icon: faCommentAlt,
      page: "/counseling/list",
    },
    {
      name: <FormattedMessage id="menu.gates" />,
      icon: faKey,
      page: "/gates",
    }

  ];

  const noMenuPages = ["", "login", "forgot_password", "define"];

  var pathName = useLocation().pathname;

  if (noMenuPages.includes(pathName.split("/")[1])) {
    return null;
  }

  function Logout() {
    localStorage.setItem("token", null);
    history.push("/");
  }

  const token = GetToken();

  if (token === "null") {
    history.push("/");
    return;
  }

  const userInfo = jwt_decode(token);

  if (userInfo.role === 1) {
    return (
      <div className="col-md-2">
        <nav
          className="navbar navbar-dark bg-dark pt-3 collapse left-menu"
          id="navbarSupportedContent"
          style={{ alignItems: "baseline" }}
        >
          <div className="container" id="leftMenusContainer">
            {menusDrivers.map((menu) => {
              const isSelected =
                pathName.includes(menu.page) && !pathName.includes("params");
              return (
                <Link
                  className="menu-item-container"
                  to={menu.page}
                  key={menu.page}
                  onClick={() => {
                    setConfigClicked(false);
                    console.log("close menus");
                    document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show");
                    document
                      .getElementById("menuConfig")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("params-collapse")
                      ?.classList.remove("show");
                  }}
                  style={
                    isSelected && !configClicked
                      ? {
                        backgroundColor: "var(--button-primary)",
                        color: "white",
                      }
                      : { color: "black" }
                  }
                >
                  <div className="col-4 col-sm-3">
                    <FontAwesomeIcon
                      icon={menu.icon}
                      alt="Menu icon"
                      color={isSelected && !configClicked ? "white" : "black"}
                      size="2x"
                    />
                  </div>
                  <div className="col-8 col-sm-9 d-block">
                    <span className="text-left float-start">{menu.name}</span>
                  </div>
                </Link>
              );
            })}

          </div>
        </nav>
      </div>
    );
  } else {
    return (
      <div className="col-md-2">
        <nav
          className="navbar navbar-dark bg-dark pt-3 collapse left-menu"
          id="navbarSupportedContent"
          style={{ alignItems: "baseline" }}
        >
          <div className="container" id="leftMenusContainer">
            {menusAdmin1.map((menu) => {
              const isSelected = pathName.includes(menu.page) && !pathName.includes("imports");
              return (
                <Link
                  className="menu-item-container"
                  to={menu.page}
                  key={menu.page}
                  onClick={() => {
                    setConfigClicked(false);
                    document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show");
                    document
                      .getElementById("menuConfig")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("params-collapse")
                      ?.classList.remove("show");
                    document
                      .getElementById("menuConfig2")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("coaching-collapse")
                      ?.classList.remove("show");
                    document
                      .getElementById("menuConfig3")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("counseling-collapse")
                      ?.classList.remove("show");
                  }}
                  style={
                    isSelected && !configClicked
                      ? {
                        backgroundColor: "var(--button-primary)",
                        color: "white",
                      }
                      : { color: "black" }
                  }
                >
                  <div className="col-4 col-sm-3">
                    <FontAwesomeIcon
                      icon={menu.icon}
                      alt="Menu icon"
                      color={isSelected && !configClicked ? "white" : "black"}
                      size="2x"
                    />
                  </div>
                  <div className="col-8 col-sm-9 d-block">
                    <span className="text-left float-start">{menu.name}</span>
                  </div>
                </Link>
              );
            })}


            <div
              id="menuConfig2"
              className="menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0"
              style={
                pathName.includes("/coaching")
                  ? { backgroundColor: "var(--button-primary)", color: "white" }
                  : {}
              }
              data-bs-toggle="collapse"
              data-bs-target="#coaching-collapse"
              aria-expanded="false"
            >
              <div className="col-4 col-sm-3">
                <FontAwesomeIcon
                  icon={faShieldAlt}
                  alt="Menu icon"
                  color={pathName.includes("/coaching") ? "white" : "black"}
                  size="2x"
                />
              </div>
              <div className="col-8 col-sm-9 d-block">
                <span className="text-left float-start">
                  <FormattedMessage id="menu.coaching" />
                </span>
              </div>
            </div>
            <div className="collapse w-100" id="coaching-collapse">
              <ul className="list-unstyled w-100">
                {/* 
                <li>
                  <Link
                    className="dropdown-item"
                    to="/coaching/import"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/import")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.import" />
                  </Link>
                </li>
                */}
                <li>
                  <Link
                    className="dropdown-item"
                    to="/coaching/messages"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/messages")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.messages" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/coaching/write-up"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/write-up")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="write-up" />
                    
                  </Link>
                </li>

                {/*
                <li>
                  <Link
                    className="dropdown-item"
                    to="/coaching/ranking"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/ranking")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.ranking" />
                  </Link>
                </li>
                */}
              </ul>
            </div>
            {/*
            <div
              id="menuConfig3"
              className="menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0"
              style={
                pathName.includes("/counseling") &&  !pathName.includes("/params")
                  ? { backgroundColor: "var(--button-primary)", color: "white" }
                  : {}
              }
              data-bs-toggle="collapse"
              data-bs-target="#counseling-collapse"
              aria-expanded="false"
            >
              <div className="col-4 col-sm-3">
                <FontAwesomeIcon
                  icon={faCommentAlt}
                  alt="Menu icon"
                  color={pathName.includes("/counseling") &&  !pathName.includes("/params") ? "white" : "black"}
                  size="2x"
                />
              </div>
              <div className="col-8 col-sm-9 d-block">
                <span className="text-left float-start">
                  <FormattedMessage id="counseling" />
                </span>
              </div>
            </div>
            <div className="collapse w-100" id="counseling-collapse">
              <ul className="list-unstyled w-100">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/counseling/issue"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/issue") &&  !pathName.includes("/params")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="issue" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/counseling/list"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/list") &&  !pathName.includes("/params")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="counseling" />
                  </Link>
                </li>
              </ul>
            </div>
          */}


            <Link
              className="menu-item-container hide-desktop"
              to="/imports"
              key="/imports"
              style={
                pathName.includes("imports")
                  ? {
                    backgroundColor: "var(--button-primary)",
                    color: "white",
                  }
                  : { color: "black" }
              }
            >
              <div className="col-4 col-sm-3">
                <FontAwesomeIcon
                  icon={faFileImport}
                  alt="Menu icon"
                  color={pathName.includes("imports") ? "white" : "black"}
                  size="2x"
                />
              </div>
              <div className="col-8 col-sm-9 d-block">
                <span className="text-left float-start">Imports</span>
              </div>
            </Link>

            {menusAdmin2.map((menu) => {
              const isSelected =
                pathName.includes(menu.page);
              return (
                <Link
                  className="menu-item-container"
                  to={menu.page}
                  key={menu.page}
                  onClick={() => {
                    setConfigClicked(false);
                    console.log("close menus");
                    document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show");
                    document
                      .getElementById("menuConfig")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("params-collapse")
                      ?.classList.remove("show");
                    document
                      .getElementById("menuConfig2")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("menuConfig3")
                      ?.classList.add("collapsed");
                    document
                      .getElementById("coaching-collapse")
                      ?.classList.remove("show");
                    document
                      .getElementById("counseling-collapse")
                      ?.classList.remove("show");
                  }}
                  style={
                    isSelected && !configClicked
                      ? {
                        backgroundColor: "var(--button-primary)",
                        color: "white",
                      }
                      : { color: "black" }
                  }
                >
                  <div className="col-4 col-sm-3">
                    <FontAwesomeIcon
                      icon={menu.icon}
                      alt="Menu icon"
                      color={isSelected && !configClicked ? "white" : "black"}
                      size="2x"
                    />
                  </div>
                  <div className="col-8 col-sm-9 d-block">
                    <span className="text-left float-start">{menu.name}</span>
                  </div>
                </Link>
              );
            })}

            <div
              id="menuConfig"
              className="menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0 hide-desktop"
              style={
                pathName.includes("/params")
                  ? { backgroundColor: "var(--button-primary)", color: "white" }
                  : {}
              }
              data-bs-toggle="collapse"
              data-bs-target="#params-collapse"
              aria-expanded="false"
            >
              <div className="col-4 col-sm-3">
                <FontAwesomeIcon
                  icon={faCog}
                  alt="Menu icon"
                  color={pathName.includes("/params") ? "white" : "black"}
                  size="2x"
                />
              </div>
              <div className="col-8 col-sm-9 d-block">
                <span className="text-left float-start">
                  <FormattedMessage id="menu.configurations" />
                </span>
              </div>
            </div>
            <div className="collapse w-100" id="params-collapse">
              <ul className="list-unstyled w-100">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/carrier"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/carrier")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.carrier" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/counseling"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/counseling") && pathName.includes("/params")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="counseling" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/company"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/company")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.company" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/driver-status"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/driver-status")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    DAs Status
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/issue"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/issue") && pathName.includes("/params")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="issue" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/ownership"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/ownership")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.ownership" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/roster-status"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/roster-status")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.roster_status" />
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="/users"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/users")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.users" />
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/vehicle-status"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/vehicle-status")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.vehicles_status" />
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="/params/vendor"
                    onClick={document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show")}
                    style={
                      pathName.includes("/vendor")
                        ? { color: "var(--button-primary" }
                        : {}
                    }
                  >
                    <FormattedMessage id="menu.vendor" />
                  </Link>
                </li>
              </ul>
            </div>

            <div
              className="menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0 hide-desktop"
              data-bs-toggle="collapse"
              data-bs-target="#account-collapse"
              aria-expanded="false"
            >
              <div className="col-4">
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  size="2x"
                  alt="Menu icon"
                />
              </div>
              <div className="col-8 d-block">
                <span className="text-left float-start" onClick={Logout}>
                  <FormattedMessage id="logout" />
                </span>
              </div>
            </div>

            <div
              className="menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0 hide-desktop"
              data-bs-toggle="collapse"
              data-bs-target="#account-collapse"
              aria-expanded="false"
            >
              <li className="nav-item dropdown" style={{ display: "block" }}>
                <button
                  className="button-link nav-link dropdown-toggle"
                  id="navbarDropdownLocale"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span style={{ marginRight: 10 }}>
                    {country.length !== 0 && (
                      <span style={{ marginRight: 10 }}>
                        {
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/" +
                              country?.find((x) => x.code === language)?.code +
                              ".png"
                            }
                            alt="flag icon"
                          />
                        }
                      </span>
                    )}
                    {country.length === 0 && <span>loading</span>}
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  style={{ minWidth: 0 }}
                  aria-labelledby="navbarDropdownLocale"
                >
                  {country?.map((language) => {
                    return (
                      <li key={language.code}>
                        <button
                          className="button-link dropdown-item"
                          onClick={() => {
                            localStorage.setItem("language", language.code);
                            document.location.reload();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/" +
                              language.code +
                              ".png"
                            }
                            alt="flag icon"
                            style={{ marginRight: 5, color: "black" }}
                          />
                          {language.code}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
