import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
import { faClock, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading";

export default function PodEdit() {

  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [driverid, setDriverid] = useState("");
  const [driver, setDriver] = useState([]);

  let { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {

    if (id) {
      //edit
      api
        .put(
          "pod-user/update/" + id,
          {
            year: data.year,
            week: data.week,
            opport: data.opport,
            success: data.success,
            bypass: data.bypass,
            rejects: data.rejects,
            blurry: data.blurry,
            human: data.human,
            nopack: data.nopack,
            incar: data.incar,
            inhand: data.inhand,
            hide: data.hide,
            close: data.close,
            dark: data.dark,
            other: data.other,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          // title: "Success",
          //}).then(() => {
          history.push("/drivers/pod/list/" + driverid);
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {

    }

  }


  useEffect(() => {

    if (id) {
      api
        .get("pod-user/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          const value = data.data[0];
          setValue("year", value.year);
          setValue("week", value.week);
          setValue("opport", value.opport);
          setValue("success", value.success);
          setValue("bypass", value.bypass);
          setValue("rejects", value.rejects);
          setValue("blurry", value.blurry);
          setValue("human", value.human);
          setValue("nopack", value.nopack);
          setValue("incar", value.incar);
          setValue("inhand", value.inhand);
          setValue("hide", value.hide);
          setValue("close", value.close);
          setValue("dark", value.dark);
          setValue("other", value.other);
          setDriverid(value.userid);
          api
            .get("driver/getbyid/" + value.userid, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              setDriver(data.data[0]);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response?.data.message,
              });
            });

        })
        .catch((err) => {
          console.log(err.response);
        });

    }


  }, [id, setValue]);




  return (
    <div className="row">
      <Loading visible={loading} />
      <div className="col-12 col-md-12 mb-2">
        <h5>
          POD <FormattedMessage id="tabela.driver" />:
          <a onClick={() => history.push("/drivers/edit/" + driverid)} style={{ 'marginLeft': '15px', 'cursor': 'pointer', 'textDecoration': 'underline', 'color': "#0d6efd" }}>
            {driver.fname} {driver.lname}
          </a>
        </h5>
      </div>
      <div className="col-md-12 col-12">
        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
          <div className="row">
            <div className="col-md-3 mt-3">
              <FormattedMessage id="form.year" />
              <input
                className="form-control"
                type="number"
                maxlength="4"
                {...register("year", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="week" />
              <input
                className="form-control"
                type="number"
                min="1" max="53" step="1"
                {...register("week", { required: true })}
              />
            </div>


            <div className="col-md-3  mt-3">
              <FormattedMessage id="opport" />
              <input
                className="form-control"
                type="text"
                {...register("opport", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="success" />
              <input
                className="form-control"
                type="text"
                {...register("success", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="bypass" />
              <input
                className="form-control"
                type="text"
                {...register("bypass", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="rejects" />
              <input
                className="form-control"
                type="text"
                {...register("rejects", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="blurry" />
              <input
                className="form-control"
                type="text"
                {...register("blurry", { required: true })}
              />
            </div>


            <div className="col-md-3  mt-3">
              <FormattedMessage id="human" />
              <input
                className="form-control"
                type="text"
                {...register("human", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="nopack" />
              <input
                className="form-control"
                type="text"
                {...register("nopack", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="incar" />
              <input
                className="form-control"
                type="text"
                {...register("incar", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="inhand" />
              <input
                className="form-control"
                type="text"
                {...register("inhand", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="hide" />
              <input
                className="form-control"
                type="text"
                {...register("hide", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="close" />
              <input
                className="form-control"
                type="text"
                {...register("close", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="dark" />
              <input
                className="form-control"
                type="text"
                {...register("dark", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="other" />
              <input
                className="form-control"
                type="text"
                {...register("other", { required: true })}
              />
            </div>

          </div>

          <div className="d-flex flex-row-reverse bd-highlight">

            <FormattedMessage id="edit_save">
              {(edit_save) => {
                return (
                  <button type="submit"
                    className="btn btn-orange btn-confirm float-end"
                  >
                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                    {id ? edit_save.edit : edit_save.save}
                  </button>
                );
              }}
            </FormattedMessage>
            <button
              className="btn btn-secondary btn-confirm"
              onClick={() => history.push("/drivers/pod/list/" + driverid)}
            >
              <FormattedMessage id="form.cancel" />
            </button>
          </div>
        </form>
      </div>

    </div>

  );
}



function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;
  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");

}
