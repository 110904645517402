import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import api, { GetToken } from "../../../api/axios";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faAngleLeft,
  faAngleRight,
  faSync,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import Modal from 'react-modal';

export default function List() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState([]);
  const [date, setDate] = useState("");
  const [visibleScoreModal, setVisibleScoreModal] = useState(false);

  useEffect(() => {
    UpdateList();
  }, [date]);

  function UpdateList() {
    setLoading(true);
    if (date == "") {
      api
        .get("coaching-message/getpreload", { headers: { "x-access-token": GetToken() } })
        .then((preloadApi) => {
          console.log(preloadApi.data);
          setMessages(preloadApi.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get("coaching-message/getbysentdate/" + date, { headers: { "x-access-token": GetToken() } })
        .then((preloadApi) => {
          setMessages(preloadApi.data);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function DecreaseDate() {
    setDate(moment((date != "" ? date : moment().format("YYYY-MM-DD"))).subtract(1, "days").format("YYYY-MM-DD"));
  }

  function IncreaseDate() {
    setDate(moment((date != "" ? date : moment().format("YYYY-MM-DD"))).add(1, "days").format("YYYY-MM-DD"));
  }


  function OpenMessage(id) {
    let message = messages.filter(el => el.id === id);
    setMessage(message[0]);
    setVisibleScoreModal(true);
  }

  function handleVisibleModal(value) {
    setVisibleScoreModal(value);
  }

  return (
    <div>
      <Modal isOpen={visibleScoreModal} >
        <div className="row">
          <div className="col-md-12 mt-1">
            <h5><b><FormattedMessage id="tabela.driver" /></b>: {message.fname} {message.lname}</h5>
          </div>
          <div className="col-md-12 mt-1">
            <p><b><FormattedMessage id="tabela.date" /></b> : {message.tripdate}</p>
          </div>
          <div className="col-md-12 mt-1">
            <label><b><FormattedMessage id="opportunities" /></b></label>
            <p style={{ 'whiteSpace': 'pre-line' }}>{message.opportunities}</p>
          </div>
          <div className="col-md-12 mt-1">
            <label><b><FormattedMessage id="reinforcement" /></b></label>
            <p style={{ 'whiteSpace': 'pre-line' }}> {message.reinforcement}</p>
          </div>
          <div className="col-md-12 mt-1">
            <label><b><FormattedMessage id="sms_text" /></b></label>
            <p style={{ 'whiteSpace': 'pre-line' }}>{message.smstext}</p>
          </div>
          <div className="col-md-12 mt-1">
            <p><b>Status</b>:  {message.sentstatus == 1 ? 'Queued' : 'Sent'}</p>
          </div>
        </div>
        <button
          className="btn btn-secondary btn-confirm float-end"
          onClick={() => {
            setVisibleScoreModal(false);
          }}
        >
          <FormattedMessage id="close" />
        </button>
      </Modal>
      <div className="row">
        <div className="col-md-7 col-8">
          <p className="title  title-mobile">
            <FormattedMessage id="sent_messages" />
          </p>
        </div>
        <div className="col-md-1 col-4  mb-2">
          <button
            className="btn btn-success  btn100"
            onClick={() => UpdateList()}
            style={{
              backgroundColor: 'blue',
              borderColor: 'transparent',
              width: "35px",
              display: 'inline',
              marginRight: '15px',
              padding: '8.5',
              float: 'right'
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </button>
        </div>
        <div className="col-md-4 col-12  mb-2">
          <div
            onClick={DecreaseDate}
            style={{
              display: "inline",
              paddingTop: 7,
              paddingBottom: 7,
              width: "10%",
              color: "#fff",
              borderRadius: "5px 0 0 5px",
              float: "left",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#808080",
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" />
          </div>

          <input
            type="date"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => setDate(formatDate(e.target.value))}
            value={date}
            className="form-control col"
            style={{
              display: "inline-block",
              width: "80%",
              borderRadius: 0,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          />

          <div
            onClick={IncreaseDate}
            style={{
              display: "inline",
              paddingTop: 7,
              paddingBottom: 7,
              width: "10%",
              color: "#fff",
              borderRadius: "  0 5px 5px 0",
              float: "right",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#808080",
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} size="lg" />
          </div>
        </div>
      </div>
      <div className="table-responsive ">
        <Loading visible={loading} />
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col" >
                <FormattedMessage id="tabela.date" />
              </th>
              <th scope="col" >
                <FormattedMessage id="tabela.driver" />
              </th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => {
              return (
                <tr key={message.id} >
                  <td>{message.tripdate}</td>
                  <td>{message.fname} {message.lname}</td>
                  <td>{message.sentstatus == 1 ? 'Queued' : 'Sent'}</td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => OpenMessage(message.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}


function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;

  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  console.log("formated date", [year, month, day].join("-"));
  return [year, month, day].join("-");
}
