import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import List from "./list";
import Import from "./import";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faPlus,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import AddEdit from "./addEdit";
import RosterConfirm from "./rosterConfirm";
import Loading from "../../components/Loading";


export default function Roster() {
  var pathName = useLocation().pathname;

  const history = useHistory();
  const [roster, setRoster] = useState();
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastOrder, setLastOrder] = useState('asc');
  const [lastField, setLastField] = useState('time');
  const [search, setSearch] = useState("");
  const [meta, setMeta] = useState("");

  useEffect(() => {
    const date = moment().format("YYYY-MM-DD");
    setDate(date);
    api
      .get("roster-meta/getbydate/" + date, { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setMeta(data.data[0] !== undefined ? data.data[0].notes : "");
      }).catch(err => {
        console.log(err);
      });
  }, []);

  function DecreaseDate() {
    setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"));
    api
      .get("roster-meta/getbydate/" + moment(date).subtract(1, "days").format("YYYY-MM-DD"), { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setMeta(data.data[0] !== undefined ? data.data[0].notes : "");

      }).catch(err => {
        console.log(err);
      });
    setLastOrder('asc');
    setLastField('time');
  }

  function IncreaseDate() {
    setDate(moment(date).add(1, "days").format("YYYY-MM-DD"));

    api
      .get("roster-meta/getbydate/" + moment(date).add(1, "days").format("YYYY-MM-DD"), { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        setMeta(data.data[0] !== undefined ? data.data[0].notes : "");
      }).catch(err => {
        console.log(err);
      });
    setLastOrder('asc');
    setLastField('time');
  }

  //const throttled = useRef(throttle((newValue) => {
  //  api.post("roster-meta/save/" + date, { notes: newValue }, { headers: { "x-access-token": GetToken() } })
  //    .then((data) => {
  //      console.log(data);
  //    }).catch(err => {
  //      console.log(err);
  //    });
  //}, 1000))

  //useEffect(() => throttled.current(meta), [meta])

  const changeHandler = (e) => {
    setMeta(e.target.value);
  };

  const blurHandler = (e) => {
    if (date !== "") {
      setMeta(e.target.value);
      api.post("roster-meta/save/" + date, { notes: meta }, { headers: { "x-access-token": GetToken() } })
        .then((data) => {
          console.log(data);
        }).catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (date !== "") {
      const callSearchService = () => {
        api.post("roster-meta/save/" + date, { notes: meta }, { headers: { "x-access-token": GetToken() } })
          .then((data) => {
            console.log(data);
          }).catch(err => {
            console.log(err);
          });
      }

      let debouncer = setTimeout(() => {
        callSearchService();
      }, 3000);

      return () => {
        clearTimeout(debouncer);
      }

    }

  }, [meta]);


  function print(type) {
    let data = {
      date: moment(date).format("YYYY-MM-DD"),
      type: type,
      field: lastField,
      order: lastOrder,
    };

    setLoading(true);

    api
      .post("pdf/generate", data, {
        responseType: "arraybuffer",
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        var file = new Blob([data.data], { type: "application/pdf" });
        // Create a link pointing to the ObjectURL containing the blob.
        var fileURL = URL.createObjectURL(file, { oneTimeOnly: true });
        var link = document.createElement("a");
        link.href = fileURL;
        link.download = "Rosters " + moment(date).format("YYYY-MM-DD") + ".pdf";
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 100);
        //window.open(fileURL);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally((_) => {
        setLoading(false);
      });
  }

  return (
    <Switch>
      <div className="container mt-3">
        <Loading visible={loading} />

        {!pathName.includes("import/confirm") && (
          <div className="row">
            <div className="col-md-12">
              <p className="title">
                <FormattedMessage id="menu.rosters" />
              </p>
            </div>
          </div>
        )}
        {!pathName.includes("add") &&
          !pathName.includes("edit") &&
          !pathName.includes("import") && (
            <div className="row mb-3">
              <div className="col-md-4 col-12  mb-2">
                <div
                  onClick={DecreaseDate}
                  style={{
                    display: "inline",
                    paddingTop: 7,
                    paddingBottom: 7,
                    width: "10%",
                    color: "#fff",
                    borderRadius: "5px 0 0 5px",
                    float: "left",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#808080",
                  }}
                >
                  <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                </div>

                <input
                  type="date"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                  className="form-control col"
                  style={{
                    display: "inline-block",
                    width: "80%",
                    borderRadius: 0,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                />

                <div
                  onClick={IncreaseDate}
                  style={{
                    display: "inline",
                    paddingTop: 7,
                    paddingBottom: 7,
                    width: "10%",
                    color: "#fff",
                    borderRadius: "  0 5px 5px 0",
                    float: "right",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#808080",
                  }}
                >
                  <FontAwesomeIcon icon={faAngleRight} size="lg" />
                </div>
              </div>

              <div className="col-md-4 col-12 mb-2">
                <FormattedMessage id="form.search">
                  {(placeholder) => {
                    return (
                      <input
                        className="form-control"
                        placeholder={placeholder}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    );
                  }}
                </FormattedMessage>
              </div>

              <div className="col-md-2 col-4 mb-2">
                <button
                  className="btn btn-orange btn100"
                  style={{ backgroundColor: "gray" }}
                  id="dropdownMenuPrint"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faPrint} className="btn-icon" />
                  <FormattedMessage id="print" />
                </button>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuPrint">
                  <li>
                    <button
                      class="dropdown-item"
                      onClick={() => print("enlarged")}
                    >
                      <FormattedMessage id="enlarged" />
                    </button>
                  </li>

                  <li>
                    <button
                      class="dropdown-item"
                      onClick={() => print("reduced")}
                    >
                      <FormattedMessage id="reduced" />
                    </button>
                  </li>
                </ul>
              </div>
               {/* 
              <div className="col-md-2 col-4 mb-2">
                <button
                  className="btn btn-orange btn100"
                  style={{ backgroundColor: "blue" }}
                  onClick={() => history.push("/roster/import")}
                >
                  <FontAwesomeIcon icon={faFileImport} className="btn-icon" />
                  <FormattedMessage id="import" />
                </button>
              </div>
            */} 
              <div className="col-md-2 col-4 mb-2">
                <button
                  className="btn btn-orange btn100"
                  onClick={() => history.push("/roster/add")}
                >
                  <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                  <FormattedMessage id="form.create" />
                </button>
              </div>

              <div className="col-md-12 col-12 mb-1 mt-2">
                <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px' }}>
                  <textarea className="form-control"
                    placeholder="Notes"
                    rows="2"
                    style={{ resize: 'none' }}
                    maxlength="2048"
                    onChange={changeHandler}
                    onBlur={blurHandler}
                    value={meta}
                  />
                </div>
              </div>

            </div>

          )}
        {(pathName.includes("/roster") && !pathName.includes("/add") && !pathName.includes("/edit") && !pathName.includes("/confirm") && !pathName.includes("/import")) &&
          <List date={date} setLastOrder={setLastOrder} setLastField={setLastField} search={search} />}

        <Route
          path="/roster/import"
          exact
          component={(props) => <Import setRosterMethod={setRoster} />}
        />
        <Route path="/roster/add" component={AddEdit} />
        <Route path="/roster/edit/:id" component={AddEdit} />
        <Route
          path="/roster/import/confirm"
          component={(props) => <RosterConfirm roster={roster} />}
        />
      </div>
    </Switch >
  );
}

function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;

  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  console.log("formated date", [year, month, day].join("-"));
  return [year, month, day].join("-");
}
