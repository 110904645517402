import React, { useEffect, useState } from "react";
import {
    faBell,
    faEdit,
    faTrash,
    faClock,
    faCalendarAlt,
    faMobileAlt,
    faMailBulk,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import api, { GetToken } from "../../api/axios";
import "date-fns";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Fragment } from "react";

export default function ListItem({ preload, roster, timeMask, rosters, setRosterById, date, UpdateList, messages, deleteRosterById,openModal}) {
    const history = useHistory();

    useEffect(() => {
        const current = new Date();
        const dateTimeTwo = new Date(
            `${current.getFullYear()}/${current.getMonth() + 1
            }/${current.getDate()} ${roster.time}`
        );
        //console.log('preload',preload);

    }, [roster, rosters, date]);

    if (rosters == undefined) {
        return <div></div>
    }

    if (roster == undefined) {
        return <div></div>
    }

    function tConvert(time) {

        if (timeMask !== "12H") {
            return time;
        }
        // Check correct time format and split into components
        time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
    }

    return (
        <tr>
            <td>
                <div class="form-check">
                    <input name="notificationApp" class="form-check-input" type="checkbox" checked={roster.active}
                        onClick={(e) => {
                            roster.active = e.target.checked;
                            setRosterById(roster.id, roster);
                        }} id="notificationAppInput" />
                </div>
            </td>
            <td>
                {timeMask === "12H" ? (
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        value={new Date(
                            `${new Date().getFullYear()}/${new Date().getMonth() + 1
                            }/${new Date().getDate()} ${roster.time}`
                        )}
                        onChange={(e) => {
                            UpdateRoster(e, "time");
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "change time",
                        }}
                        style={{ display: "inherit", margin: 0, padding: 0 }}
                        keyboardIcon={<FontAwesomeIcon icon={faClock} size="xs" />}
                    />
                ) : (
                    <input
                        type="time"
                        value={moment(roster.time, "HH:mm:ss").format("HH:mm")}
                        className="form-control"
                        onChange={(e) => {
                            //setTime(new Date("2021-01-01 " + e.target.value));
                            UpdateRoster(new Date("2021-01-01 " + e.target.value), "time");
                        }}
                    />
                )}
            </td>
            <td>
                <select
                    className="form-select"
                    onChange={(e) => {
                        UpdateRoster(e.target.value, "driverid");
                        UpdateRoster(preload?.driver?.filter(x => x.id == e.target.value)[0]?.vehicleid, 'vehicleid');
                        UpdateRoster(preload?.vehicle?.filter(x => x.id == preload?.driver?.filter(x => x.id == e.target.value)[0]?.vehicleid)[0]?.deviceid, 'deviceid');
                        UpdateRoster(preload?.vehicle?.filter(x => x.id == preload?.driver?.filter(x => x.id == e.target.value)[0]?.vehicleid)[0]?.space, 'space');
                    }}
                    style={{
                        borderColor: (rosters?.filter(x =>
                            x.driverid == roster.driverid)?.length > 1 || preload.driver.filter(x =>
                                x.statusid == 0 && x.id == roster.driverid)?.length == 1) ? 'red' : roster.driverid == 0 || roster.driverid == null ? 'red' : '#ced4da'
                    }}
                >
                    <option value="0"></option>
                    {preload?.driver?.filter(x => x.statusid == 1).map((data) => (
                        <option selected={roster.driverid == data.id} value={data.id}>
                            {data.fname} {data.lname}
                        </option>
                    ))}
                    <optgroup label="Disabled">
                        {preload?.driver?.filter(x => x.statusid == 0).map((data) => (
                            <option selected={roster.driverid == data.id} value={data.id}>
                                {data.fname} {data.lname}
                            </option>
                        ))}
                    </optgroup>
                </select>
            </td>
            <td>
                <button
                    class="btn dropdown-toggle"
                    style={{
                        color: "#333", borderColor: (rosters?.filter(x =>
                            x.vehicleid == roster.vehicleid)?.length > 1 || preload.vehicle.filter(x =>
                                x.id == roster.vehicleid)?.length == 0 || preload.vehicle.filter(x =>
                                    x.status !== 1 && x.id == roster.vehicleid)?.length == 1) ? 'red' : 
                                    roster.vehicleid == null ? 'red' : ((preload?.driver?.filter(x => x.id === roster.driverid && x.cdv === 0)?.length > 0 && 
                                    preload?.vehicle?.filter(x => x.id === roster.vehicleid && x.cdv === 1)?.length > 0)) ? "orange" : '#ced4da'
                    }}
                    type="button"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {preload?.vehicle?.find((x) => x.id == roster.vehicleid)?.name ==
                        undefined
                        ? "Select"
                        : preload?.vehicle?.find((x) => x.id == roster.vehicleid)?.name}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {preload?.driver?.find((driver) => driver.id == roster.driverid)?.vehicleid == undefined ? (null)
                        : <Fragment>
                            <li>
                                <div className="dropDownIndicator"><FormattedMessage id="primary" /></div>
                            </li>
                            <li>
                                <button
                                    class="dropdown-item"
                                    type="button"
                                    style={{
                                        color: rosters?.find(x => preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicleid
                                        )?.id == x.vehicleid) == undefined ? '#ced4da' : 'red'
                                    }}
                                    onClick={() => {
                                        UpdateRoster(
                                            preload?.vehicle?.find(
                                                (y) =>
                                                    y.id ==
                                                    preload?.driver?.find((x) => x.id == roster.driverid)
                                                        ?.vehicleid
                                            ).id,
                                            "vehicleid"
                                        );
                                        UpdateRoster(preload?.vehicle?.filter(x => x.id == preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicleid
                                        ).id)[0]?.space, 'space');
                                        UpdateRoster(preload?.vehicle?.filter(x => x.id == preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicleid
                                        ).id)[0].deviceid, 'deviceid');

                                    }}
                                >
                                    {preload?.driver?.find((x) => x.id == roster.driverid)
                                        ?.vehicleid == undefined
                                        ? null
                                        : preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicleid
                                        )?.name}
                                </button>
                            </li>
                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                        </Fragment>}

                    {preload?.driver?.find((x) => x.id == roster.driverid)
                        ?.vehicle2id == undefined ? (
                        null
                    ) : (
                        <Fragment>
                            <li>
                                <div className="dropDownIndicator">
                                    <FormattedMessage id="secondary" /></div>
                            </li>
                            <li>
                                <button
                                    class="dropdown-item"
                                    type="button"
                                    style={{
                                        color: rosters?.find(x => preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicle2id
                                        )?.id == x.vehicleid) == undefined ? '#ced4da' : 'red'
                                    }}


                                    onClick={() => {
                                        UpdateRoster(
                                            preload?.vehicle?.find(
                                                (y) =>
                                                    y.id ==
                                                    preload?.driver?.find(
                                                        (x) => x.id == roster.driverid
                                                    )?.vehicle2id
                                            ).id,
                                            "vehicleid"
                                        );

                                        UpdateRoster(preload?.vehicle?.filter(x => x.id == preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicle2id
                                        ).id)[0].space, 'space');

                                        UpdateRoster(preload?.vehicle?.filter(x => x.id == preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicle2id
                                        ).id)[0].deviceid, 'deviceid');


                                    }}
                                >
                                    {
                                        preload?.vehicle?.find(
                                            (y) =>
                                                y.id ==
                                                preload?.driver?.find((x) => x.id == roster.driverid)
                                                    ?.vehicle2id
                                        )?.name
                                    }
                                </button>
                            </li>
                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                        </Fragment>
                    )}

                    <li>
                        <div className="dropDownIndicator"><FormattedMessage id="not_yet_assigned" /></div>
                    </li>
                    {preload?.vehicle?.filter((x) => x.status == 1).map((x) =>
                        rosters.map((y) => y.vehicleid).includes(x.id) ? null : (
                            <li>
                                <button
                                    class="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                        UpdateRoster(x.id, "vehicleid");
                                        UpdateRoster(x.space, 'space');
                                        UpdateRoster(x.deviceid, 'deviceid');
                                    }}
                                >
                                    {x.name}
                                </button>
                            </li>
                        )
                    )}
                    <li>
                        <hr class="dropdown-divider" />
                    </li>

                    <li>
                        <div className="dropDownIndicator"><FormattedMessage id="already_assigned" /></div>
                    </li>
                    {preload?.vehicle?.filter((x) => x.status === 1).map((x) =>
                        rosters.map((y) => y.vehicleid).includes(x.id) ? (
                            <li>
                                <button
                                    class="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                        UpdateRoster(x.id, "vehicleid");
                                        UpdateRoster(x.space, "space")
                                        UpdateRoster(x.deviceid, "deviceid");
                                    }}
                                >
                                    {x.name}
                                </button>
                            </li>) : null
                    )}
                    <li>
                        <hr class="dropdown-divider" />
                    </li>

                    <li>
                        <div className="dropDownIndicator">
                            <FormattedMessage id="disabled" /></div>
                    </li>
                    {preload?.vehicle?.filter((x) => x.status !== 1).map((x) =>
                        //rosters.map((y) => y.vehicleid).includes(x.id) ? (
                        <li>
                            <button
                                class="dropdown-item"
                                type="button"
                                onClick={() => {
                                    UpdateRoster(x.id, "vehicleid");
                                    UpdateRoster(x.space, "space")
                                    UpdateRoster(x.deviceid, "deviceid");
                                }}
                            >
                                {x.name}
                            </button>
                        </li>
                        //) : null
                    )}

                </ul>
            </td>
            <td>
                <input
                    className="form-control"
                    value={roster.route}
                    onChange={(e) => {
                        UpdateRoster(e.target.value, "route");
                    }}
                />
            </td>
            <td style={{ display: "none" }}>
                <input
                    className="form-control"
                    value={roster.staging}
                    onChange={(e) => {
                        UpdateRoster(e.target.value, "staging");
                    }}
                />
            </td>
            <td style={{ display: "none" }}>
                <input
                    className="form-control"
                    value={roster.space}
                    onChange={(e) => {
                        UpdateRoster(e.target.value, "space");
                    }}
                />
            </td>
            <td>
                <select
                    className="form-select"
                    onChange={(e) => UpdateRoster(e.target.value, "statusid")}
                >
                    <option value="0"></option>
                    {preload?.status?.map((data) => (
                        <option value={data.id} selected={roster.statusid === data.id}>
                            {data.name}
                        </option>
                    ))}
                </select>
            </td>
            <td>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="btn-group dropstart">
                        <FontAwesomeIcon id="dropMessages" icon={faBell} className="mt-2"
                            color={messages && messages.filter(message => message.rosterid == roster.id).length == 0 ? '#ccc'
                                : messages.filter(message => message.rosterid == roster.id)
                                    .map(x =>
                                        x.result && Object.keys(JSON.parse(x.result).MessageResponse.Result)
                                            .map(key => JSON.parse(x.result).MessageResponse.Result[key].StatusCode)
                                    ).filter(e => e != 200).length !== 0 ? 'red' : 'black'}
                            disabled={messages && messages.filter(message => message.rosterid == roster.id).length == 0}
                            data-bs-toggle="dropdown" aria-expanded="false" />
                        <div class="dropdown-menu" aria-labelledby="dropMessages"
                            style={{ backgroundColor: 'transparent', borderColor: 'transparent', minWidth: 260 }}>
                            <ul style={{
                                backgroundColor: '#fff',
                                padding: 10,
                                fontSize: 12,
                                listStyle: 'none',
                                borderWidth: 1,
                                borderColor: '#acacac',
                                borderStyle: 'solid'
                            }}>
                                <li style={{ fontSize: 14, fontWeight: '600', marginBottom: 5 }}>

                                    <FormattedMessage id="driver_assignment_notification_record" /></li>

                                {messages && messages != undefined && messages.filter(message => message.rosterid == roster.id)?.map(x => {

                                    return (
                                        <Fragment>
                                            <li><FontAwesomeIcon icon={faCalendarAlt}
                                                style={{ marginRight: 4 }} /> {x.createdat.split(" ")[0]} {tConvert(x.createdat.split(" ")[1])}   </li>

                                            {!x.result && <li><FontAwesomeIcon icon={faMobileAlt} color='red'
                                                style={{ marginRight: 5 }} /> Error, no notification sent </li>}


                                            {x.result && Object.keys(JSON.parse(x.result).MessageResponse.Result).map(key => {
                                                if (JSON.parse(x.result).MessageResponse.Result[key].StatusCode !== 200) {
                                                    return (<li><FontAwesomeIcon icon={faMobileAlt} color='red'
                                                        style={{ marginRight: 5 }} /> Error: {JSON.parse(x.result).MessageResponse.Result[key].DeliveryStatus}</li>);
                                                } else {
                                                    return (
                                                        <Fragment>
                                                            <li><FontAwesomeIcon icon={faMobileAlt} color='green'
                                                                style={{ marginRight: 6 }} /> {JSON.parse(x.result).MessageResponse.Result[key].DeliveryStatus},
                                                                Sent to {key} </li>
                                                        </Fragment>
                                                    );
                                                }
                                            })}

                                            {x.emailid &&
                                                <li><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 5 }}
                                                    color='green' />SUCCESSFUL, Sent to email adress</li>}
                                            {!x.emailid && <li><FontAwesomeIcon icon={faMailBulk} color='red'
                                                style={{ marginRight: 5 }} />Error, no notification sent</li>}

                                            {messages.filter(message => message.rosterid == roster.id).length == 1 ? null :
                                                <hr style={{ marginTop: 10, marginBottom: 10 }} />}
                                        </Fragment>
                                    )

                                })}
                            </ul>
                        </div>
                    </div>


                    <FontAwesomeIcon
                        icon={faEdit}
                        className="mt-2"
                        onClick={() => history.push("/roster/edit/" + roster.id)}
                    />
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="mt-2"
                        onClick={() => DeleteRoster(roster.id)}
                    />
                </div>
            </td>
        </tr>
    );

    function WarningCDV(driverCDV, vehicleCDV) {

    }

    function UpdateRoster(e, item) {

        const data = {};

        if (item !== "time") {

            if (e == undefined || e == null) {
                console.log('undefined', e);
                return;
            }
            data[item] = e;
            roster[item] = e;
            setRosterById(roster.id, roster);

        } else {

            const time_formated =
                (e.getHours() < 10 ? "0" : "") +
                e.getHours() +
                ":" +
                (e.getMinutes() < 10 ? "0" : "") +
                e.getMinutes();

            if (time_formated.includes("NaN")) {
                alert("Invalid Time");
                return;
            }
            roster[item] = time_formated;
            setRosterById(roster.id, roster)
            data[item] = time_formated;
        }



        //console.log("update values:", data);

        api
            .put("roster/update/" + roster.id, data, {
                headers: { "x-access-token": GetToken() },
            })
            .then((result) => {
                // console.log(data.data);
                if (data.statusid !== undefined && data.statusid != 1) {
                    openModal(roster.id,data.statusid);
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.response?.data?.message,
                });
            });
    }

    function DropDownItem(title, name) {
        return (
            <Fragment>
                <li>
                    <div className="dropDownIndicator"><FormattedMessage id="default_secondary" /></div>
                </li>
                <li>
                    <button
                        class="dropdown-item"
                        type="button"
                        onClick={() =>
                            UpdateRoster(
                                preload?.vehicle?.find(
                                    (y) =>
                                        y.id ==
                                        preload?.driver?.find((x) => x.id == roster.driverid)
                                            ?.vehicle2id
                                ).id,
                                "vehicleid"
                            )
                        }
                    >
                        {preload?.driver?.find((x) => x.id == roster.driverid)
                            ?.vehicle2id == undefined
                            ? "not set"
                            : name}
                    </button>
                </li>
                <li>
                    <hr class="dropdown-divider" />
                </li>
            </Fragment>
        );
    }

    function DeleteRoster(id) {
        Swal.fire({
            title: "Confirm delete",
            text: "Are you sure you want to delete this item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#565e64",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                api
                    .delete("roster/delete/" + id, {
                        headers: { "x-access-token": GetToken() },
                    })
                    .then((data) => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Roster deleted",
                        }).then(() => {
                            deleteRosterById(id);
                        });
                    })
                    .catch((err) => {
                        if (err.response?.data?.message.includes('Invalid Token.')) {
                            localStorage.setItem('token', null);
                            history.push('/');
                        }
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: err.response?.data?.message,
                        });
                    });
            }
        });
    }
}
