import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import ImportMentor from "./importMentor";
import ImportNetradyne from "./importNetradyne";
import ImportPod from "./importPod";
import ImportCdf from "./importCdf";
import MentorConfirm from "./mentorConfirm";
import NetradyneConfirm from "./netradyneConfirm";
import CdfConfirm from "./cdfConfirm";
import PodConfirm from "./podConfirm";

import { FormattedMessage } from "react-intl";

export default function Import() {

  var pathName = useLocation().pathname;
  const history = useHistory();
  const [fileNameImport, setFileNameImport] = useState();
  const [mentor, setMentor] = useState();
  const [netradyne, setNetradyne] = useState();
  const [pod, setPod] = useState();
  const [cdf, setCdf] = useState();
  const [cdfYear, setCdfYear] = useState();
  const [cdfWeek, setCdfWeek] = useState();
  const [podYear, setPodYear] = useState();
  const [podWeek, setPodWeek] = useState();

  useEffect(() => {

  }, []);

  return (
    <Switch>
      <div className="container mt-3">

        {!pathName.includes("/coaching/import/mentor/confirm") && !pathName.includes("/coaching/import/cdf/confirm")  && !pathName.includes("/coaching/import/pod/confirm")  && !pathName.includes("/coaching/import/netradyne/confirm") && (
          <>
            <div className="row">
              <div className="col-md-12">
                <p className="title">
                  <FormattedMessage id="import" />
                </p>
              </div>
            </div>
            <div class="card mb-5">
              <div class="card-header">
                <FormattedMessage id="daily" />
              </div>
              <div class="card-body">
                <Route
                  path="/coaching/import"
                  exact
                  component={(props) => <ImportMentor setMentorMethod={setMentor} setFileNameMentorImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/coaching/import"
                  exact
                  component={(props) => <ImportNetradyne setNetradyneMethod={setNetradyne} setFileNameNetradyneImport={setFileNameImport} />}
                />
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <FormattedMessage id="weekly" />
              </div>
              <div class="card-body">
                <Route
                  path="/coaching/import"
                  exact
                  component={(props) => <ImportCdf setCdfMethod={setCdf} setCdfYearMethod={setCdfYear} setCdfWeekMethod={setCdfWeek} setFileNameCdfImport={setFileNameImport} />}
                />
                <hr />
                <br />
                <Route
                  path="/coaching/import"
                  exact
                  component={(props) => <ImportPod setPodMethod={setPod} setPodYearMethod={setPodYear} setPodWeekMethod={setPodWeek} setFileNamePodImport={setFileNameImport} />}
                />
              </div>
            </div>
          </>
        )}


        <Route
          path="/coaching/import/mentor/confirm"
          exact
          component={(props) => <MentorConfirm mentor={mentor} fileNameImport={fileNameImport} />}
        />

        <Route
          path="/coaching/import/netradyne/confirm"
          exact
          component={(props) => <NetradyneConfirm netradyne={netradyne} fileNameImport={fileNameImport} />}
        />

        <Route
          path="/coaching/import/cdf/confirm"
          exact
          component={(props) => <CdfConfirm cdf={cdf} year={cdfYear} week={cdfWeek} fileNameImport={fileNameImport} />}
        />

        <Route
          path="/coaching/import/pod/confirm"
          exact
          component={(props) => <PodConfirm pod={pod} year={podYear} week={podWeek} fileNameImport={fileNameImport} />}
        />


      </div>
    </Switch >
  );
}


