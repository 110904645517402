import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./list";
import {FormattedMessage} from "react-intl";

export default function Country() {
  return (
    <Switch>
      <div className="container mt-3">
        <div className='row mb-3'>
          <div className='col-12 col-md-6'>
            <p className="title"><FormattedMessage id="menu.countries" /></p>
          </div>
          <div className='col-6 col-md-2'>
          </div>
          <div className='col-6 col-md-4'>
          </div>          
        </div>
        <Route exact path="/params/country" component={List} />
      </div>
    </Switch>
  );
}

