import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import api, { GetToken } from "../../api/axios";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
import { faClock, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading";

export default function CdfEdit() {

  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [driverid, setDriverid] = useState("");
  const [driver, setDriver] = useState([]);

  let { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {

    if (id) {
      //edit
      api
        .put(
          "feedback-user/update/" + id,
          {
            year: data.year,
            week: data.week,
            positive: data.positive,
            negative: data.negative,
            nofeedback: data.nofeedback,
            great: data.great,
            respectful: data.respectful,
            instructions: data.instructions,
            friendly: data.friendly,
            beyond: data.beyond,
            withcare: data.withcare,
            notgreat: data.notgreat,
            mishandled: data.mishandled,
            unprofessional: data.unprofessional,
            notlocation: data.notlocation,
            ontime: data.ontime,
            late: data.late,
            damaged: data.damaged,
            wrongaddress: data.wrongaddress,
            notreceived: data.notreceived,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then((data) => {
          //Swal.fire({
          //  icon: "success",
          // title: "Success",
          //}).then(() => {
          history.push("/drivers/cdf/list/" + driverid);
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {

    }

  }


  useEffect(() => {

    if (id) {
      api
        .get("feedback-user/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          const value = data.data[0];
          setValue("year", value.year);
          setValue("week", value.week);
          setValue("positive", value.positive);
          setValue("negative", value.negative);
          setValue("nofeedback", value.nofeedback);
          setValue("great", value.great);
          setValue("respectful", value.respectful);
          setValue("instructions", value.instructions);
          setValue("friendly", value.friendly);
          setValue("beyond", value.beyond);
          setValue("withcare", value.withcare);
          setValue("notgreat", value.notgreat);
          setValue("mishandled", value.mishandled);
          setValue("unprofessional", value.unprofessional);
          setValue("notlocation", value.notlocation);
          setValue("ontime", value.ontime);
          setValue("late", value.late);
          setValue("damaged", value.damaged);
          setValue("wrongaddress", value.wrongaddress);
          setValue("notreceived", value.notreceived);
          setDriverid(value.userid);

          api
            .get("driver/getbyid/" + value.userid, {
              headers: { "x-access-token": GetToken() },
            })
            .then((data) => {
              setDriver(data.data[0]);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response?.data.message,
              });
            });

        })
        .catch((err) => {
          console.log(err.response);
        });


    }


  }, [id, setValue]);




  return (
    <div className="row">
      <Loading visible={loading} />
      <div className="col-12 col-md-12 mb-2">
        <h5>
          CDF <FormattedMessage id="tabela.driver" />:
          <a onClick={() => history.push("/drivers/edit/" + driverid)} style={{ 'marginLeft': '15px', 'cursor': 'pointer', 'textDecoration': 'underline', 'color': "#0d6efd" }}>
            {driver.fname} {driver.lname}
          </a>
        </h5>
      </div>
      <div className="col-md-12 col-12">
        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
          <div className="row">
            <div className="col-md-3 mt-3">
              <FormattedMessage id="form.year" />
              <input
                className="form-control"
                type="number"
                maxlength="4"
                {...register("year", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="week" />
              <input
                className="form-control"
                type="number"
                min="1" max="53" step="1"
                {...register("week", { required: true })}
              />
            </div>


            <div className="col-md-3  mt-3">
              <FormattedMessage id="positive" />
              <input
                className="form-control"
                type="text"
                {...register("positive", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="negative" />
              <input
                className="form-control"
                type="text"
                {...register("negative", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="nofeedback" />
              <input
                className="form-control"
                type="text"
                {...register("nofeedback", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="great" />
              <input
                className="form-control"
                type="text"
                {...register("great", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="respectful" />
              <input
                className="form-control"
                type="text"
                {...register("respectful", { required: true })}
              />
            </div>


            <div className="col-md-3  mt-3">
              <FormattedMessage id="instructions" />
              <input
                className="form-control"
                type="text"
                {...register("instructions", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="friendly" />
              <input
                className="form-control"
                type="text"
                {...register("friendly", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="beyond" />
              <input
                className="form-control"
                type="text"
                {...register("beyond", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="withcare" />
              <input
                className="form-control"
                type="text"
                {...register("withcare", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="notgreat" />
              <input
                className="form-control"
                type="text"
                {...register("notgreat", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="mishandled" />
              <input
                className="form-control"
                type="text"
                {...register("mishandled", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="unprofessional" />
              <input
                className="form-control"
                type="text"
                {...register("unprofessional", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="notlocation" />
              <input
                className="form-control"
                type="text"
                {...register("notlocation", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="ontime" />
              <input
                className="form-control"
                type="text"
                {...register("ontime", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="late" />
              <input
                className="form-control"
                type="text"
                {...register("late", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="damaged" />
              <input
                className="form-control"
                type="text"
                {...register("damaged", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="wrongaddress" />
              <input
                className="form-control"
                type="text"
                {...register("wrongaddress", { required: true })}
              />
            </div>

            <div className="col-md-3  mt-3">
              <FormattedMessage id="notreceived" />
              <input
                className="form-control"
                type="text"
                {...register("notreceived", { required: true })}
              />
            </div>
          </div>

          <div className="d-flex flex-row-reverse bd-highlight">

            <FormattedMessage id="edit_save">
              {(edit_save) => {
                return (
                  <button type="submit"
                    className="btn btn-orange btn-confirm float-end"
                  >
                    <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
                    {id ? edit_save.edit : edit_save.save}
                  </button>
                );
              }}
            </FormattedMessage>
            <button
              className="btn btn-secondary btn-confirm"
              onClick={() => history.push("/drivers/cdf/list/" + driverid)}
            >
              <FormattedMessage id="form.cancel" />
            </button>
          </div>
        </form>
      </div>

    </div>

  );
}



function formatDate(date) {
  var parts = date.split("-");
  var mydate = new Date(parts[0], parts[1], parts[2]);
  date = mydate;
  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");

}
